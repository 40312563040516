
import {css, html, LitElement} from "lit";
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component';
import {Assets} from 'shared/assets';


class TableFilterScreenshotAnalysis extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
    
    .broken-icon, .semi-broken-icon {
      display: inline-block;
      position: absolute;
      top: -3px;
      left: -28px;
    }
    .broken-icon svg, .semi-broken-icon svg {
      height: 26px;
      width: 26px;
    }
    
    .label-text span {
      margin-left: 20px;
    }
 `];


  constructor() {
    super()
    this.valueType = 'array'
  }


  render() {
    let items = []
    items.push(this.itemHelper('semi-broken', 'SEMI_BROKEN', html`
      <span class="semi-broken-icon">
        ${Assets.inlineSvg('screenshot/icon-semi-broken.svg')}
      </span>
      <span>Warnings</span>
    `))
    items.push(this.itemHelper('broken', 'BROKEN', html`
      <span class="broken-icon">
        ${Assets.inlineSvg('screenshot/icon-broken.svg')}
      </span>
      <span>Errors</span>
    `))

    return html`
      ${this.sectionHelper(html`Screenshot Analysis`, items)}
    `;
  }
}
customElements.define('table-filter-screenshot-analysis', TableFilterScreenshotAnalysis);
