
import {css, html, LitElement} from "lit";
import {graphQL} from "../shared/graphQL";


//-----------------------------------------------------------------------------
class SmartScanBarGraph extends LitElement {
  static styles = css`
    :host {
      display: inline-block;
      position: relative;
      height: 100%;
      width: 8%;
      margin: 0 2.5%;
    }
    .bars {
      position: relative;
      height: 80%;
    }
    .bar {
      position: absolute;
      display: inline-block;
      bottom: 0px;
      width: 25%;
      border-radius: 4px;
    }

    .good {
      background-color: var(--smart-scan-good-color);
      height: 90%;
      left: 0;
    }
    .semi-broken {
      background-color: var(--smart-scan-semi-broken-color);
      height: 30%;
      left: 37.2%;
    }
    .broken {
      background-color: var(--smart-scan-broken-color);
      height: 10%;
      left: 75%;
    }

    .day-of-week {
      height: 20%;
      padding-top: 8px;
      text-align: center;
    }
   `;

  static properties = {
    day: {type: Number},
    data: {state: true},
  };


  constructor() {
    super()
    this.day = 0
    this.data = {}
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let graphqlQuery = `
      query Counts($normal: ScreenshotFilter, $broken: ScreenshotFilter, $semi_broken: ScreenshotFilter) {
        normal: screenshotCount(filters: $normal)
        broken: screenshotCount(filters: $broken)
        semi_broken: screenshotCount(filters: $semi_broken)
      }
    `;

    if (this.day === undefined || this.day === null) {
      return
    }

    let labels = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    let date = new Date()
    date.setDate(date.getDate() + this.day)
    this.label = labels[date.getDay()]

    let after = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toJSON()
    let before = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59).toJSON()

    let graphqlVariables = {
      normal: {
        forAccountId: graphQL.accountId(),
        whereAnalysisStatus: "NORMAL",
        inTimeSpan: {
          after: after,
          before: before,
        },
      },
      broken: {
        forAccountId: graphQL.accountId(),
        whereAnalysisStatus: "BROKEN",
        inTimeSpan: {
          after: after,
          before: before,
        },
      },
      semi_broken: {
        forAccountId: graphQL.accountId(),
        whereAnalysisStatus: "SEMI_BROKEN",
        inTimeSpan: {
          after: after,
          before: before,
        },
      },
    };

    this.error = null
    graphQL.query({
      query: graphqlQuery,
      variables: graphqlVariables,
      success: (response) => {
        this.data = response.data
        if (this.day === 0) {
          let event = new Event('todayDataReady')
          this.dispatchEvent(event)
        }
      },
      error: (message) => {
        this.error = `Error getting smart scan bar graph data: ${message}`
        console.log(this.error)
        this.data = {error: this.error}
      },
    });
  }


  render() {
    if (this.error) return this.error
    this.data.normal =  this.data.normal || 0
    this.data.semi_broken = this.data.semi_broken || 0
    this.data.broken = this.data.broken || 0
    let total = this.data.normal + this.data.semi_broken + this.data.broken
    if (total <= 0) total = 0.0000001

    return html`
      <div class="bars">
          <div class="bar good" style="height: ${this.data.normal * 100.0 / total + 0.1}%"></div>
          <div class="bar semi-broken" style="height: ${this.data.semi_broken * 100.0 / total + 0.1}%"></div>
          <div class="bar broken" style="height: ${this.data.broken * 100.0 / total + 0.1}%"></div>
      </div>
      <div class="day-of-week">${this.label}</div>
    `;
  }
}
customElements.define('smart-scan-bar-graph', SmartScanBarGraph);

