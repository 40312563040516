import { capitalize }  from './foundation/capitalize';
import { CRONExpression } from './CRONExpression';

// Magic number killing constants
const HOUR_IN_A_DAY = 24;
const MONTHS_IN_A_YEAR = 12;
const DAYS_IN_YEAR = 365;
const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;
const MILLISECONDS_IN_YEAR =
    MILLISECONDS_IN_HOUR * HOUR_IN_A_DAY * DAYS_IN_YEAR;

/**
 * Converts a CRON range string into a sorted array of numbers
 * NOTE: Current implementation ignores dashes and just assumes a comma
 * delimited list, this should probably be changed.
 * @param {string} cronRangeString
 * @returns
 */
function convertFromCRONRange(cronRangeString) {
    return cronRangeString.split(',').map(x => parseInt(x, 10)).sort();
}

/**
 * Converts an iterable of numbers to a CRON range string
 * NOTE: Current implementation ignores dashes and just creates a comma
 * delimited string, this should probably be changed.
 * @param {*} numbers   an iterable of numbers to convert
 * @returns {string}    a CRON range
 */
function convertToCRONRange(numbers) {
    const nums = [...numbers].sort();
    return nums.join(',');
}

/**
 * Helper function to safely split the cron expression into parts
 * that can be destructed without error.
 * @param {string} expression a valid cron expression string
 * @returns
 */
export function splitCRONExpression(expression) {
    // Break into parts based on spaces
    const obj = new CRONExpression(expression);

    return {
        minutes: obj?.minutes,
        hours: obj?.hours,
        daysOfMonth: obj?.daysOfMonth,
        months: obj?.months,
        daysOfWeek: obj?.daysOfWeek
    };
}

/**
 * handles an array for custom expressions
 * @param {string[]} expressions
 */
function parseCustomExpressions(expressions) {
    return {
        type: 'custom',
        dates: expressions.map(expression => {
            // Break expression
            const {daysOfMonth, months} =
                new CRONExpression(expression);

            // Check that days of month is a single day and not a range
            if (daysOfMonth.includes('-') || daysOfMonth.includes(',')) {
                throw new Error(
                    `Tried to parse CRON expression '${expression}' as a ` +
                    'custom repeat type but days of month value was a range.'
                );
            }

            // Check that month is a single month and not a range
            if (
                months.includes('-') ||
                months.includes(',') ||
                daysOfMonth.includes('/')
            ) {
                throw new Error(
                    `Tried to parse CRON expression '${expression}' as a ` +
                    'custom repeat type but month value was a range.'
                );
            }

            // Compare the dates to see if we need ot bump the year
            const now = new Date();
            const target = new Date(now.getFullYear(), months, daysOfMonth);
            const year = now.getFullYear() +
                (now.valueOf() > target.valueOf() ? 1 : 0);

            // format month as an ISO8601 month since CRON dates are 0 indexed
            const mon = (parseInt(months) + 1).toString().padStart(2, '0');

            return `${year}-${mon}-${daysOfMonth.padStart(2, '0')}`
        })
    };
}

/**
 * Takes string of delimited cron expressions and parses them into a usable
 * JSON object.
 * @param {string} raw           The raw cron string
 * @param {string} delimiter     The delimiter to break the string on
 * @returns {RepeatRepresentation} an object representing the repetition. Varies
 *                                 quite a bit based on type
 */
export function parseCRONExpressions(raw, delimiter = ';') {
    if (!raw) {
        return { type: 'never' };
    }

    const expressions = raw.split(delimiter);

    // Any scenario where the is more than one string it must be custom
    if (expressions.length > 1) {
        return parseCustomExpressions(expressions);
    }

    // Pull the single expression out for convience
    const [expression] = expressions;

    // Pull out individual pieces of the expression
    const {minutes, hours, daysOfMonth, months, daysOfWeek} =
        new CRONExpression(expression);

    // Custom is so particular it is benefical to check it first so that tests
    // for other types can be simplier
    // Check that minute through month is set for a single custom.

    // get a workable version of the parts with int members parsed
    const customParts = [minutes, hours, daysOfMonth, months].map(
        x => parseInt(x, 10)
    );

    if (
        daysOfWeek === '*' &&
        customParts.every(x => !isNaN(x))

    ) {
        return parseCustomExpressions([expression]);
    }    

    const stepRe = /^(\*|\d+)\/(\*|\d+)/;

    // We handle days with hour steps so if it is set we know it is daily
    if (stepRe.test(hours)) {
        const step = hours.split('/')?.[1];
        return {
            type: 'daily',
            every: Number.parseInt(step, 10) / HOUR_IN_A_DAY,
            minutes: minutes,
            hours: hours
        };
    }

    // If days of week is set it could be either weekly or monthly in day of
    // week mode. Handle it either way.
    if (!daysOfWeek?.startsWith?.('*')) {
        // If there is a has it is monthly in week mode
        if (daysOfWeek?.indexOf?.('#') !== -1) {
            return {
                type: 'monthly',
                repeatBy: 'daysOfWeek',
                daysOfWeek: daysOfWeek.split(','),
                minutes: minutes,
                hours: hours
            };
        }

        // otherwise treat it as weekly.
        return {
            type: 'weekly',
            days: convertFromCRONRange(daysOfWeek),
            minutes: minutes,
            hours: hours
        };
    }

    // Handle days of month style month
    if (
        daysOfMonth?.[0] !== '*' &&
        parseInt(hours?.[0], 10) <= 24 &&
        months === '*'
    ) {
        return {
            type: 'monthly',
            repeatBy: 'daysOfMonth',
            daysOfMonth: convertFromCRONRange(daysOfMonth),
            minutes: minutes,
            hours: hours
        };
    }

    // We handle years with month steps so if it is set we know it is yearly
    if (months?.startsWith?.('*/')) {
        return {
            type: 'yearly',
            every: Number.parseInt(months.slice(2)) / MONTHS_IN_A_YEAR,
            minutes: minutes,
            hours: hours
        };
    }

    throw new Error(`Could not parse CRON expression:\n\t${raw}`);
}

/**
 * Function to conver a monthly config into a string
 * @param {RepeatRepresentation} config
 * @returns
 */
function convertMonthlyConfig(config) {
    if (!config) {
        throw new Error(
            'Must pass a repeat representation to convertMonthlyConfig'
        );
    }

    if (config.type !== 'monthly') {
        throw new Error(
            'tried to convert non-monthly repeat representation in monthy' +
            'CRON expression'
        );
    }

    switch(config.repeatBy) {
        case 'daysOfWeek':
            if (!Array.isArray(config.daysOfWeek)) {
                throw new Error(
                    'Tried to convert monthly daysOfWeek object' +
                    ' without a daysOfWeek property that is an array'
                );
            }

            return [
                '0',    // minute
                '0',    // hour
                '*',    // day of month
                '*',    // month
                config.daysOfWeek.join(',') // day of week
            ].join('\t');
        case 'daysOfMonth':
            if (!Array.isArray(config.daysOfMonth)) {
                throw new Error(
                    'Tried to convert monthly daysOfMonth object' +
                    ' without a daysOfMonth property that is an array'
                );
            }

            return [
                '0',    // minute
                '0',    // hour
                config.daysOfMonth.join(','),    // day of month
                '*',    // month
                '*'     // day of week
            ].join('\t');

        default:
            throw new Error(
                `tried to convert unknown repeatType ${config.repeatBy} into` +
                'monthly CRON expression'
            );
    }
}

/**
 * Converts a day in YYYY-MM-DD format into a CRON expression in the next year.
 * If the year of the passed in string is not within a year an error is thrown
 * @param {string} dayString
 * @return {string} A string CRON expression format
 */
function convertCustomDate(dayString) {
    const safe = new Date(dayString).toISOString().slice(0, 19);
    const dayParts = safe.split('-');

    if (dayParts.length !== 3) {
        throw new Error(
            'convertCustomDate expected custom date in YYYY-MM-DD format'
        );
    }

    const [year, month, day] = dayParts.map(x => parseInt(x));

    // Check that the date isn't more than a year in the future
    const date = new Date(year, month - 1, day);
    if (Date.now() + MILLISECONDS_IN_YEAR < date.valueOf()) {
        throw new Error(
            `Custom Date ${day} could not be converted to a CRON expression` +
            'because it is more than a year in the future, and CRON can not ' +
            'express a year.'
        );
    }

    return [
        '0',    // minute
        '0',    // hour
        day,    // day of month
        month - 1,  // month
        '*'     // day of week
    ].join('\t');
}

/**
 * Converts a repeat Repsentation to a CRON string
 * @param {RepeatRepresentation} config
 * @returns {string} A delimited string of CRON epxressions
 */
export function convertToCRONExpressions(config, delimiter = ';') {
    if (!config) {
        throw new Error(
            'Must pass a repeat representation to convertToCRONExpressions'
        );
    }

    switch(config.type) {
        // CRON Helper Reference
        // [m,  h,  dom,    month,  dow]
        case 'never':
            return '';
        case 'daily':
            // verify we have an every to work with
            if (typeof config.every !== 'number') {
                throw new Error(
                    'Tried to convert daily CRON object to CRON string' +
                    ' without an "every" property. Must be an integer.'
                );
            }

            return (new CRONExpression([
                '0',            // minute
                `*/${config.every * HOUR_IN_A_DAY}`,   // hour
                '*',            // day of month
                '*',            // month
                '*'             // day of week
            ])).toString('\t');
        case 'weekly':
            if (config?.days?.length <= 0) {
                throw new Error(
                    'Tried to convert weekly CRON object to CRON string' +
                    ' without an "days" property. Must be an array' +
                    ' with at least one integer.'
                );
            }

            return (new CRONExpression([
                '0',    // minute
                '0',    // hour
                '*',    // day of month
                '*',    // month
                convertToCRONRange(config.days) // day of week
            ])).toString('\t');
        case 'monthly':
            // monthly splits into the two subtypes
            return convertMonthlyConfig(config);
        case 'yearly':
            if (typeof config.every !== 'number') {
                throw new Error(
                    'Tried to convert yearly CRON object to CRON string' +
                    ' without an "every" property. Must be an array' +
                    ' with at least one integer.'
                );
            }

            return (new CRONExpression([
                '0',            // minute
                '0',            // hour
                '1',            // day of month
                `*/${config.every * MONTHS_IN_A_YEAR}`,   // month
                '*'             // day of week
            ])).toString('\t');
        case 'custom':
            if (config?.dates?.length <= 0) {
                throw new Error(
                    'Tried to convert custom CRON object to CRON string' +
                    ' without a valid "dates" property. Must be an array' +
                    ' with at least one date convertable string.'
                );
            }

            return config.dates.map(convertCustomDate).join(delimiter);
        default:
            throw new Error(
                `Tried to convert unknown repeat type ${config.type} ` +
                'to CRON expression'
            );
    }
}

/**
 * @typedef {Object} RepeatRepresentation
 * @property {string}       type
 *                          The type of repeat. Can be:
 *                              - never
 *                              - daily
 *                              - weekly
 *                              - monthly
 *                              - yearly
 *                              - custom
 * 
 * @property {?number}      every
 *                          The number of units to repeat at. Only for daily
 *                          and yearly.
 * 
 * @property {?number[]}    days
 *                          The days of the week to repeat on where 0 is Sunday
 *                          and 6 is Saturday. Only in weekly type.
 * 
 * @property {?string}      repeatBy
 *                          The days of the week to repeat on where 0 is Sunday
 *                          and 6 is Saturday. Only in monthly type. Can be:
 *                              - daysOfWeek
 *                              - daysOfMonth
 * 
 * @property {?string[]}    daysOfWeek
 *                          A array of week month pairs where seperate by a
 *                          hash. for example '2#3' would represent the third
 *                          Tuesday of the month.
 * 
 * @property {?number[]}    daysOfMonth
 *                          The days of the month to repeat on where 1 is the
 *                          first
 * 
 * @property {?number[]}    dates
 *                          The dates to repeat on in YYYY-MM-DD format. Only
 *                          in custom types.
 */

const typeToUnit = new Map()

// populate type to unit map
typeToUnit.set('never', 'never');
typeToUnit.set('daily', 'day');
typeToUnit.set('weekly', 'week');
typeToUnit.set('monthly', 'month');
typeToUnit.set('yearly', 'year');
typeToUnit.set('custom', 'custom');

/**
 * Generates the every part of a the humand readable english
 */
function everyCause(config) {
    if (!config?.every) {
        throw new Error(
            'Tried to generate every clause for a config without an every'
        );
    }

    const amount = config.every === 1 ? '': `${config.every} `;
    const unit = typeToUnit.get(config.type);

    return `Every ${amount}${unit}${
        config.every > 1 ? 's' : ''
    }`;
}

const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];


/**
 * Helper function add an ordinal suffix to a number.
 * Only works for english.
 * @param {*} number The number the suffix is for.
 * @returns The suffix for an ordinal number
 */
export function ordinalSuffix(number) {
    if (typeof number !== 'number') {
        throw new Error(
            'Tried to get an ordinal suffix for something that is not a number'
        );
    }

    // Deal with the weird teen numbers
    if (10 < number && number < 20) {
      return 'th';
    }
  
    // Handle special cases
    if ( number % 10 === 1) {
      return 'st';
    }
  
    if ( number % 10 === 2) {
      return 'nd';
    }
  
    if ( number % 10 === 3) {
      return 'rd';
    }
  
    // Give th to everyone else
    return 'th';
  }

/**
 * Helper to convert a list of things to a hand readable, delimited list
 */
function toDelimitedEnglishList(list, delimiter = ',') {
    if (!list?.length) {
        return '';
    }

    const d = list.length < 3 ? '' : delimiter;
    // figure out if we need an and
    const final = list.length > 1 ? 'and ' : '';

    return list
        .map((x, i) => i + 1 !== list.length ? `${x}${d}` : `${final}${x}`)
        .join(' ');
}

function weekdaysClause(days) {
    const copy = days && days.length ? [...days] : [];
    copy.sort((a,b) => a - b);
    return copy && copy.length > 0 ?
        `on ${ toDelimitedEnglishList(copy.map(x => `${weekdays[x]}s`))}` : ``;
}

function daysOfMonthClause(days) {
    // Don't bother for empty stuff
    if (!days?.length) {
        return '';
    }

    const copy = [...days].sort((a,b) => a - b);

    return `on ${toDelimitedEnglishList(copy.map(
            raw => {
                const x = Number.parseInt(raw);
                return typeof x === 'number' && !isNaN(x) ?
                    `${x}${ordinalSuffix(x)}` :
                    raw;
            }
        ))}`;
}

function daysOfWeekClause(days) {
     // Don't bother for empty stuff
     if (!days?.length) {
        return '';
    }

    const copy = [...days].map(
            // split the pairs and parse them into ints
            x => x.split('#').map(y => parseInt(y, 10))
        ).sort(
            ([dayA, weekA], [dayB, weekB]) => {
                // combine day and week into a single number where week is more
                // important 
                const a = weekA * 1000 + dayA;
                const b = weekB * 1000 + dayB;
                return a - b;
            }
        );

    return `on the ${toDelimitedEnglishList(copy.map(
            x => {
                const [day, week] = x;
                const weekLabel = week === 6 ? 'last' : `${week}${ordinalSuffix(week)}`;
                return `${weekLabel} ${weekdays[day]}`
            }
        ))}`;
}

function timeClause(hours, minutes){
  if(hours) {
    let hoursSplit = hours.split('/')[0]
    if(hoursSplit != '*') {
      let parsedHours = parseInt(hoursSplit)
      if (parsedHours != '*') {
        let ampm = 'am'
        if (parsedHours > 11) {
          ampm = 'pm'
          parsedHours -= 12
        }
        if (parsedHours === 0) parsedHours = 12
        if (minutes.length == 1) minutes = '0' + minutes
        if (parsedHours < 10 ) parsedHours = '0' + parsedHours
        return "at " + parsedHours + ':' + minutes + ' ' + ampm
      }
    }
  }
}

function customClause(dates) {
    const copy = !dates?.length ?
        [] :
        [...dates].sort((a,b) => (new Date(a)) - (new Date(b)));
    copy.sort((a,b) => a - b);
    return copy && copy.length > 0 ?
        `on ${ toDelimitedEnglishList(copy)}` : ``;
}

export function convertConfigToEnglish(config) {
    if (!config) {
        throw new Error(
            'Tried to convert empty repeat config to english'
        );
    }

    if (!config?.type) {
        throw new Error(
            'Tried to convert untyped repeat config to english'
        );
    }

    if (config.type === 'never') {
        return 'Never Repeats';
    }

    const str = [
        // add the every cause or the type depending on which makes more sense
        config?.every ?
            everyCause(config) :
            capitalize(config.type),
        // add applicable clauses
        weekdaysClause(config.days),
        daysOfMonthClause(config.daysOfMonth),
        daysOfWeekClause(config.daysOfWeek),
        customClause(config.dates),
        timeClause(config.hours, config.minutes),
    ];

    return str
        .filter(x => x) // remove empty strings
        .join(" ");     // join it all into a single string
}

export function CRONExpressionToString(expression) {
    return convertConfigToEnglish(
        parseCRONExpressions(expression)
    );
}
