
import {css, html, LitElement} from "lit";
import {styleMap} from 'lit/directives/style-map.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js'


class DonutChart extends LitElement {
  static styles = css`
    :host {
      position: relative;
      display: inline-block;
    }
    .donut-chart-center {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 85%;
      height: 24%;
      text-align: center;
    }
    .number {
      font-weight: bold;
      margin-bottom: 4px;
    }
    .label {
      font-size: 13px;
    }
 `;

  static properties = {
    ringWidth: {type: Number},   // in pixels
    label: {type: String},
    data: {state: true},
  };


  constructor() {
    super()
    this.ringWidth = 11
    this.data = []
  }


  render() {
    if (this.data.length <= 0) return
    if (this.offsetWidth <= 0) return
    let strokeWidth = this.ringWidth * 20.0 * 2.0 / this.offsetWidth
    let radius = 20.0 - (strokeWidth / 2.0)
    let cir = 2.0 * Math.PI * radius
    let valueTotal = 0
    for (let i = 0; i < this.data.length; i++) {
      valueTotal += this.data[i].value
    }
    let dashStart = cir
    let dashEnd = 0
    let duration = 1

    let circles = ''
    for (let i = 0; i < this.data.length; i++) {
      let rev = this.data.length - 1 - i
      circles += `
        <circle cx="20" cy="20" r="${radius}" fill="transparent"
          stroke-width="${strokeWidth}" stroke="${this.data[rev].color}"
          stroke-dasharray="0 ${cir}" stroke-dashoffset="${cir / 4.0}">
            <animate attributeName="stroke-dasharray" values="0 ${cir}; ${dashStart} ${dashEnd}" dur="${duration}s" fill="freeze"/>
        </circle>
      `;
      let part = this.data[rev].value / valueTotal * cir
      dashStart -= part
      dashEnd += part
    }
    let svg = `<svg width="100%" height="100%" viewBox="0 0 40 40">${circles}</svg>`

    let numStyle = { 'font-size': `${26 * (this.offsetWidth / 180)}px` }
    let labelStyle = { 'font-size': `${13 * (this.offsetWidth / 180)}px` }

    return html`
      <div class="donut-chart">
        ${unsafeHTML(svg)}
      </div>
      <div class="donut-chart-center">
        <div class="number" style=${styleMap(numStyle)}>${valueTotal.toLocaleString()}</div>
        <div class=label" style=${styleMap(labelStyle)}>${this.label}</div>
      </div>
    `;
  }
}
customElements.define('donut-chart', DonutChart);
