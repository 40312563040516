
import {css, html, LitElement} from 'lit'
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component'


class TableFilterUserEmployee extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
    `];

  static properties = {
    data: {state: true},
  };


  constructor() {
    super()
    this.data = []
  }


  render() {
    let items = []
    items.push(this.itemHelper('employeesOnly', 'true', html`
      <span class="employee"></span>
      <span>Employee</span>
    `))
    items.push(this.itemHelper('usersOnly', 'true', html`
      <span class="user"></span>
      <span>User</span>
    `))

    return html`
      ${this.sectionHelper(html`User Type`, items)}
    `;
  }
}
customElements.define('table-filter-user-employee', TableFilterUserEmployee);

