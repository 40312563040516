import { LitElement, html, css } from 'lit'
import { graphQL } from '../shared/graphQL'
import { Assets } from '../shared/assets'

export class MssPlayerMoveDialog extends LitElement {
  static styles = css`
        :host {
            font-weight: bold;
        }
        #dialog {
            position: absolute;
            z-index: 100;
            top: 100px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-top: 10vh;
            background-color: #EBEBEB;
            width: 460px;
            padding: 25px;
            border: 1px solid #DCDCDC;
            border-radius: 8px;
            box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5);
        }
        .show {
            display: block;
        }
        .hide {
            display: none;
        }
        .select-wapper {
            position: relative;
        }
        .select-wapper svg {
            position: absolute;
            right: 17px;
            top: 7px;
            width: 12px;
            height: 6px;
            pointer-events: none;
        }
        select {
            border: 1px solid #666666;
            border-radius: 5px;
            padding: 0 23px 0 10px;
            height: 27px;
            margin-top: -2px;
            margin-bottom: -2px;
            margin-left: 0px;
            margin-right: 4px;
            font-size: 16px;
            color: #333333;
            appearance: none;
            width: 240px;
        }
        .drop-down {
            margin-top: 20px;
            margin-bottom: 25px;
        }
    `;

  static properties = {
    display: false,
    playerIds: [],
    closeAll: {},
    orgId: 0
  }

  constructor() {
    super();
    this.display = false;
    this.playerIds = [];
    this.orgs = [];
    this.accounts = []
    this.orgId = -1;
  }

  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }

  render() {
    return html`
      <div id="dialog" class="${this.display ? 'show' : 'hide'}">
          <span>Move selected players to </span>

          <div class="drop-down">
              <span class="select-wapper">
                  <select name="per" @change=${this._select}>
                      ${this.orgId > 0 ? this.accounts?.map((act) => html`
                          <option value="${act.id}">${act.name}</option>
                    `)

        :
        this.orgs?.map((org) =>
          org.accounts?.sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0
          }).map((act) =>
            html`<option value="${act.id}">${org.name}: ${act.name}</option>`)
        )
      }
                  </select>
                  ${Assets.inlineSvg('caret-solid.svg')}
              </span>
          </div>

          <div class="buttons">
              <mss-button class="primary" @click=${this._move}>Move</mss-button>
              <mss-button class="" @click=${this._close}>Cancel</mss-button>
          </div>
      </div>
        `;
  }

  _select() {
    this.selected = this.shadowRoot.querySelector('option:checked').value;
  }

  _close() {
    this.display = false;
    $('.fow').hide();
  }

  _move() {
    this.movePlayers();
    this._close();
  }

  generateSelectorOptions() {

  }

  getData() {
    var query = '';
    var variables = {};

    if (this.orgId > 0) {
      query = `
            query account($input: AccountFilter) {
                accounts(filter: $input) {
                    id
                    name
                }
            }
            `;
      variables = {
        input: {
          forOrganizationId: this.orgId
        }
      };
    } else {
      query = `
        query organizations($filters: OrganizationFilter!) {
            organizations(filters: $filters) {
                    id
                    name
                    accounts {
                        id
                        name
                    }
                }
            }
        `;
      variables = {
        filters: { orderByName: 'ASC' }
      };
    }

    graphQL.query({
      query,
      variables,
      success: (response) => {
        if (response.errors) {
          this.orgs = [];
          errorHandler(responese.errors)
        }
        if (this.orgId > 0) {
          this.accounts = response.data.accounts;
        } else {
          this.orgs = response.data.organizations;
        }
      },
      error: function (message) {
        this.orgs = []
        console.log('Error: ', message);
      },
    });
  }

  movePlayers() {
    let query = `
        mutation playerMoveToAccount($moveInput: PlayerMoveToAccountInput!) {
            playerMoveToAccount(input: $moveInput) {
                backgroundJob {
                    jobId,
                    reportedErrors {
                        messages {
                            messageLevel
                            message
                        }
                    }
                }
            }
        }
    `;
    if (!this.selected) {
      this._select()
    }
    let variables = `
        {
            "moveInput": {
                "backgroundJobName": "Move players ${this.playerIds.join(', ')}",
                "accountId": ${this.selected},
                "playerIds": [${this.playerIds.join(', ')}]
            }
        }
    `;

    graphQL.query({
      query,
      variables,
      success: (response) => {
        if (response.errors) {
          errorHandler(responese.errors)
          return false;
        }
      },
      error: function (message) {
        console.log('Error: ', message);
      },
    });
  }

}
customElements.define('mss-player-move-dialog', MssPlayerMoveDialog);
