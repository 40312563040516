
import {css, html, LitElement} from "lit"
import {SlideshowLargeBase} from "shared/slideshow-large-base.component"
import {Assets} from 'shared/assets'
import 'shared/slideshow-large-base.component'


class SmartScanSlideshow extends SlideshowLargeBase {
  static styles = [
    SlideshowLargeBase.styles,
    css`
    .name {
      padding-left: 40px;
    }
    .analysis-icon {
      position: absolute;
      left: 0px;
      top: -8px;
      font-size: 24px;
      text-align: center;
      padding-left: 0px;
      padding-right: 0px;
      height: 35px;
      width: 35px;
    }
    .analysis-icon svg {
        width: 35px;
    }
    
    .link-to-player {
      color: #438BDF;
      transition: color 0.2s;
      outline: none;
      text-decoration: none;
      padding-right: 20px;
    }
    .link-to-player .caret-icon {
      position: absolute;
      right: 2px;
      top: 4px;
    }
    .caret-icon svg {
      height: 17px;
      width: 9px;
    }
    
    .link-to-player:hover {
      color: #5f9be1;
    }
    .link-to-player:hover svg g {
      fill: #5f9be1;
    }
    
  `];


  constructor() {
    super()
  }


  image(index) {
    return this.data[index].imageLarge
  }


  topLeft(index) {
    let screenshot = this.data[index]
    let analysis = screenshot.analysisStatus
    let icon = html``
    if (analysis === 'NORMAL') {
      icon = html`${Assets.inlineSvg('screenshot/icon-good')}`
    } else if (analysis === 'SEMI_BROKEN') {
      icon = html`${Assets.inlineSvg('screenshot/icon-semi-broken.svg')}`
    } else if (analysis === 'BROKEN') {
      icon = html`${Assets.inlineSvg('screenshot/icon-broken.svg')}`
    } else {
      icon = html``
    }
    let name = (screenshot.player && screenshot.player.name) || '--'

    return html`
      <span class="analysis-icon">${icon}</span>
      <span class="name">${name}</span>
    `;
  }


  topRight(index) {
    return html`<a class="link-to-player" href="${Routes.player_path(this.data[index].player.id)}">
        Go to Player<span class="caret-icon">${Assets.inlineSvg('caret.svg')}</span>`
  }


  bottomMiddle(index) {
    return html`${index + 1} / ${this.data.length}`
  }


  bottomRight(index) {
    return html`${Util.formatDateTime(this.data[index].capturedAt)}`
  }
}
customElements.define('smart-scan-slideshow', SmartScanSlideshow);


