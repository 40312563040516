import './recurring-schedule.component';
import { convertToCRONExpressions, parseCRONExpressions } from '../cron-parser';
import { LitElement, html, css } from 'lit';
import { CRONExpression } from '../CRONExpression';

class CRONFieldChange extends CustomEvent {
    constructor(detailsExt) {
        super('change', {
            detail: {
                isValid: false,
                ...detailsExt
            },
            bubbles: true,
            composed: true
        });
    }
}

export class CRONField extends LitElement {
    static styles = css`
        input[type=time] {
            width: 50%;
            border-radius: 3px;
            border: 1px solid #979797;
            border-color: var(--border-color, #979797);
            background-color: var(--background-color, #fff);
            font-size: var(--font-size, 16px);
            line-height: var(--line-height, 35px);
            margin-bottom: 20px;
            font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
            padding-left: 12px;
            padding-right: 12px;
        }

        input[type=time]:disabled {
            background-color: inherit;
            border-color: inherit;
        }

        .time-container {
            width: 100%;
            display: flex;
            flex-direction: row;

            align-items: baseline;
        }

        .time-container > input[type=time] {
            margin-right: 10px;
        }

        .time-container > input[type=time]:only-child {
            margin-right: 0;
        }

        span[slot=timezone] {
            margin-left: 10px;
        }
    `;

    static get properties() {
        return {
            value: { type: Object }
        };
    }

    /**
     * getter for the internal recurringSchedule element
     * left public to avoid bad shadowDOM piercing if it is  really needed.
     */
    get recurringSchedule() {
        return this.renderRoot.querySelector('recurring-schedule');
    }

    /**
     * getter for the internal time input
     * left public to avoid bad shadowDOM piercing if it is  really needed.
     */
    get timeElement() {
        return this.renderRoot.querySelector('input[type=time]');
    }

    /**
     * The internal object representation to model the data being changed even
     * if the state is invalid.
     */
    #objectRepresentation = {type: 'never'};

    /**
     * Internal value for representing the time.
     */
    #time = "00:00";

    /**
     * Internal handler for determining if the data being passed in is valid.
     */
    #isValid = true;

    /**
     * getter for isValid to be used publicly without allowing setting.
     */
    get isValid() {
        return this.#isValid;
    }

    /**
     * Attempt to update value based on recurringSchedule's value and set the
     * validility approriately.
     */
    #attemptValueUpdate() {
        this.#objectRepresentation = this.recurringSchedule?.value;
        // Get pieces of time.
        this.#time =  this.timeElement?.value;
        const time = this.#time?.split?.(':');
        const hour = parseInt(time?.[0], 10);
        const minute = parseInt(time?.[1], 10);
        try {
            // handle never specially
            if (this.#objectRepresentation?.type === 'never') {
                this.value = "";
            }
            else  {
                // update all the expressions with the time
                const vals = convertToCRONExpressions(
                        this.#objectRepresentation
                    )?.split(';').map(
                    x => {
                        const exp = new CRONExpression(x); // conver to mutable object
                        if (!isNaN(hour)) {
                            exp.updateHour(hour);
                        }
                        if (minute && !isNaN(minute)) {
                            exp.updateMinute(minute);
                        }
                        return exp.toString();
                    }
                ).join(';');
                this.value = vals;
            }
            this.#isValid = true;
        } catch {
            console.warn('failed to add time to CRONExpression');
            this.#isValid = false;
        }

        // since we suceeded at this point we need to emit an event
        this.dispatchEvent(new CRONFieldChange({
            isValid: this.isValid,
            value: this.value
        }));

        // since object represenation isn't a reactive property we must
        // manually request an update
        this.requestUpdate();
    }

    /**
     * Handler for the recurr-scheduler's change event
     * @param {*} e 
     */
    #updateSchedulerValues(e) {
        e.stopPropagation();

        this.#attemptValueUpdate();

        this.dispatchEvent(new CRONFieldChange({
            isValid: this.isValid,
            value: this.value
        }));
    }

    /**
     * handler for the time input change
     * @param {*} e 
     */
    #timeChange(e) {
        e.stopPropagation();
        this.#attemptValueUpdate();
    }

    // Lifecycle hooks
    requestUpdate(name, oldValue) {
        // Try to parse the cron string to something useable
        if (name === 'value') {
            try {
                // set object part
                const val = parseCRONExpressions(this.value);
                this.#objectRepresentation = val;
                // set time part
                const first = this.value?.split?.(';')?.[0];

                if (first) {
                    const expression = new CRONExpression(first);
                    const hours = expression.hours?.split?.('/')?.[0]?.padStart(2, '0') ?? '00';
                    const minutes = expression.minutes?.split?.('/')?.[0]?.padStart(2, '0') ?? '00';
                    this.#time = `${hours}:${minutes}`;
                }
            } catch {
                console.error(
                    "Failed to parse inputted CRON expression: ",
                    this.value,
                    "\n\tContinuing as if update had not occurred"
                );
            }
        }

        return super.requestUpdate(name, oldValue);
    }

    render() {
        return html`
            <div class="time-container">
                <input
                    type="time"
                    step="60"
                    .value="${this.#time}"
                    ?disabled="${this.#objectRepresentation?.type === 'never'}"
                    @change="${this.#timeChange}"
                />
                <slot name="timezone">??</slot>
            </div>
       
            <recurring-schedule
                .value="${this.#objectRepresentation}"
                .hideWeeklyRepeat="${true}"
                .hideMonthlyRepeat="${true}"
                @change="${this.#updateSchedulerValues}">
                .isValid=${this.isValid}
            </recurring-schedule>
        `;
    }
}

customElements.define('cron-field', CRONField);
