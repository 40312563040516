import {html, css, LitElement} from 'lit'
import {Assets} from 'shared/assets'

class integrationSelect extends LitElement {
    static properties = {
        field: {state: true},
        key: {state: true},
        options: {state: true}
    };

    static styles = [
        css`
    * {
         box-sizing: border-box;
    }
     .form-group{
         height: 40px;
         display: flex;
         margin-top: 10px;
         margin-bottom: 5px;
         overflow: hidden;
    }
     .form-group label {
         font-size: 12px;
         padding: 0;
         font-weight: 400;
         color: #5B5B5B;
         display: inline-block;
         flex: 1;
    }
    
    select{
        height: 37px;
        border-radius: 3px;
        appearance: none;
        padding-right: 30px;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
        display: inline-block;
        width: 100%;
        border: 1px solid #979797;
        padding: 10px 12px;
        font-size: 16px;
        outline: none !important;
        color: #333333;
        background-color: #FFFFFF;
        line-height: 16px;
        transition: border-color 0.2s ease-in-out;
        background-repeat: no-repeat;
        background-size: 12px 6px;
        background-position: right 10px center;
        flex: 1;
    }
     .form-group.error select{
       color: #D75762;
      }
    `,
    ];

    constructor() {
        super()
        this.error = false
        this.value = null
    }

    updated() {
        let image = Assets.inlineSvg('caret-solid.svg').strings[0];
        let imageSVG64 = window.btoa(image);
        $(this.shadowRoot).find('select')[0].style.backgroundImage = "url('data:image/svg+xml;base64," + imageSVG64 + "')";
    }


    render() {
        let thiz = this;
        let options = []
        $.each(this.options, function (i, option) {
            if (i === 0) {
                options.push(html`
                    <option></option>`)
            }
            let name = option.humanized
            let fieldName = option.fieldName
            if (option.required) {
                name += ' *'
            }
            if (fieldName == thiz.value) {
                options.push(html`
                    <option value=${fieldName} selected="true">${name}</option>`)
            } else {
                options.push(html`
                    <option value=${fieldName}>${name}</option>`)
            }
            if (option.humanized === 'Attributes' || option.humanized === 'Location(s)' || option.humanized === 'Opt Out Work Anniversary') {
                options.push(html`
                    <option disabled>--</option>`)
            }
        })
        return html`
            <div class="form-group">
                <label>${this.key}</label>
                <select @change="${this.onChange}">
                    ${options}
                </select>
            </div>
        `;
    }

    onChange = function (event) {
        if (event.target.value == '') {
            this.value = null
        } else {
            this.value = event.target.value
        }
    }
}

customElements
    .define(
        'integration-select',
        integrationSelect
    )