import {html, css, LitElement} from 'lit'
import {map} from 'lit/directives/map'
import {Assets} from 'shared/assets'

require("integrations/integration-form-group.component")
require("integrations/integration-helper")
require("shared/objectFilter")

class integrationCard extends LitElement {
    static properties = {
        name: {state: true},
        integrationType: {state: true},
        ingestableFields: {state: true},
        integration: {state: true},
    };
    static styles = [
        css`
        * {
          box-sizing: border-box;
        }
        .card header{
          background-color: transparent;
          border: none;
          color: #666666;
          border-bottom: 1px solid #979797;
          border-radius: 0;
          margin: 0px 0 20px;
          padding: 0 0 3px 0;
          text-align: left;
          font-size: 14px;
          font-weight: normal;
          line-height: 1em;
          padding-left: 10px;
        }
        .card {
          width: 100%;
          max-width: none;
          margin: 0px;
          max-width: 450px;
          background-color: #F5F5F5;
          border: 1px solid #DCDCDC;
          border-radius: 8px;
          box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);
          padding: 20px;
          margin: 0;
          font-size: 16px;
          height: 100%;
        }

        .inner-card {
            padding: 0 10px;
        }

        select {
            height: 37px;
            border-radius: 3px;
            appearance: none;
            padding-right: 30px;
            font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
            display: inline-block;
            width: 100%;
            border: 1px solid #979797;
            padding: 10px 12px;
            font-size: 16px;
            outline: none !important;
            color: #333333;
            background-color: #FFFFFF;
            line-height: 16px;
            transition: border-color 0.2s ease-in-out;
            background-repeat: no-repeat;
            background-size: 12px 6px;
            background-position: right 10px center;
            flex: 1;
            padding-right: 30px;
        }
               
      .alert.alert-danger {
            border: 4px solid #D75762;
            border-radius: 8px;
            background-color: #0B2543;
            overflow: hidden;
            color: white;
            margin-bottom: 20px;
            padding: 10px 20px;
       }
       .hidden{
         display: none;
       }
    `,
    ];

    constructor() {
        super()
    }

    updated() {
        let image = Assets.inlineSvg('caret-solid.svg').strings[0];
        let imageSVG64 = window.btoa(image);
        let select = $(this.shadowRoot).find('#tz')[0];
        select ? select.style.backgroundImage = "url('data:image/svg+xml;base64," + imageSVG64 + "')" : false;
    }

    render() {
        let formGroups = []
        let thiz = this
        let integrationTypeFields = Object.filter(this.integrationType.fields, x => x.category === thiz.name)
        let orderedTypeFields = sortByOrder(integrationTypeFields)

        formGroups.push(html`
            <div class="alert alert-danger hidden">Please review the problems below:</div>`)
        $.each(orderedTypeFields, (i, data) => {
            if (data[1].fieldType === 'CRON_STRING') {
                let selectedTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let raw_cron = this.fieldValue(data[1].name)

                let formerTimezone
                if(raw_cron) {
                    formerTimezone = raw_cron.match(/(\/||&||_|| ||\(||\)||[a-zA-Z])+$/)[0]
                }
                if (formerTimezone) {
                    raw_cron = raw_cron.replace(formerTimezone, '')
                }
                let options = []
                let selected = false
                $.each(JSON.parse(this.timeZones), function (i, tz) {
                    if (formerTimezone && tz.identifier == formerTimezone.substring(1,formerTimezone.length)) {
                        options.push(html`
                            <option selected=true value="${tz['identifier']}">${tz['offset']} ${tz['name']}</option>`)
                        selected = true
                    } else if (selected == false && tz.identifier == selectedTZ) {
                        options.push(html`
                            <option selected=true value="${tz['identifier']}">${tz['offset']} ${tz['name']}</option>`)
                    } else {
                        options.push(html`
                            <option value="${tz['identifier']}">${tz['offset']} ${tz['name']}</option>`)
                    }
                });
                formGroups.push(html`
                    <cron-field
                            .value="${raw_cron}"
                            name="${data[1].name}"
                            data-field-name="${data[1].name}">
                        <span slot="timezone">
                            <select id="tz">
                                ${options}
                            </select>
                        </span>
                    </cron-field>
                `)
            } else {
                let value = ''
                if (thiz.integration) {
                    value = thiz.fieldValue(data[1].name)
                }
                formGroups.push(html`
                    <integration-form-group @input-changed=${thiz.inputChanged}
                                            .readable=${this.readable}
                                            .field=${data[1]}
                                            .value=${value}
                                            .integrationType="${thiz.integrationType}"
                                            .ingestableFields="${thiz.ingestableFields}">
                    </integration-form-group>`)
            }
        })
        return html`
            <div class="card">
                <header>${this.name}</header>
                <div class="inner-card">
                    ${formGroups}
                </div>
            </div>
        `;
    }

    fieldValue = function (fieldName) {
        let fieldData = this.integrationField(fieldName)
        if (fieldData) {
            return fieldData.value
        }
    }

    integrationField = function (fieldName) {
        if (this.integration.fields.length > 0) {
            return this.integration.fields.find(x => x.name === fieldName)
        }
    }

    inputChanged = function () {
        this.dispatchEvent(new CustomEvent('input-changed'));
        let error = false
        $(this.shadowRoot).find('integration-form-group').each((j, formGroup) => {
            if ($(formGroup.shadowRoot).find('.error').length > 0) {
                error = true
            }
        })
        if (error) {
            $(this.shadowRoot).find('.alert').removeClass('hidden')
        } else {
            $(this.shadowRoot).find('.alert').addClass('hidden')

        }
    }
}

customElements.define('integration-card', integrationCard)