
import {LitElement, html, css} from 'lit'
import {classMap} from 'lit/directives/class-map'
import {Assets} from 'shared/assets'
import 'screenshots/smart-scan-bar-graph.component'
import 'screenshots/donut-chart.component'


class SmartScanSummary extends LitElement {
  static styles = css`
    :host {
      display: block;
      box-shadow: var(--section-box-shadow, 0 1px 4px 0 rgba(0, 0, 0, .5));
      border-radius: var(--section-border-radius, 8px);
      border-style: var(--section-border-style, none);
      padding-top: 13px;
      padding-left: 24px;
      padding-right: 12px;
      padding-bottom: 0px;
    }
    .title {
      position: absolute;
    }
    .title svg {
      position: relative;
      top: 3px;
      width: 26px;
    }
    .title span {
      position: relative;
      bottom: 8px;
      font-weight: bold;
      font-size: 16px;
    }
  
    .see-details {
      position: absolute;
      right: 26px;
      bottom: 20px;
      text-decoration-line: underline;
      text-underline-offset: 3px;
      }
  
    .flex-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 32px;
    }
  
    .week-trend {
      font-size: 14px;
      margin-bottom: 22px;
    }
  
    .week-trend-title {
      color: #a0a0a0;
    }
  
    .dashed-line {
      margin-top: 5px;
      margin-bottom: 1px;
      height: 1px;
      background: repeating-linear-gradient(to right, #EFEFEF 0, #EFEFEF 12px, transparent 12px,transparent 18px);
    }
    .good {
      color: var(--smart-scan-good-color);
    }
    .semi-broken {
      color: var(--smart-scan-semi-broken-color);
    }
    .broken {
      color: var(--smart-scan-broken-color);
    }
    
    .graphs {
      height: 170px;
      width: 490px;
    }
    .graphs.dashboard {
      height: 150px;
      width: 350px;
    }
    
    .today-stats th {
      font-size: 12px;
      color: #a0a0a0;
      padding-bottom: 10px;
      text-align: left;
    }
    .today-stats td {
      padding-bottom: 10px;
    }
  
    .today-label {
      padding-bottom: 19px;
      text-align: right;
      padding-right: 7px;
      font-size: 12px;
    }
    .today-stats hr {
      border: none;
      border-top: 3px solid;
      margin: 0;
      margin-bottom: 5px;
    }
    .today-good, .today-semi-broken, .today-broken {
      font-weight: bold;
      font-size: 22px;
    }
    .total-chart {
      position: relative;
      padding-left: 5px;
      margin-bottom: 22px;
    }
    donut-chart {
      width: 180px;
      height: 180px;
    }
    donut-chart.dashboard {
      width: 170px;
      height: 170px;
    }
    
  `;
  static properties = {
    dashboard: {type: Boolean},
    data: {state: true},
    todayData: {state: true},
  };


  constructor() {
    super()
    this.data = {}
    this.todayData = {}
    this.dashboard = false
  }


  todayDataReady(event) {
    let tData = event.currentTarget.data
    this.todayData = tData
    let chart = this.renderRoot.querySelector('donut-chart')
    if (chart) {
      chart.data =  [
        { value: tData.normal, color: '#a1e3fc' },
        { value: tData.semi_broken, color: '#ffc9b2' },
        { value: tData.broken, color: '#c9a6fa' },
      ];
    }
  }


  render() {
    let endDate = new Date()
    let startDate = new Date()
    startDate.setDate(startDate.getDate() - 6)
    let graphClasses = {graphs: true, dashboard: this.dashboard}
    let barChartClasses = {dashboard: this.dashboard}
    this.todayData.normal =  this.todayData.normal || 0
    this.todayData.semi_broken = this.todayData.semi_broken || 0
    this.todayData.broken = this.todayData.broken || 0
    let total = this.todayData.normal + this.todayData.semi_broken + this.todayData.broken
    let normal = 0
    let semiBroken = 0
    let broken = 0
    if (total > 0) {
      normal = (this.todayData.normal * 100.0 / total + 0.1).toFixed(0)
      semiBroken = (this.todayData.semi_broken * 100.0 / total + 0.1).toFixed(0)
      broken = (this.todayData.broken * 100.0 / total + 0.1).toFixed(0)
    }

    return html`
      <div class="title">
        ${Assets.inlineSvg("screenshot/AI-smartScan.svg")}<span>&nbsp;&nbsp;Smart Scan</span>
      </div>
      <div class="flex-container">
        <div>
          <div class="week-trend">
            <span class="week-trend-title">7 Day Trend</span>&nbsp;&nbsp;&nbsp;<span
              class="start-date">${Util.formatDate(startDate)}</span> - <span
              class="end-date">${Util.formatDate(endDate)}</span>
            <div class="dashed-line"></div>
            <div class=${classMap(graphClasses)}>
              <smart-scan-bar-graph day="-6"></smart-scan-bar-graph>
              <smart-scan-bar-graph day="-5"></smart-scan-bar-graph>
              <smart-scan-bar-graph day="-4"></smart-scan-bar-graph>
              <smart-scan-bar-graph day="-3"></smart-scan-bar-graph>
              <smart-scan-bar-graph day="-2"></smart-scan-bar-graph>
              <smart-scan-bar-graph day="-1"></smart-scan-bar-graph>
              <smart-scan-bar-graph day="0" @todayDataReady=${this.todayDataReady}></smart-scan-bar-graph>
            </div>
          </div>
        </div>
        <div>
          <table class="today-stats">
            <tr>
              <th></th>
              <th>Today</th>
            </tr>
            <tr>
              <td class="today-label">Good</td>
              <td><span class="today-good">${normal}</span> %
                <hr class="good"\>
              </td>
            </tr>
            <tr>
              <td class="today-label">Warning</td>
              <td><span class="today-semi-broken">${semiBroken}</span> %
                <hr class="semi-broken"\>
              </td>
            </tr>
            <tr>
            <td class="today-label">Error</td>
            <td><span class="today-broken">${broken}</span> %
              <hr class="broken"\>
            </td>
            </tr>
          </table>
        </div>
        <div class="total-chart">
          <donut-chart ringWidth="11" label="Today's Screenshots" class=${classMap(barChartClasses)}></donut-chart>
        </div>
      </div>
      ${this.seeDetailsLink ? `<a class="see-details" href="${this.options.seeDetailsLink}">See Details</a>` : ''}
    `;
  }
}
customElements.define('smart-scan-summary', SmartScanSummary);



