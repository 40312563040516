
import {LitElement, html, css} from 'lit'
import {Assets} from "../assets";


class MssFolderIcon extends LitElement {
  static styles = css`
		*, *:before, *:after {
		  box-sizing: border-box;
		}
		:host {
			display: inline-block;
			position: relative;
			width: 32px;
			height: 28px;
			font-size: 11px;
			line-height: 12px;
		}
		
		svg {
		  height: 100%;
		  width: 100%;
		}
		
		.count-container {
		  position: absolute;
      top: 50%;
      left: 0px;
      width: 160%;
      text-align: center;
		}
		
		.count {
      display: inline-block;
      position: relative;
      margin: 0 auto;
      border: 2px solid #585858;
      border-radius: 8px;
      padding: 0px 4px;
      background-color: #ffffff;
		}
  `;


  static properties = {
    count: {type: String},
  };


  constructor() {
    super()
    this.count = ''
  }


  render() {
    return html`
      ${Assets.inlineSvg('folder.svg')}
      <div class="count-container">
        <div class="count">${this.count}</div>
      </div>
		`;
  }

}
customElements.define('mss-folder-icon', MssFolderIcon);
