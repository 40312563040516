import { svg } from "lit";

export const plus = svg`
<svg width="13px" height="13px" viewBox="0 0 28 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
<desc>Created with Sketch.</desc>
<g id="Home/Main-Menu/Top-Bar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
    <g id="TopBar---Publish-Now" transform="translate(-1175.000000, -41.000000)" stroke="#666666" stroke-width="5">
        <g id="Group">
            <g id="Add-Content" transform="translate(1164.000000, 29.000000)">
                <g id="Group-9" transform="translate(15.000000, 15.000000)">
                    <path d="M10.5,0.35 L10.5,20.65" id="Line-2"></path>
                    <path d="M20.5060967,11 L0,11" id="Line-2-Copy"></path>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>`;


