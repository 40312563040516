
import {css, html, LitElement} from 'lit'
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component'
import {Assets} from 'shared/assets'
import {graphQL} from '../graphQL'


class TableFilterTags extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
      header svg {
        position: relative;
        top: 2px;
      }
    `];

  static properties = {
    data: {state: true},
  };


  constructor() {
    super()
    this.valueType = 'array'
    this.data = []
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let accountId = graphQL.accountId() == 0 ? null : graphQL.accountId();
    let organizationId = graphQL.organizationId() == 0 ? null : graphQL.organizationId();
    let query = `
      query {
        tags(accountId: ${accountId}, organizationId: ${organizationId}) {
          id
          name
        }
      }
    `;

    graphQL.query({
      query: query,
      success: (response) => {
        this.data = response.data.tags
      },
      error: (message) => {
        console.log(`Error getting tags: ${message}`)
      },
    });
  }


  render() {
    let items = []
    for (const type of this.data) {
      items.push(this.itemHelper(type.name, type.id, html`
        ${type.name}
      `))
    }

    return html`
      ${this.sectionHelper(html`
        ${Assets.inlineSvg('filters/tag.svg')}&nbsp;Tags`, items)}
    `;
  }
}
customElements.define('table-filter-tags', TableFilterTags);

