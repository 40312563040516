import { css } from 'lit';

export const templateStyles = css`
    .col {
        display: flex;
        flex-direction: column;
    }

    .row {
        display: flex;
        flex-direction: row;
    }

    button {
        width: 100%;
        color: #5B5B5B;
        background-color: #FFFFFF;
        border: 1px solid #979797;
        transition: background-color 0.2s, color 0.2s;
        cursor: pointer;
        text-align: center;
    }

    button:disabled {
        pointer-events: none;
        opacity: 0.4;
    }


    .primary {
        color: #FFFFFF;
        background-color: #438BDF;
        border-color: #438BDF;
    }
  
  
    .primary:hover {
        background-color: #2D7EDB;
        border-color: #2D7EDB;
    }

    .btn-sm {
        padding: 0;
        font-size: 12px;
        line-height: 16px;
        border-radius: .25em;
    }

    .btn-lg {
        padding: 8px 10px;
        font-size: 16px;
        line-height: 16px;
        border-radius: .5em;
    }

    .card {
        position: relative;
        background-color: #F5F5F5;
        border: 1px solid #DCDCDC;
        border-radius: .5em;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50);
        padding: 15px;
    }

    .close-icon {
        position: absolute;
        top: 14px;
        right: 20px;
        cursor: pointer;
    }

    .card header {
        display: flex;
        color: #666666;
        border: none;
        font-size: 14px;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, "sans-serif";
    }

    .card header.underline {
        margin-bottom: 1em;
        border-bottom: 1px solid #979797;
    }

    .card-template {
        width: 400px;
        position: relative;
        background: white;
        border: solid 1px #979797;
        border-radius: 3px;
        padding: 20px 0px 0px 0px;
    }

    .card-sm {
        width: 25%;
    }

    .card-md {
        width: 50%;
    }

    .card-lg {
        height: 600px;
        max-height: 80%;
        width: calc(100% - 500px);
        min-width: 500px
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
    }

    .dropdown-container {
        position: relative;
        display: inline-block;
        cursor: pointer;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
    }

    .dropdown-body {
        position: absolute;
        z-index: 1;
    }

    .disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.4;
    }
`;