
import { html, css } from 'lit'
import { TableBase } from 'shared/tables/table-base.component'
import { graphQL } from "shared/graphQL"
import 'shared/foundation/mss-favorite-toggle.component'
import 'shared/foundation/mss-pill.component'
import 'shared/tables/table-filter-tags.component'
import 'shared/tables/table-filter-groups.component'
import { bulkActionClick } from "shared/bulkActionHelpers";
import { Assets } from "../shared/assets";

class LocationsTable extends TableBase {

  static styles = [
    TableBase.styles,
    css`
    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0
    }
    .action:hover svg circle {
      stroke: #d6d9e0
    }

    `,
  ];

  static properties = {
    allowed: {}
  };


  constructor() {
    super()

    this.route = '/locations/:id'
    this.dataName = 'Locations'
    this.columns = [
      { key: 'bulk_action', title: '', display: 'always-left' },
      { key: 'name', title: 'Location', sortable: 'orderByName' },
      { key: 'messages', title: 'Messages', sortable: 'orderByMessageCount' },
      { key: 'content', title: 'Content', sortable: 'orderByContentCount' },
      { key: 'required_messages', title: 'Required Messages' },
      { key: 'users_count', title: 'Users', sortable: 'orderByUserCount' },
      { key: 'timezone', title: 'Time Zone', sortable: 'orderByTimezone' },
      { key: 'tags', title: 'Tags' },
      { key: 'group', title: 'Group', sortable: 'orderByGroup' },
      { key: 'favorite', title: '' },
      { key: 'actions', title: '', display: 'always-right' },
    ]

    this.orderBy = 'orderByName'   // default
    this.orderType = 'ascending'   // default

    this.paginationEnabled = true
    this.paginationOptions.dataName = this.dataName
    this.searchEnabled = true
    this.filterSections = html`
      <table-filter-tags></table-filter-tags>
      <table-filter-groups></table-filter-groups>
    `;
    this.settingsEnabled = true

    this.bulkActionsMenu = html`
      <div class="action delete"
           data-url="${Routes.locations_path()}"
           data-method="delete"
           @click=${bulkActionClick}
      >
        ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
      </div>
    `;
  }


  getData() {
    let query = `
      query Locations($filters: LocationFilter, $total_count_filters: LocationFilter) {
        locationsCount(filters: $total_count_filters)
        locations(filters: $filters) {
          id
          name
          messageCount
          contentCount
          userCount
          group {
            name
          }
          timezone
          tags {
            id
            name
          }
          favorites {
            id
          }
          requiredMessageTemplatesCount
          requiredMessageTemplatesFulfilledCount
        }
      }
    `;

    let variables = {
      filters: {
        pager: {
          pageSize: this.pageSize,
          page: this.page - 1,
        },
      },
      total_count_filters: {
      },
    };

    if (this.orderBy && this.orderType) {
      let val = {}
      val[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
      variables.filters.sort = val
    }
    if (this.searchValue) {
      variables.filters['nameLike'] = this.searchValue
    }

    let filtering = {}
    if (graphQL.accountId()) {
      filtering.forAccountId = graphQL.accountId()
    }
    let values
    if (this.filterValues) {
      if ((values = this.filterValues["table-filter-tags"])) {
        filtering.forTagIds = values
      }
      if ((values = this.filterValues["table-filter-groups"])) {
        filtering.forGroupIds = values
      }
    }

    // Put filtering in both 'filters' and 'total_count_filters'
    for (const filter in filtering) {
      variables.filters[filter] = filtering[filter]
      variables.total_count_filters[filter] = filtering[filter]
    }

    graphQL.query({
      query: query,
      variables: variables,
      success: (response) => {
        if (response.data) {
          this.totalCount = response.data.locationsCount
          this.data = response.data.locations
        }
      },
      error: (message) => {
        this.data = []
        console.log('GraphQL Errors: ', message)
      },
    });
  }


  cellValue(colKey, item) {
    const allowedActions = new Set(
      (this.allowed || '')
        .split(',')
        .map(x => x.toLowerCase())
    );

    let value = html``;
    if (colKey === 'bulk_action' && allowedActions.has('destroy')) {
      value = html`<mss-checkbox name="Location" value="${item.id}"></mss-checkbox>`

    } else if (colKey === 'favorite') {
      let itemId = item.id || ''
      value = html`
        <mss-favorite-toggle itemType="LOCATION" .itemId=${itemId}
             ?checked=${item.favorites && item.favorites.length > 0}>
        </mss-favorite-toggle>`

    } else if (colKey === 'group') {
      value = (item.group && item.group.name) || ''

    } else if (colKey === 'name') {
      value = item.name

    } else if (colKey === 'timezone') {
      value = item.timezone

    } else if (colKey === 'manifest_version') {
      value = item.manifestVersion

    } else if (colKey === 'messages') {
      value = html`${item.messageCount}`

    } else if (colKey === 'content') {
      value = html`${item.contentCount}`

    } else if (colKey === 'users_count') {
      value = html`${item.userCount}`

    } else if (colKey === 'required_messages') {
      value = `${item.requiredMessageTemplatesFulfilledCount || 0}/${item.requiredMessageTemplatesCount || 0}`

    } else if (colKey === 'tags') {
      let list = []
      if (item.tags) {
        let title = ''
        for (const tag of item.tags) {
          if (title === '') {
            title = tag.name
          } else {
            title += `, ${tag.name}`
          }
        }
        for (const tag of item.tags) {
          list.push(html`<mss-pill title="${title}">${tag.name}</mss-pill>`)
        }
      }
      value = html`${list}`

    } else if (colKey === 'actions') {
      value = [
        {
          title: 'View',
          url: Routes.location_path(item.id),
          httpMethod: 'get',
          remote: false,
          protect: 'show'
        },
        {
          title: 'Edit',
          url: Routes.edit_location_path(item.id),
          httpMethod: 'get',
          remote: true,
          protect: 'update'
        },
        {
          title: 'Manage',
          url: Routes.manage_location_path(item.id),
          httpMethod: 'post',
          remote: false,
          protect: 'managed'
        },
        {
          title: 'Delete',
          url: Routes.location_path(item.id),
          httpMethod: 'delete',
          remote: false,
          confirm: `Are you sure you want to delete ${item.name || 'this record'}?`,
          protect: 'destroy'
        },
      ]
    }
    return value
  }


  cellClick(key, item, event) {
    if (key !== 'bulk_action' && key !== 'favorite' && key !== 'actions') {
      window.location.href = Routes.location_path(item.id)
    }
  }


  defaultTableSettings() {
    return {
      desktop: {
        visibleColumns: [
          'name',
          'messages',
          'content',
          'timezone',
          'tags',
          'group',
        ]
      },
      tablet: {
        visibleColumns: [
          'name',
          'messages',
          'content',
          'tags',
          'group',
        ]
      },
      mobile: {
        visibleColumns: [
          'name',
          'messages',
          'group',
        ]
      },
    }
  }
}
customElements.define('locations-table', LocationsTable)

