import  {LitElement, html, css} from 'lit'

export class ContextualizedSelect extends LitElement {
    static styles = css`
      .contextualized-select-container {
        overflow-x: hidden;
        position: relative;
        height: 100%;
      }

      .contextualized-select-container:focus {
        border-color: var(--focused-border-color, #458cde);
      }

      .select-container {
        position: relative;
        display: flex;
        border-radius: 3px;
        border: 1px solid #979797;
        border-color: var(--border-color, #979797);
        background-color: var(--background-color, #fff);
      }

      .select-container::after {
        content: '';
        width: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -3px;
        border-width: 6px 6px;
        border-style: solid;
        pointer-events: none;
        border-color: #000 transparent transparent transparent;
      }

      select {
        display: block;

        -webkit-appearance: none;
        -moz-appearance: none;
        transition: border-color 200ms;
        width: var(--width, 100%);
      
        appearence: none;
        height: 100%;
        font-size: var(--font-size, 16px);
        line-height: var(--line-height, 35px);
        outline: none;
        color: var(--font-color, #333333);
        padding-left: 12px;
        padding-right: 12px;
        border: 0;
        background-color: rgba(0, 0, 0, 0);
      }

      select::-ms-expand {
        display: none;
      }

      .contextual-text {
        position: absolute;
        content: var(--extra-tag);
        left: 50px;
        top: 34px;
        font-size: var(--font-height);
        pointer-events: none;
      }
    `;

    static properties = {
      value: {},
      singularContext: {type: String},
      pluralContext: {type: String},
    };

    constructor() {
      super();
      // this.singularContext = this?.singularContext ?? '';
      // this.pluralContext = this?.pluralContext ?? '';
    }

    get #select() {
      return this.renderRoot?.querySelector('.contextualized-select-base-element') ?? null;
    }

    #valueChanged(e) {
      e.stopPropagation();
      if (this.#select?.value !== this.value) {
        this.value = this.#select.value;
      }

      this.dispatchEvent(new Event('change', {
        details: {
          value: this.value
        },
        bubbles: true,
        composed: true
      }));
    }

    render() {
      // update binding if applicable
      if (this.#select && this.#select?.value !== this.value) {
        this.#select.value = this.value
      }

      const singularContext = this.singularContext ?? ''
      const pluralContext = this.pluralContext ?
        this.pluralContext :
        (this.singularContext ?
          this.singularContext + 's':
          ''
        );

      // TODO: Try to make the options slotted
      // TODO: Try to make the tag slotted (see https://lit.dev/docs/components/shadow-dom/#slots)
      return html`<div class="select-container">
        <select
          class="contextualized-select-base-element"
          @change="${this.#valueChanged}"
          .value="${this.value}"
        >
          <option value="1">1 ${singularContext}</option>
          <option value="2">2 ${pluralContext}</option>
          <option value="3">3 ${pluralContext}</option>
          <option value="4">4 ${pluralContext}</option>
          <option value="5">5 ${pluralContext}</option>
          <option value="6">6 ${pluralContext}</option>
          <option value="7">7 ${pluralContext}</option>
          <option value="8">8 ${pluralContext}</option>
          <option value="9">9 ${pluralContext}</option>
          <option value="10">10 ${pluralContext}</option>
          <option value="11">11 ${pluralContext}</option>
          <option value="12">12 ${pluralContext}</option>
          <option value="13">13 ${pluralContext}</option>
          <option value="14">14 ${pluralContext}</option>
          <option value="15">15 ${pluralContext}</option>
        </select>
      </div>`;
    }
}

customElements.define('contextualized-select', ContextualizedSelect);

