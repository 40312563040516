/**
 * A class to handle a CRON object intelligently.
 * The object metaphor is helpful since editting indivdual parts is desirable
 * and multiple parse methods
 */

export class CRONExpression {
    minutes = '*';
    hours = '*';
    daysOfMonth = '*';
    months = '*';
    daysOfWeek = '*';

    #steppingRegex = /^(\*|\d+)(\/\d+)/;

    /**
     * Constructs the object.
     * @param {*} initial
     */
    constructor(initial) {
        //read in the string and break it into useful parts
        if (typeof initial === 'string') {
            const parsed = initial?.trim?.()?.split?.(/\s+/);

            this.minutes = parsed[0];
            this.hours = parsed[1];
            this.daysOfMonth = parsed[2];
            this.months = parsed[3];
            this.daysOfWeek = parsed[4];
        }

        // try to handle arrays
        else if (typeof initial === 'object') {
            // handle an array with parts
            if (initial.length === 5) {
                this.minutes = initial[0];
                this.hours = initial[1];
                this.daysOfMonth = initial[2];
                this.months = initial[3];
                this.daysOfWeek = initial[4];
            }
        }
        else {
            throw new Error(
                'Tried to construct a CRONExpression from the unknown input ' +
                `${initial}`
            );
        }
    }

    /**
     * updates the time of hour without changing any of the stepping information
     */
    updateHour(hour) {
        // update hours
        const match = this.hours?.toString?.()?.match?.(this.#steppingRegex);

        // Combine any step with the new hour
        this.hours = `${hour.toString()}${match?.length > 2 ? match[2] : ''}`;

    }

    /**
     * updates the time of minute without changing any of the stepping information
     */
    updateMinute(minute) {
        // update hours
        const match = this.minutes?.toString?.()?.match?.(this.#steppingRegex);

        // Combine any step with the new minute
        this.minutes = `${minute.toString()}${match?.length > 2 ? match[2] : ''}`;
    }

    /**
     * converts the object into a predicatable object
     * @param {*} delimiter {string} an optional delimiter
     * @returns a CRON epxression string
     */
    toString(delimiter) {
        const parts = [
            this.minutes,
            this.hours,
            this.daysOfMonth,
            this.months,
            this.daysOfWeek
        ];

        // if providered a delimiter just use it
        if (delimiter) {
            return parts.join(delimiter);
        }

        // join everything together with padding on the start
        const longest = parts.reduce((a, c) => a > c.length ? a : c.length, 0);
        return [
            this.minutes,
            this.hours,
            this.daysOfMonth,
            this.months,
            this.daysOfWeek
        ].map(
            (x, i) => i === 0 ? x : x.padStart(longest, ' ')
        ).join(' ');
    }
}
