import {html, css, LitElement} from 'lit';
import {templateStyles} from './template-styles';
import {backArrow} from '../../shared/icons/back-arrow';
import {pencil} from '../../shared/icons/pencil';
import './add-item-dropdown.component';
import './content-template-modal.component';
import './content-template-preview.component';
import './location-selector.component';
import './base-form.component';

export class ContentTemplateMiniNavbar extends LitElement {
  static styles = [
    templateStyles,
    css`
        .mini-top-nav {
            position: fixed;
            width: 100%;
            z-index: 10;
            height: 37px;
            background-color: #E2E2E2;
            font-size: 14px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
        }

        .nav {
            display: flex;
            align-items: center;
        }

        .nav-left {
            justify-content: space-between;
            width: 25%;
        }

        .nav-center {
            justify-content: center;
            width: 50%;
        }

        .nav-right {
            justify-content: end;
            width: 25%;
            padding-right: 20px;
        }

        .nav-item {
            cursor: pointer;
        }

        #back {
            display: flex;
            align-items: center;
            color: #5B5B5B;
            text-decoration: none;
        }

        #back-arrow {
            padding: 10px 5px 5px 10px;
        }

        #template-name {
            color: #2571CA;
            margin-right: 3px;
        }

        #save-template {
            width: 155px;
        }

        #preview-template {
            width: 80px;
            border: 1px solid #A6A7A6;
            background-color: transparent;
        }
    `,
  ];

  static properties = {
    backPath: String,
    templateName: String,
    hidePreviewButton: Boolean,
    automatedContentLocation: Object, //TODO pass this location in from rails
    saveDisabled: {type: Boolean, state: true},
    modalHeader: {type: String, state: true},
    modalCardSize: {type: String, state: true},
    modalBody: {state: true},
  };

  constructor() {
    super();
    this.modalHeader = null;
    this.modalCardSize = null;
    this.modalBody = null;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('content-template-valid', this.toggleSaveButton);
    window.addEventListener('content-template-update', this.handleExternalNameUpdate);
  }

  disconnectedCallback() {
    window.removeEventListener('content-template-valid', this.toggleSaveButton);
    window.removeEventListener('content-template-update', this.handleExternalNameUpdate);
    super.disconnectedCallback();
  }

  templateNameFormField() {
    return [
      {
        id: 'templateName',
        label: 'Name',
        value: this.templateName,
        required: true
      }
    ];
  }

  backArrowTemplate() {
    return html`
        <a id="back" class="nav-item" href="${this.backPath}">
          <span id="back-arrow">
              ${backArrow}
          </span>
            <span>Content Templates</span>
        </a>`;
  }

  addItemDropdownTemplate() {
    return html`
        <div class="nav-item">
            <add-item-dropdown></add-item-dropdown>
        </div>`;
  }

  editTemplateNameTemplate() {
    return html`
        <div class="nav-item" @click="${this.openEditModal}">
          <span id="template-name">
          ${this.templateName}
          </span>
            ${pencil}
        </div>`;
  }

  actionsTemplate() {
    return html`
        <div class="actions">
            <button
                    class="primary btn-sm nav-item"
                    id="save-template"
                    type="submit"
                    @click=${this.saveTemplate}
                    ?disabled=${this.saveDisabled}
            >
                Save
            </button>
            ${this.hidePreviewButton
                    ? ''
                    : html`
                        <button
                                class="btn-sm nav-item"
                                id="preview-template"
                                @click=${this.openPreviewModal}
                        >
                            Preview
                        </button>
                    `}
        </div>
    `;
  }

  templateModal() {
    return html`
        <content-template-modal
                @submit-modal=${this.updateTemplateName}
                @update-location=${this.updateLocation}
                @close-modal=${this._closeModal}
                id="template-modal"
                header=${this.modalHeader}
                cardSize=${this.modalCardSize}
                includeCloseIcon=true
        >
            ${this.modalBody}
        </content-template-modal>
    `;
  }

  render() {
    return html`
        <div class="mini-top-nav row">
            <div class="nav nav-left">
                ${this.backArrowTemplate()}
                ${this.addItemDropdownTemplate()}
            </div>
            <div class="nav nav-center">${this.editTemplateNameTemplate()}</div>
            <div class="nav nav-right">${this.actionsTemplate()}</div>
        </div>

        ${this.templateModal()}
    `;
  }

  toggleSaveButton = e => {
    this.saveDisabled = !e.detail.valid;
  }

  handleExternalNameUpdate = e => this.updateTemplateName(e);

  updateTemplateName(e) {
    if (e.detail.templateName) {
      this.templateName = e.detail.templateName;
    }
  }

  updateLocation(e) {
    this.automatedContentLocation = e.detail.location;
    this.updatePreviewModal(e.detail.restart);
  }

  saveTemplate() {
    this.dispatchEvent(
      new CustomEvent('save-template', {
        detail: {
          name: this.templateName,
          location: this.automatedContentLocation
        },
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
  }

  _openModal() {
    const modalEl = this.shadowRoot.getElementById('template-modal');
    window.dispatchEvent(
      new CustomEvent('open_modal', {})
    );
    modalEl.open = true;
  }

  _closeModal() {
    const modalEl = this.shadowRoot.getElementById('template-modal');
    modalEl.open = false;
    this.dispatchEvent(
      new CustomEvent('close_preview', {
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
  }

  openEditModal() {
    this.modalHeader = 'Document Name';
    this.modalCardSize = 'sm';
    this.modalBody = html`
        <base-form
                slot="body"
                .formFields="${this.templateNameFormField()}"
                showCancelButton="true"
        ></base-form>`;

    this._openModal();
  }

  openPreviewModal() {
    window.previewMode = true
    this.dispatchEvent(
      new CustomEvent('regenerate_preview', {
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
    this.updatePreviewModal(false);
    this._openModal();
  }

  updatePreviewModal(restart) {
    this.modalHeader = 'Content Preview'
    this.modalCardSize = 'lg'
    if (this.automatedContentLocation && !restart) {
      window.dispatchEvent(new Event("get-data"));
      this.modalBody =
        html`
            <content-template-preview
                    slot="body"
                    .location=${this.automatedContentLocation}
            ></content-template-preview>`

    } else {
      this.modalBody = html`
          <location-selector slot="body"></location-selector>`;
    }
  }
}

customElements.define('content-template-mini-navbar', ContentTemplateMiniNavbar);
