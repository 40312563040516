
import {Dialog} from 'shared/dialog-box.component'


export function bulkActionClick(event) {
  let method = event.currentTarget.getAttribute('data-method')
  let url = event.currentTarget.getAttribute('data-url')
  let transportName = event.currentTarget.getAttribute('data-transport-name') || 'id'
  let withType = event.currentTarget.getAttribute('data-with-type')
  let allowedTypes = event.currentTarget.getAttribute('data-allowed-types')

  if (method === 'delete') {
    let count = this.bulkActionValues.length
    let msg = event.currentTarget.getAttribute('data-confirm') ||
          `Are you sure you want to delete ${count} ${count > 1 ? 'items' : 'item'}?`
    let myself = this
    Dialog.open(`
      <div slot="message">${msg}</div>
      <mss-button slot="button" class="primary" value="Yes">Yes</mss-button>
      <mss-button slot="button" class="secondary" value="No">No</mss-button>
    `, (value) => {
      if (value === 'Yes') {
        bulkActionDoIt.call(myself, method, url, transportName, withType, allowedTypes)
      }
    })
    event.stopPropagation()
    event.preventDefault()
    return false
  }

  bulkActionDoIt.call(this, method, url, transportName, withType, allowedTypes)

  event.stopPropagation()
  event.preventDefault()
  return false
}


function bulkActionDoIt(method, url, transportName, withType, allowedTypes) {
  if (withType || allowedTypes) {
    if (allowedTypes) {
      allowedTypes = allowedTypes.split(',')
    } else {
      allowedTypes = []
    }
    let data = []
    let allValid = true
    let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
    for (const box of list) {
      if (box.checked) {
        data.push([box.name, box.value])
        if (allowedTypes.length && allowedTypes.indexOf(box.name) === -1) {
          allValid = false
        }
      }
    }

    if (!allValid) {
      gaudi.flash("Sorry, that action cannot be performed on the selected items.", "danger", 3000)
      return
    }

    if (allowedTypes.length === 1 && $(this).data("method") === "visit") {
      data = {ids: data.map((e) => e[1])}
    } else {
      let tmp = {}
      tmp[transportName] = data
      data = tmp
    }

    if (method === "visit") {
      Turbolinks.visit(`${url}?${$.param(data)}`)
    } else {
      $.ajax({
        method: method || 'post',
        url: url,
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        data: data
      })
    }
  } else {
    let data = {}
    data[transportName] = this.bulkActionValues

    $.ajax({
      method: method || 'post',
      url: url,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      },
      data: data,
    })
  }
}

