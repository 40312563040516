import {html, css, LitElement} from 'lit'
import {graphQLIngestor} from "shared/graphQLIngestor"
import {CRONExpressionToString as cronToString} from '../shared/cron-parser'
import { calendar } from '../shared/icons/calendar';
import { gear } from '../shared/icons/gear';
import { molecule } from '../shared/icons/molecule';

require("integrations/display-mappings.component")

class integrationShow extends LitElement {
    static properties = {
        integration: {state: true},
        allowed: {}
    };
    static styles = [
        css`
            *, *:before, *:after {
                box-sizing: border-box;
            }
            .flex {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
            }
            .info-bar {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 14px;
                width: parentElement;
                margin-bottom: 70px;
                margin-top: 40px;
            }
            .info {
                display: flex;
                flex-direction: column;
            }
            .info label {
                font-size: 12px;
                font-weight: bold;
                line-height: 20px;
                color: #58;
                width: 100%;
            }
            .info input {
                height: 35px;
                background-color: white;
            }
            .sub-head {
                display: inline-block;
                margin-bottom: 7px;
                font-size: 12px;
                color: #58;
            }
            .data-section {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 40px;
                margin-bottom: 50px;
            }
            .data-unit {
                display: flex;
                flex-direction: row;
                justify-content: left-align;
            }
            .data {
                padding-left: 10px;
            }
            .icon {
                width: 25px;
                height: 26px;
            }
            .title {
                font-size: 12px;
                font-weight: bold;
                line-height: 18px;
                margin-bottom: 5px;
            }
            .input {
                height: 37px;
                border-radius: 3px;
                font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
                display: inline-block;
                min-width: 300px;
                width: fit-content;
                border: 1px solid #979797;
                padding: 10px 12px;
                font-size: 16px;
                outline: none !important;
                color: #333333;
                background-color: #FFFFFF;
                line-height: 16px;
                transition: border-color 0.2s ease-in-out;
            }

        `
    ];

    constructor() {
        super()
        this.integration = {}
        graphQLIngestor.ready(() => this.getData())
    }

    render() {
        let mappings = ["Connection", "Field Mapping", "Schedule"]
        let mappingsData = []
        let thiz = this
        if(thiz.integration.fields){
            $.each(mappings, function (key, value) {
                let fields = {};
                let icon;
                switch (value) {
                    case 'Field Mapping':
                        let maps = thiz.integration.fields.find(f => f.name === 'Field Map')?.value;
                        let raw_fields = maps ? JSON.parse(maps) : {};
                        for(const [k, v] of Object.entries(raw_fields)) {
                            if(v !== null) { fields[k] = v };
                        }
                        icon = molecule;
                        break;
                    case 'Connection':
                        fields = Object.values(thiz.integration.fields)
                            .filter(v => ['FTP Address', 'FTP User Name', 'SFTP Address', 'SFTP User Name'].includes(v.name))
                            .reduce((a, c) => ({...a, [c.name]: c.value}), {});
                        icon = gear;
                        break;
                    case 'Schedule':
                        const schedule = thiz.integration.fields.find((v) => v.name === 'Schedule String');
                        fields = {Schedule: cronToString(schedule.value) };
                        icon = calendar;
                        break;
                    default:
                        fields = {}
                        console.log('Missing valid field_type attribute!');
                        break;
                }
                mappingsData.push(html`
                    <div class="data-unit">
                        <div class="icon">${icon}</div>
                        <div class="data">
                            <display-mappings .value="${value}" .fields="${JSON.stringify(fields)}"></display-mappings>
                        </div>
                    </div>
                `)
            })
        }
        return html`
            <div class="flex">
            <div class="info-bar">
                <div class="info">
                    <label>Name</label>
                    <div class="input">${this.integration.name}</div>
                </div>
                <div class="info">
                    <label>Description</label>
                    <div class="input">${this.integration.description}</div>
                </div>
            </div>

            <div class="data-section">
                ${mappingsData}
            </div>

            <span class="sub-head">Process History</span>
            <div id="integration">
                <background-jobs-table id="${this.id}" allowed=${this.allowed}></background-jobs-table>
            </div>
            </div>
        `;
    }


    getData() {
        let query = `
      query Integration($id: ID!){
            integration(id: $id){
                name
                description
                updatedAt,
                fields{
                  name
                  value
                }
            }
        }
    `;

        let variables = {
            "id": this.id
        }

        let thiz = this

        graphQLIngestor.query({
            query: query,
            variables: variables,
            success: (response) => {
                this.integration = response.data.integration
                $(thiz.parentElement.parentElement).find('.breadcrumbs .name').text(this.integration.name)
            },
            error: (message) => {
                console.log(`Error getting Integration: ${message}`)
            },
        });
    }

}

customElements.define('integration-show-index', integrationShow)
