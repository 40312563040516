import {html, css, LitElement} from 'lit'

require("integrations/integration-select.component")


class integrationFormGroup extends LitElement {
    static properties = {
        field: {state: true},
    };
    static styles = [
        css`
        * {
          box-sizing: border-box;
        }
        *:before{
          box-sizing: border-box;
        }
        *:after{
          box-sizing: border-box;
        }
        .form-group {
          margin: auto;
          position: relative;
          margin-bottom: 20px;
        }

        .form-group span {
          display: none;
        }

        .form-group.error span {
            display: block;
            font-size: 12px;
            color: #D75762;
        }

        .form-group label {
          font-size: 12px;
          padding: 0;
          font-weight: 400;
          color: #5B5B5B;
          display: inline-block;
        }

        .form-group input {
          height: 37px;
          border-radius: 3px;
          font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
          display: inline-block;
          width: 100%;
          border: 1px solid #979797;
          padding: 10px 12px;
          font-size: 16px;
          outline: none !important;
          color: #333333;
          background-color: #FFFFFF;
          line-height: 16px;
          transition: border-color 0.2s ease-in-out;
        }

        .form-group.error input {
            border: 1px solid #D75762;
        }

       .fas{
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          cursor: pointer;
        }

        .fa-eye{
          position: absolute;
          top: 35px;
          right: 8px;
        }

       .fa-eye.active{
          color: #438BDF;
       }

       .fa-eye:before {
          content: "\\f06e";
       }

       .form-group.required:after{
          content: "Required";
          text-align: right;
          display: block;
          font-size: 12px;
          line-height: 19px;
          margin-bottom: -3px;
          position: absolute;
          top: 63px;
          right: 0px;
       }
        .fa-1x {
            font-size: 1em;
        }

        span.error-message{
          color: #D75762;
          font-size: 12px;
          display: none;
        }
        span.error-message.error{
          display: block;
        }

      .form-group-required{
          text-align: right;
          display: block;
          font-size: 12px;
          line-height: 19px;
          position: relative;
       }
       input:disabled{
         background-color: inherit;
       }
    `,
    ];

    constructor() {
        super()
        this.ftpRegex = /^s?ftp:\/\/.*\/.*[a-zA-Z0-9]$/
    }

    render() {
        let inputData = []
        let inputType = this.inputType()
        if (inputType == 'text') {
            inputData.push(html`
                <div class="${this.formGroupClass()}">
                    <label>${this.field.name}</label>
                    <input type="${inputType}"
                           value="${this.value}"
                           ?disabled="${this.readable}"
                    >
                    <span></span>
                </div>
            `)
        } else if (inputType == 'password') {
            inputData.push(html`
                <div class="${this.formGroupClass()}">
                    <label>${this.field.name}</label>
                    <input type="${inputType}"
                           value="${this.value}"
                           ?disabled="${this.readable}"
                    >
                    <i class="fas fa-eye fa-1x" @click="${this.togglePassword}"></i>
                    <span></span>
                </div>
            `)
        } else if (inputType === 'CRON_STRING') {
            inputData.push(html`
                <recurring-schedule
                        .allowUntil="${false}"
                        .hideWeeklyRepeat="${true}"
                        .hideMonthlyRepeat="${true}"
                ></recurring-schedule>
            `);
        } else if (inputType == 'JSON') {
            let obj = JSON.parse(this.value)
            let options = this.ingestableFields.filter((f) => f.fieldName != 'roles')
            $.each(obj, function (key, value) {
                inputData.push(html`
                    <integration-select .key=${key} .value=${value} .options="${options}"></integration-select>
                `)
            })
            inputData.push(html`
                <div class="form-group-required">*Required</div>`)
        }
        inputData.push(html`<span class="error-message"></span>`)
        return html`
            ${inputData}
        `;
    }

    inputType = function () {
        let _type = 'text'
        if (this.field.fieldType === 'STRING') {
            _type = 'text'
        } else if (this.field.fieldType === 'PASSWORD') {
            _type = 'password'
        } else if (this.field.fieldType == 'JSON') {
            _type = 'JSON'
        } else if (this.field.fieldType === 'CRON_STRING') {
            return this.field.fieldType;
        }
        return _type
    }

    formGroupClass = function () {
        let className = "form-group"
        if (this.field.required) {
            className += ' required'
        }
        return className
    }

    handleInput() {
        this.dispatchEvent(new CustomEvent('input-changed'));
    }

    validate = function (target) {
        if ($(target.parentElement).hasClass('required')) {
            if (this.field.required && target.value == '') {
                $($(target.parentElement).find('span')[0]).text($(target.parentElement).find('label').text() + " can't be blank")
                $(target.parentElement).addClass('error')
            } else if (this.field.name == 'FTP Address') {
                $(target.nextElementSibling).text('FTP Address is invalid')
                if (this.ftpRegex.test(target.value)) {
                    $(target.parentElement).removeClass('error')
                } else {
                    $(target.parentElement).addClass('error')
                }
            } else {
                $(target.parentElement).removeClass('error')
            }
        }
        this.handleInput()
    }

    togglePassword = function (event) {
        if (event.target.previousElementSibling.type == "password") {
            event.target.previousElementSibling.type = "text"
            $(event.target).addClass('active')
        } else {
            event.target.previousElementSibling.type = "password"
            $(event.target).removeClass('active')
        }
    }

    selectValues = function () {
        let integrationSelects = $(this.shadowRoot).find('integration-select')
        let values = []
        $.each(integrationSelects, function (i, integrationSelect) {
                if (integrationSelect.value) {
                    values.push(integrationSelect.value)
                }
            }
        )
        return values
    }

    validateAbsents = function () {
        let error = false
        let arrToCompare = this.selectValues()
        let requiredFields = $.map(this.ingestableFields, function (el) {
            if (el.required) {
                return el
            }
        });
        let absent = requiredFields.filter(e => !arrToCompare.includes(e.fieldName));
        let errEl = $(this.shadowRoot).find('.error-message')
        if (absent.length > 0) {
            let humanized = $.map(absent, function (el) {
                return el.humanized
            });
            errEl.addClass('error')
            if (humanized.length > 1) {
                errEl.text(humanized.join(', ') + " are required fields")
            } else {
                errEl.text(humanized + " is a required field")
            }
            error = true
        } else {
            errEl.removeClass('error')
        }
        return error
    }

    validateSelects = function () {
        $(this.parentElement).find('.alert').addClass('hidden')
        let thiz = this;
        let error = false;
        let integrationSelects = $(this.shadowRoot).find('integration-select')
        let values = this.selectValues()

        let chk = new Set();
        let duplicates = values.filter(n => chk.size === chk.add(n).size);

        if (this.validateAbsents()) {
            error = true
        }

        if (duplicates.length > 0) {
            $.each(integrationSelects, function (i, integrationSelect) {
                    let formGroup = $($(integrationSelect.shadowRoot).find('.form-group')[0])
                    if (duplicates.includes(integrationSelect.value)) {
                        formGroup.addClass('error');
                        $(thiz.parentElement).find('.alert').removeClass('hidden')
                        integrationSelect.error = true
                        error = true
                    } else {
                        formGroup.removeClass('error');
                        integrationSelect.error = false
                    }
                }
            )
        } else {
            $.each(integrationSelects, function (i, integrationSelect) {
                    let formGroup = $($(integrationSelect.shadowRoot).find('.form-group')[0])
                    formGroup.removeClass('error');
                    integrationSelect.error = false
                }
            )
        }
        return error
    }
}

customElements.define('integration-form-group', integrationFormGroup)
