import {LitElement, html, css} from 'lit';
import './users-table.component';

export class UsersIndex extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    mss-label-value-box {
      max-width: 345px;
      min-width: 170px;
      flex: 1;
      margin-bottom: 18px;
    }

    mss-label-value-box [slot=label] {
      font-size: 18px;
    }
    mss-label-value-box [slot=value] {
      font-size: 24px;
      font-weight: normal;
    }

    mss-label-value-box [slot=label] svg {
      margin-right: 11px;
    }

    mss-label-value-box.groups {
      cursor: pointer;
    }
    mss-label-value-box.groups [slot=value] {
      background-color: #EEEEEE;
      border-left: 0;
    }

    mss-label-value-box.locations {
      cursor: default;
    }
    mss-label-value-box.locations [slot=label] {
      background-color: #438BDF;
      color: white;
    }
    mss-label-value-box.locations [slot=value] {
      background-color: #1B6BC8;
      border-left: 0;
      color: white;
    }

    mss-label-value-box.users {
      cursor: pointer;
    }
    mss-label-value-box.users [slot=value] {
      background-color: #EEEEEE;
      border-left: 0;
    }

    .stat{
      cursor: pointer;
      flex: 1;
      background-color: white;
      border: 2px solid #979797;
      border-radius: 8px;
      max-width: 345px;
      display: flex;
      height: 40px;
      align-items: center;
    }

    .stat .value{
      cursor: pointer;
      flex: none;
      padding: 0 20px;
      font-size: 24px;
      border-left: 2px solid #979797;
      color: #5B5B5B;
      display: flex;
      height: 40px;
      align-items: center;
    }

    .stat label{
      cursor: pointer;
      font-size: 16px;
      color: #5B5B5B;
      padding: 0 10px;
      flex: 1;
    }

   .stat-bar {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;
    }
    mss-label-value-box {
      height: 40px;
      min-width: 295px;
      cursor: pointer;
    }

    .stat-bar [slot=label] svg {
      display: inline-block;
      position: relative;
      top: 0px;
      left: -5px;
      margin-right: 1px;
      height: 40px;
      width: 40px;
      font-size: 16px;
    }

    .stat-bar [slot=value] {
      font-weight: bold;
      font-size: 20px;
    }
    .stat-bar .nested-label.stat-fill {
        margin-right: 20px;
    }

    .stat-fill {
      cursor: pointer;
      flex: 1;
      background-color: #033F86;
      border: 2px solid #979797;
      border-radius: 8px;
      max-width: 345px;
      display: flex;
      height: 40px;
      align-items: center;
     }

    .stat-fill label {
        cursor: pointer;
        color: white !important;
        font-size: 16px;
        color: #5B5B5B;
        padding: 0 10px;
        flex: 1;
    }
    .stat-fill .value {
        cursor: pointer;
        flex: none;
        padding: 0 20px;
        font-size: 24px;
        border-left: 2px solid #979797;
        display: flex;
        height: 40px;
        align-items: center;
        color: white;
    }
`;

  static properties = {
    data: {state: true},
    missingImageUrl: {},
    orgid: {},
    accountid: {},
    locationid: {},
    locationadmin: {},
    attrid: {},
    employeesCount: 0,
    usersCount: 0,
    allowed: {}
  };


  constructor() {
    super()
    this.data = {}
    this.table = 'users-table'
  }


  connectedCallback() {
    super.connectedCallback()
  }

  render() {
    return html`
        <div class="stat-bar mar30t mar40b flex">
          <div class="stat-fill nested-label" @click=${this.usersClick}>
             <label><span>Users</span></label>
             <div class="value">${this.usersCount}</div>
          </div>
          <div class="stat nested-label" @click=${this.employeesClick}>
             <label><span>Employees</span></label>
             <div class="value">${this.employeesCount}</div>
          </div>
        </div>
        <users-table allowed=${this.allowed} missingImageUrl=${this.missingImageUrl} orgid="${this.orgid}" accountid="${this.accountid}" attrid="${this.attrid}"
          locationid="${this.locationid}" locationadmin="${this.locationadmin != undefined}"></users-table>
    `;
  }

  employeesClick() {
    let table = this.shadowRoot.querySelector(this.table)
    if (table) {
      table.setFilterValues({'table-filter-user-employee': {'employeesOnly': true, 'usersOnly': false}})
    }
  }

  usersClick() {
    let table = this.shadowRoot.querySelector(this.table)
    if (table) {
      table.setFilterValues({'table-filter-user-employee': {'usersOnly': true, 'employeesOnly': false}})
    }
  }

}

customElements.define('users-index', UsersIndex)


