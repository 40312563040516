import { LitElement, html, css } from 'lit';
import { choose } from 'lit/directives/choose';
import './pikaday.component';

class StandardRepeatingOptionsChangeEvent extends CustomEvent {}

export class StandardRepeatingOptions extends LitElement {
  static styles  =  css`
    input[type=number] {
      display: block;
      border-radius: 3px;
      border: 1px solid #979797;
      border-color: var(--border-color, #979797);
      transition: border-color 200ms;
      width: 100%;

      appearence: none;
      font-size: var(--font-size, 16px);
      line-height: var(--line-height, 35px);
      outline: none;
      color: var(--font-color, #333333);
      background-color: var(--background-color, #fff);
      padding: 0;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }

    .repeat-every {
      margin-bottom: 16px;
    }

    .addition-repeat-options-types {
      display: flex;
      justify-content: space-between;
      line-height: 24px;
      justify-content: space-between;
    }

    .additional-repeat-options-specifics {
      padding-bottom: 16px;
    }
  `;

  static get properties() {
    return {
      /**
       * The type of repeat, either forever, enddate, ntimes
       */
      type: { type: String },
      every: {type: Number},
      /**
       * The value of the type, only applicable for enddate and ntimes
       */
      repeatTimes: { type: Number },
      repeatDate: {},

      // Passthroughs for contextualized-select
      singularContext: {type: String},
      pluralContext: {type: String },
      allowUntil: {type: Boolean}
    };
  };

  get contextualizeSelect() {
    return this.renderRoot.querySelector('contextualized-select');
  }

  constructor() {
    super();
  }

  /**
   * Checks if the component is in a valid state
   * @returns true if data is consider valid, else false
   */
  get isValid() {
    if (this.type === 'ntimes') {
      return this.repeatTimes > 0;
    }

    if (this.type === 'enddate') {
      return typeof this.repeatDate !== 'undefined';
    }

    if (this.type === 'forever' || typeof this.type === 'undefined') {
      return true;
    }

    // valid if undefined
    if (!this.type) {
      return true;
    }
  }

  /**
   * Generalized change handler
   */
  #dispatchChange(e) {
    e?.stopPropagation();

    const every = parseInt(this.contextualizeSelect?.value, 10);

    // setup details
    const detail = {
      isValid: this.isValid,
    }

    if (this.type) {
      detail.type = this.type;
    }

    if (every) {
      detail.every = every;
    }

    // Add ntimes specific details
    if (this.type === 'ntimes') {
      detail.repeatTimes = parseInt(this.repeatTimes, 10);
    }

    // Add enddate specific details
    if (this.type === 'enddate') {
      detail.repeatDate = this.repeatDate;
    }

    // shortcut for an dispatch of a change event
    const options = {
      detail,
      bubbles: true,
      composed: true
    };
    this.dispatchEvent(new StandardRepeatingOptionsChangeEvent('change', options));
  }

  /**
   * Handles the radio change event for repeat time
   * @param {*} e
   */
  #setRepeatType(e) {
    const element = e?.target;
    const value = element.value;
    this.type = value;


    // null out repeatDate if the type is wrong
    if (this.type !== 'enddate') {
      this.repeatDate = undefined;
    }

    // null out repeatTimes if the type is wrong
    if (this.type !== 'ntimes') {
      this.repeatTimes = undefined;
    }

    this.#dispatchChange();
  }

  #setRepeatTimes(e) {
    e.stopPropagation();
    const element = e?.target;
    const value = element.value;
    this.repeatTimes = value;
    this.requestUpdate();

    this.#dispatchChange();
  }


  /**
   * Handles a change from the pikaday calendar
   * @param {*} e Change event from the Pikaday change
   */
  #setRepeatDateFromChange(e) {
    e.stopPropagation();
    this.repeatDate = e.detail.date;

    this.requestUpdate();
    this.#dispatchChange();
  }

  // Lit Lifecycle functions
  render() {
    return html`
      <div class="repeat-every">
        Repeat every...
        <contextualized-select
          .value="${this.every}"
          .singularContext="${this.singularContext}"
          .pluralContext="${this.pluralContext}"
          @change="${this.#dispatchChange}"
        >
        </contextualized-select>
      </div>
      ${!this.allowUntil ? html``: html`<div class="additional-repeat-options">
        <div class="addition-repeat-options-types">
          Until...
          <label>
            <input
              name="until"
              type="radio"
              value="forever"
              @change="${this.#setRepeatType}"
              ?checked=${this.type === undefined  || this.type === 'forever'}>
            Forever
          </label>
          <label>
            <input
              name="until"
              type="radio"
              value="enddate"
              @change="${this.#setRepeatType}"
              ?checked=${this.type === 'enddate'}>
            Specific Date
          </label>
          <label>
            <input
              name="until"
              type="radio"
              value="ntimes"
              @change="${this.#setRepeatType}"
              ?checked=${this.type === 'ntimes'}>
            After 'n' Times
          </label>
        </div>
        ${choose(
          this.type, [
            [undefined, () => html``],
            ['forever', () => html``],
            ['ntimes', () => html`
              <input
                class="additional-repeat-options-specifics"
                type="number"
                min="0"
                .value="${this.repeatTimes}"
                @change="${this.#setRepeatTimes}"
              />`
            ],
            ['enddate', () => html`
              <pikaday-date
                @change="${this.#setRepeatDateFromChange}"
              ></pikaday-date>
            `]
          ],
          () => html`unknown repeat type`
        )}
      </div>`}
    `;
  }
}

customElements.define('standard-repeating-options', StandardRepeatingOptions);
