
import { css, html, LitElement } from 'lit'
import { TableFilterSectionBase } from 'shared/tables/table-filter-section-base.component'
import { Assets } from 'shared/assets'
import { graphQL } from '../graphQL'


class TableFilterRoles extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
      header svg {
        position: relative;
        top: 2px;
      }
    `];

  static properties = {
    data: { state: true },
  };


  constructor() {
    super()
    this.valueType = 'array'
    this.data = []
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let query = `
    query roles{
      roles{
        id
        description
        name
      }
    }`;

    let variables = ``;

    // TODO: Add query for attributes
    // graphQL.query({
    //   query: query,
    //   variables,
    //   success: (response) => {
    //     this.data = response.data.roles
    //   },
    //   error: (message) => {
    //     console.log(`Error in filter while getting Groups: ${message}`)
    //   },
    // });
  }


  render() {
    let items = []
    for (const type of this.data) {
      items.push(this.itemHelper(type.name, type.id, html`${type.description}`));
    }

    return html`
      ${this.sectionHelper(html`
        ${Assets.inlineSvg('subnavigation/attributes.svg')}&nbsp;Roles`, items)}
    `;
  }
}
customElements.define('table-filter-roles', TableFilterRoles);

