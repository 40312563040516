sortByOrder = function (list) {
    let sortable = [];
    for (let key in list) {
        sortable.push([key, list[key]]);
    }

    sortable.sort(function (a, b) {
        return (a[1].sortOrder < b[1].sortOrder ? -1 : (a[1].sortOrder > b[1].sortOrder ? 1 : 0));
    });

    return sortable;
}