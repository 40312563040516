
import {css, html, LitElement} from "lit"


class MssDot extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    
    :host {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--success-color);
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
 `;


  render() {
    return html`
      <slot></slot>
    `;
  }
}
customElements.define('mss-dot', MssDot);

