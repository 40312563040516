

export function numberToHumanSize(number, significantDigits = 3, siUnits = false) {
  if (typeof number === 'string') {
    number = parseFloat(number)
  }
  if (typeof number !== 'number' || number === NaN || number === Infinity) {
    return ''
  }

  let divider
  let units
  if (siUnits) {
    divider = 1024
  } else {
    divider = 1000
  }
  units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  let index = 0
  while (Math.abs(number) >= divider) {
    number /= divider
    index += 1
  }

  let dp = significantDigits
  if (number < 1) {
  } else if (number < 10) {
    dp -= 1
  } else if (number < 100) {
    dp -= 2
  } else if (number < 1000) {
    dp -= 3
  } else {
    dp -= 4
  }
  if (dp < 0) dp = 0
  if (dp > 100) dp = 100
  if ((number % 1) === 0.0) dp = 0

  return `${number.toFixed(dp)} ${units[index]}`;
}

