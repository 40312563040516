
import {LitElement, html, css} from 'lit'
import {Assets} from 'shared/assets'
import {graphQL} from "../graphQL";



class MssFavoriteToggle extends LitElement {
  static styles = css`
		*, *:before, *:after {
		  box-sizing: border-box;
		}
    :host {
      display: inline-block;
      position: relative;
      width: 32px;
      height: 32px;
      color: #5B5B5B;
      background-color: rgba(0,0,0,0.0);
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0,0,0,0.0);
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      transition: background-color 0.2s, color 0.2s;
      text-align: center;
    }
    
    :host(:hover) {
      border-color: rgb(151, 151, 151);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    }
    
    :host(:active) {
      transform: translateY(1px);
      box-shadow: inset 0 0px 2px 0 rgba(0,0,0,0.50);
    }
    
    svg {
      position: relative;
      top: 4px;
      left: 1px;
      width: calc(100% - 11px);
      height: calc(100% - 11px);
    }
    svg g {
      fill: #DDDDDD;
    }
    :host([checked]) svg g {
      fill: #EACE0D;
    }
  `;


  static properties = {
    checked: {type: Boolean, reflect: true},
    itemType: {type: String},
    itemId: {type: String},
  };


  constructor() {
    super()
    this.checked = false
    this.itemType = ''
    this.itemId = ''
  }


  render() {
    return html`${Assets.inlineSvg('star.svg')}`;
  }


  connectedCallback() {
    super.connectedCallback()
    this._clickListener = (event) => {
      this._click(event)
    }
    this.addEventListener('click', this._clickListener)
  }


  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener('click', this._clickListener)
    this._resizeListener = null
  }


  _click(event) {
    this.checked = !this.checked
    this.dispatchEvent(new Event('change', {bubbles: true, composed: true}))

    let typeMap = {
      contentfolder: 'CONTENT_FOLDER',
      playlistfolder: 'PLAYLIST_FOLDER',
      playerfolder: 'PLAYER_FOLDER',
      vodgroup: 'VOD_GROUP',
      vodfolder: 'VOD_FOLDER'
    }
    let itemType = this.itemType.toLowerCase()
    if (typeMap[itemType])  itemType = typeMap[itemType]
    itemType = itemType.toUpperCase()

    let mutation = `
      mutation FavoriteItem($favorite: FavoriteItemInput!) {
        favoriteItem(input:$favorite) {
          message
        }
      }
    `;
    let variables = {
      favorite: {
        itemId: this.itemId,
        favorite: this.checked,
        itemType: itemType
      },
    }

    let msgType = itemType
    if (msgType === 'PLAYER_FOLDER') {
      msgType = 'Player Group'
    } else if (msgType === 'GROUP') {
      msgType = 'Location Group'
    } else {
      msgType = itemType.toLowerCase().replace('_', ' ')
      // Capitalize first letter
      msgType = msgType.charAt(0).toUpperCase() + msgType.slice(1);
    }

    graphQL.query({
      query: mutation,
      variables: variables,
      success: (response) => {
        if (this.checked) {
          gaudi.flash(`Successfully added ${msgType} to favorites.`, 'success', 3000)
        } else {
          gaudi.flash(`Successfully removed ${msgType} from favorites.`, 'success', 3000)
        }
      },
      error: (message) => {
        if (this.checked) {
          gaudi.flash(`Error adding ${msgType} to favorites.`, 'error', 3000)
        } else {
          gaudi.flash(`Error removing ${msgType} from favorites.`, 'error', 3000)
        }
        console.log(`GraphQL error from favorites mutation: ${message}`)
      },
    });

    event.stopPropagation()
    event.preventDefault()
  }
}
customElements.define('mss-favorite-toggle', MssFavoriteToggle);
