
import {css, html, LitElement} from "lit"
import {Dialog} from 'shared/dialog-box.component'
import {Assets} from 'shared/assets'
import {unsafeHTML} from "lit/directives/unsafe-html.js"


class TableActions extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    :host {
      display: inline-block;
      position: relative;
      width: 32px;
      height: 32px;
      color: #5B5B5B;
      background-color: #FFFFFF;
      border-width: 1px;
      border-style: solid;
      border-color: #FFFFFF;
      border-radius: 8px;
      outline: none;
      padding: 8px 10px;
      cursor: pointer;
      user-select: none;
      transition: background-color 0.2s, color 0.2s;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }
    :host(:hover) {
      background-color: #fcfcfc;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50);
    }
    :host([open]) {
      border-color: rgb(151, 151, 151);
    }
    :host(:active) {
      transform: translateY(1px);
      box-shadow: inset 0 0px 2px 0 rgba(0,0,0,0.50);
    }

    .ellipsis {
      position: absolute;
      top: 3px;
      left: 7px;
    }

    .close {
      position: absolute;
      top: 8px;
      left: 9px;
    }
    .close svg {
      width: 13px;
      height: 14px;
    }

    .container {
      position: absolute;
      right: 32px;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #FFFFFF;
      box-shadow: var(--section-box-shadow, 0 1px 4px 0 rgba(0, 0, 0, .5));
      border-radius: var(--section-border-radius, 8px);
      border-style: var(--section-border-style, none);
      z-index: 999999;
    }
    .container.open {
      display: block;
    }

    .item {
      display: flex;
      align-items: center;
      background-color: #FFFFFF;
      text-align: left;
      height: 40px;
      min-width: 180px;
      white-space: nowrap;
      padding-left: 15px;
    }
    .item:hover {
      background-color: #f2f2f2;
    }
    .item a {
      color: inherit;
      text-decoration: none;
      width: 100%;
      height: 100%;
      padding-top: 12px;
    }
    
    .item[aria-disabled="true"]{
      cursor: not-allowed;
      color: #CCCCCC;
    }
 `;

  static properties = {
    actions: {state: true},
    open: {type: Boolean, reflect: true},
  };


  constructor() {
    super()
    this.open = false
    this.actions = []
    this.containerTop = 0
  }


  connectedCallback() {
    super.connectedCallback()
    this._clickListener = (event) => {
      this._click(event)
    }
    this.addEventListener('click', this._clickListener)
  }


  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener('click', this._clickListener)
    this._resizeListener = null
  }


  render() {
    let icon = html`
      <div class="ellipsis">
        ${Assets.inlineSvg('bullets.svg')}
      </div>
    `;
    let container = html``
    if (this.open) {
      icon = html`
        <div class="close">
          ${Assets.inlineSvg('close.svg')}
        </div>
      `;

      let list = []
      for (const action of this.actions) {
        let attrs = ''
        if(action.disabled){
          attrs += ` aria-disabled="true"`
        }else {
          if (action.httpMethod) attrs += ` data-method="${action.httpMethod.toLowerCase()}"`
          if (action.url) attrs += ` data-url="${action.url}"`
          if (action.remote) attrs += ` data-remote="${action.remote}"`
          if (action.confirm) attrs += ` data-confirm="${action.confirm}"`
        }
        if (action.httpMethod &&
           (action.httpMethod.toLowerCase() === 'get' && !action.remote))
        {
          list.push(unsafeHTML(`
            <div class="item">
              <a href=${action.url} ${attrs}>
                ${action.title}
              </a>
            </div>
          `))
        } else {
          if(action.html){
            let el = action.html
            let htmlObject = $(el);
            htmlObject.on( "click", {thiz: action.thiz, playerId: action.playerId}, action.action )
            list.push(htmlObject)
          }else {
            list.push(unsafeHTML(`
              <div class="item" ${attrs}>
                ${action.title}
              </div>
            `))
          }
        }
        container = html`
          <div class="container" @click=${this.containerClick} style="top: ${this.containerTop}px;">
            ${list}
          </div>
        `
      }
    }
    return html`
      ${icon}
      ${container}
    `;
  }


  containerClick(event) {
    let target = event.target
    let method = target.getAttribute('data-method')
    let url = target.getAttribute('data-url')
    let remote = target.getAttribute('data-remote')
    if (!method || (method === 'get' && remote !== 'true')) {
      // pass this one on
      return true
    }

    let confirm = target.getAttribute('data-confirm')
    if (confirm) {
      Dialog.open(`
      <div slot="message">${confirm}</div>
      <mss-button slot="button" class="primary" value="Yes">Yes</mss-button>
      <mss-button slot="button" class="secondary" value="No">No</mss-button>
    `, (value) => {
        if (value === 'Yes') {
          this.containerClickFinish(method, url, remote)
          this.open = false
          this.requestUpdate()
        }
      })
    } else {
      this.containerClickFinish(method, url, remote)
      this.open = false
      this.requestUpdate()
    }

    event.stopPropagation()
    event.preventDefault()
    return false
  }


  containerClickFinish(method, url, remote) {
    let inputCSRF = ''
    let element = null
    let csrfParam = null
    let csrfToken = null
    element = document.querySelector('head meta[name="csrf-param"]')
    if (element) csrfParam = element.getAttribute('content')
    element = document.querySelector('head meta[name="csrf-token"]')
    if (element) csrfToken = element.getAttribute('content')
    if (csrfParam && csrfToken) {
      inputCSRF = `<input name=${csrfParam} value=${csrfToken} type="hidden" />`
    }

    if (method === 'get' && remote === 'true') {
      $.ajax({
        method: method,
        url: url,
        dataType: "script",
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        error: (xhr) => {
          console.log('Error from Ajax call to url ${url}')
        },
      })

    } else {
      let form = document.createElement('form')
      form.method = 'post'
      form.action = url
      form.style.display = 'none'
      form.innerHTML = `
        <input name="_method" value="${method}" type='hidden' />
        <input name="remote" value="${remote}" type='hidden' />
        ${inputCSRF}
        <input type="submit" />
      `;
      document.body.appendChild(form);
      form.querySelector('[type="submit"]').click();
    }
  }


  _click(event) {
    if (this.open) {
      this.dispatchEvent(new Event('close', {bubbles: true, composed: true}))
      this.open = false
    } else {
      this.dispatchEvent(new Event('open', {bubbles: true, composed: true}))
      this.open = true
    }
    let buttonY = event.currentTarget.getBoundingClientRect().y
    let viewPortHeight = window.innerHeight
    let containerHeight = this.actions.length * 40 + 16 + 6
    let diff = (buttonY + containerHeight) - viewPortHeight
    if (diff > 0) {
      this.containerTop = -12 - Math.floor((diff + 40.0) / 40.0) * 40.0
    } else  {
      this.containerTop = 0
    }
    return true
  }
}
customElements.define('table-actions', TableActions)

