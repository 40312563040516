import { html, css, LitElement } from 'lit';
import { templateStyles } from './template-styles';
import { closeIcon } from '../../shared/icons/close';

export class ContentTemplateModal extends LitElement {
    static properties = {
        open: Boolean, //lit defaults boolean properties to false
        includeCloseIcon: Boolean,
        header: String,
        cardSize: String
    };

    constructor() {
        super();
    }

    static styles = [
        templateStyles,
        css`
        .wrapper {
            opacity: 0;
            transition: opacity 0.25s ease-in;
            z-index: 20;
        }

        .wrapper:not(.open) {
            visibility: hidden;
        }

        .wrapper.open {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
            visibility: visible;
        }

        .overlay {
            padding: 0px 160px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, .5);
        }
          
        .hidden{
          display: none;
        }
    `];

    _closeIcon() {
        return html`
            <span class="close-icon" 
                @click=${this.close}>
                ${closeIcon}
            </span>`;
    }

    render() {
        return html`
            <div class="wrapper ${this.open ? 'open' : ''}" aria-hidden="${!this.open}">
                ${this.open ? html`
                    <div class="overlay">
                        <div class="card card-${this.cardSize}">
                            ${this.includeCloseIcon == 'true' ? this._closeIcon() : ''}
                            <header class="${this.header ? 'underline' : ''}">
                                ${this.header ? this.header : ''}
                            </header>
                            <div class="body"
                                @cancel=${this._cancelListener}
                                @submit=${this._submitListener}
                                >
                                <slot name="body"></slot>
                            </div>
                        </div>
                    </div>`
                : ''}
            </div>
            `;
    }

    close(event) {
        window.MMeditor.setPlaceholderProfiles([], true, true);
        this.dispatchEvent(
          new CustomEvent("close-modal", {
            detail: {location: undefined},
            bubbles: true,
          })
        );
        this.open = false;
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    _submitListener(e) {
        if (e) {
            let submitData = e.detail;
            this.dispatchEvent(new CustomEvent('submit-modal', {
                detail: { ...submitData },
                bubbles: true,
                composed: true,
                cancelable: true
            }));
        }
        this.close();
    }

    _cancelListener(e) {
      this.close(e);
    }
}

customElements.define('content-template-modal', ContentTemplateModal);
