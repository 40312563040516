window.modals = (function() {
    /**
     * function that gets or creates the modal layer
     */
    function getModalLayer() {
        // Use existing modal layer value if it exists
        let modalLayer = document.querySelector('body > .modal-layer');

        // return existing modal layer
        if (modalLayer) {
            return modalLayer;
        }

        // Create modal layer
        const body = document.querySelector('body');
        modalLayer = document.createElement('div');
        modalLayer.classList.add('modal-container');

        modalBackground = document.createElement('div');
        modalBackground.classList.add('modal-layer');
        modalLayer.appendChild(modalBackground);

        body.appendChild(modalLayer);

        return modalLayer;
    }

    /**
     * Creates a modal with the rawHTML as a body
     * @param {*} rawHTML 
     */
    function create(rawHTML) {
        modalLayer = getModalLayer();
        // const modalContent = modalLayer.querySelector('.modal-content')
        const modalContent = document.createElement('div');
        modalContent.classList.add('modal-content');
        modalContent.innerHTML = rawHTML;
        modalLayer.appendChild(modalContent);

        return {
            modalLayer,
            modalContent,
            remove: () => {
                modalContent.remove();
                modalLayer.remove();
            }
        };
    }

    return {
        create
    };
})();

Object.seal(modals);

