import {html, css, LitElement} from 'lit';
import {templateStyles} from './template-styles';
import './add-item-dropdown.component';
import './content-template-modal.component';
import './content-template-preview.component';
import './location-selector.component';
import './base-form.component';

export class ContentLocation extends LitElement {
  static styles = [
    templateStyles,
    css`
        .mini-top-nav {
            position: fixed;
            width: 100%;
            z-index: 10;
            height: 37px;
            background-color: #E2E2E2;
            font-size: 14px;
        }

        .nav {
            display: flex;
            align-items: center;
        }

        .nav-left {
            justify-content: space-between;
            width: 25%;
        }

        .nav-center {
            justify-content: center;
            width: 50%;
        }

        .nav-right {
            justify-content: end;
            width: 25%;
            padding-right: 20px;
        }

        .nav-item {
            cursor: pointer;
        }

        #back {
            display: flex;
            align-items: center;
            color: #5B5B5B;
            text-decoration: none;
        }

        #back-arrow {
            padding: 10px 10px 5px 10px;
        }

        #template-name {
            color: #2571CA;
            margin-right: 3px;
        }

        #save-template {
            width: 155px;
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
            border-radius: 3px;
            width: 155px;
            height: 20px;
            margin-top: auto;
            margin-bottom: auto;
            background: #1B6BC8;
            font-size: 10px;
            color: #FFFFFF;
        }

        #preview-template {
            width: 80px;
            border: 1px solid #A6A7A6;
            background-color: transparent;
        }
    `,
  ];

  static properties = {
    backPath: String,
    templateName: String,
    currentLocationData: Object,
    hidePreviewButton: Boolean,
    automatedContentLocation: Object, //TODO pass this location in from rails
    saveDisabled: {type: Boolean, state: true},
    modalHeader: {type: String, state: true},
    modalCardSize: {type: String, state: true},
    modalBody: {state: true},
  };

  constructor() {
    super();
    this.modalHeader = null;
    this.modalCardSize = null;
    this.modalBody = null;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  actionsTemplate() {
    return html`
        <button
                class="btn-sm nav-item"
                id="preview-template"
                @click=${this.openPreviewModal}
        >
            Preview
        </button>
    `;
  }

  templateModal() {
    return html`
        <content-template-modal
                @submit-modal=${this.updateTemplateName}
                @update-location=${this._updateLocation}
                @close-modal=${this._closeModal}
                id="template-modal"
                header=${this.modalHeader}
                cardSize=${this.modalCardSize}
                includeCloseIcon=true
        >
            ${this.modalBody}
        </content-template-modal>
    `;
  }

  render() {
    return html`
        ${this.actionsTemplate()}
        ${this.templateModal()}
    `;
  }

  _updateLocation(e) {
    this.automatedContentLocation = e.detail.location;
    this.updatePreviewModal(e.detail.restart);
  }

  _openModal() {
    const modalEl = this.shadowRoot.getElementById('template-modal');
    modalEl.open = true;
    window.dispatchEvent(
      new CustomEvent('open_modal', {})
    );
  }

  _closeModal() {
    const modalEl = this.shadowRoot.getElementById('template-modal');
    modalEl.open = false;
    this.dispatchEvent(
      new CustomEvent('close_preview', {
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
  }

  openPreviewModal() {
    window.previewMode = true
    this.dispatchEvent(
      new CustomEvent('regenerate_preview', {
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
    this.updatePreviewModal(false);
    this._openModal();
  }


  updatePreviewModal(restart) {
    this.modalHeader = 'Content Preview'
    this.modalCardSize = 'lg'
    if ((this.automatedContentLocation && !restart) || this.currentLocationData) {
      window.dispatchEvent(new Event("get-data"));
      this.modalBody =
        html`
            <content-template-preview
                    slot="body"
                    .location=${this.automatedContentLocation}
                    .currentLocationData=${this.currentLocationData}
            ></content-template-preview>`

    } else {
      this.modalBody = html`
          <location-selector slot="body"></location-selector>`;

    }
  }
}

customElements.define('content-location', ContentLocation);
