import { svg } from "lit";

export const text = svg`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="22px" height="22px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <rect id="path-1" x="0" y="0" width="1131" height="636"></rect>
        <filter x="-1.1%" y="-1.9%" width="102.1%" height="103.8%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-3" x="0" y="0" width="284" height="250" rx="5"></rect>
        <filter x="-2.5%" y="-2.0%" width="104.9%" height="105.6%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CT---placeholders---add1" transform="translate(-300.000000, -144.000000)">
            <g id="Rectangle" transform="translate(284.000000, 113.000000)">
                <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
            </g>
            <g id="Group-15" transform="translate(284.000000, 91.000000)">
                <g id="Rectangle-Copy">
                    <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                    <use fill="#EDEDED" fill-rule="evenodd" xlink:href="#path-3"></use>
                </g>
                <rect id="Rectangle-Copy-9" fill="#D3D8DD" opacity="0" x="0" y="42" width="284" height="38" rx="3"></rect>
                <g id="Group-2" transform="translate(17.000000, 53.000000)">
                    <text id="T" font-family="Helvetica" font-size="14.56" font-weight="normal" fill="#666666">
                        <tspan x="2.70308594" y="14">T</tspan>
                    </text>
                    <line x1="0.464285714" y1="14.95" x2="13.65" y2="14.95" id="Line-8" stroke="#666666" stroke-width="1.3" stroke-linecap="round"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
`;