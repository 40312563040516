import {Dialog} from './dialog-box.component';

window.dialogHelpers = {
  openBooleanDialog: async (message) => {
    return new Promise((res, rej) => Dialog.open(`
      <div slot="message">${message}</div>
      <mss-button slot="button" class="primary" value="true">Yes</mss-button>
      <mss-button slot="button" class="secondary" value="false">No</mss-button>
    `, (val) => {
      if (val === 'true') {
        res(true);
      }
      else if (val === 'false') {
        res(false)
      }
      else {
        res(false) //Dialog is closed by clicking the x
      }
    }))
  }
}