
import {css, html, LitElement} from 'lit'
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component'
import {graphQL} from '../graphQL'


class TableFilterPlayerTypes extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
    `];

  static properties = {
    data: {state: true},
  };


  constructor() {
    super()
    this.valueType = 'array'
    this.data = []
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let orgId = graphQL.organizationId()
    let actId = graphQL.accountId()
    let isSuper = orgId === 0 && actId === 0 ? true : false
    let isOrg = orgId != 0 && actId === 0

    let query = `
      query PlayerTypes($filter:PlayerTypeFilter){
        playerTypes(filter:$filter){
          name
          id
          parent{
            id
            name
          }
          subTypes(filter:$filter){
            id
            name
            isParent
          }
        }
      }`

    let filter = `
    {
      "filter": {
        ${isSuper ? '' : `${isOrg ? `"forOrganization": ${orgId}` : `"forAccount": ${actId}`}`}
      }
    }`

    graphQL.query({
      query: query,
      variables: filter,
      success: (response) => {
        this.data = response.data.playerTypes
      },
      error: (message) => {
        console.log(`Error getting player types: ${message}`)
      },
    });
  }


  render() {
    let items = []
    for (const type of this.data) {
      items.push(this.itemHelper(type.name, type.id, html`
        ${type.name}
      `))
      for (const subType of type.subTypes) {
        items.push(this.itemHelper(subType.name, subType.id, html`
          ${subType.name}
        `, 'subItem'))
      }
    }

    return html`
      ${this.sectionHelper(html`Player Type`, items)}
    `;
  }
}
customElements.define('table-filter-player-types', TableFilterPlayerTypes);

