import {html, css, LitElement} from 'lit'
import {Assets} from 'shared/assets';
import {graphQLIngestor} from "shared/graphQLIngestor"

class integrationModal extends LitElement {
    static properties = {
        integrationTypes: [],
    };
    static styles = [
        css`
        *, *:before, *:after {
          box-sizing: border-box;
        }
         header{
             background-color: transparent;
             border: none;
             color: #666666;
             border-bottom: 1px solid #979797;
             border-radius: 0;
             margin: 0px 0 20px;
             padding: 0 0 3px 0;
             line-height: 1em;
             text-align: left;
             font-size: 14px;
             font-weight: normal;
        }
         .flex{
             display: flex;
             margin: 0 20px;
             align-items: center;
             justify-content: center;
        }
         .flex a{
             max-width: 200px;
             flex: 1;
             margin: 12px;
             background-color: white;
             padding: 15px;
             border: 1px solid white;
             transition: border .2s;
             color: #438BDF;
             outline: none !important;
             text-decoration: none;
        }
         .flex a span{
             display: block;
             text-align: center;
             color: #5B5B5B;
             font-size: 13px;
             padding-bottom: 3px;
        }
         .flex a .image{
             background-color: #438BDF;
             height: 110px;
             display: flex;
             align-items: center;
             justify-content: space-around;
        }
        `,
    ];

    constructor() {
        super()
        this.integrationTypes = []
        graphQLIngestor.ready(() => this.getData())
    }

    render() {
        let list = []
        for (const integrationType of this.integrationTypes) {
            list.push(html`
                <a href="${Routes.new_integration_path()}?type=${integrationType.name}">
                    <span>${integrationType.friendlyName}</span>
                    <div class="image">
                        ${Assets.inlineSvg('content-rss.svg')}
                    </div>
                </a>`)
        }

        return html`
            <header>
                Create Integration
            </header>
            <div class="flex">
                ${list}
            </div>
        `;
    }


    getData() {
        let query = `
     query IntegrationTypes{
         integrationTypes{
           name
           friendlyName
         }
       }
    `;


        graphQLIngestor.query({
            query: query,
            success: (response) => {
                this.integrationTypes = response.data.integrationTypes
            },
            error: (message) => {
                console.log(`Error getting Integration Types: ${message}`)
            },
        });
    }

}

customElements.define('integration-modal', integrationModal)