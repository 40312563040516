
import {css, html, LitElement} from 'lit'
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component'
import {GetPlayerIssueFlagInfo, PlayerIssueFlagsInfo} from 'players/player-issue-flag.component'


class TableFilterPlayerIssueFlags extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
    player-issue-flag {
      position: relative;
      top: 3px;
    }
    `];


  constructor() {
    super()
    this.valueType = 'array'
  }

  render() {
    let items = []
    for (const info of PlayerIssueFlagsInfo) {
      items.push(this.itemHelper(info.name, info.tag.toUpperCase(), html`
        <player-issue-flag issue="${info.tag}"></player-issue-flag>&nbsp;${info.name}
      `))
    }

    return html`
      ${this.sectionHelper(html`<player-issue-flag issue="generic"></player-issue-flag>&nbsp;&nbsp;Flags`, items)}
    `;
  }
}
customElements.define('table-filter-player-issue-flags', TableFilterPlayerIssueFlags);

