
import {html, css, LitElement} from 'lit'
import {graphQL} from "shared/graphQL"
import 'shared/foundation/mss-checkbox.component'
import 'shared/foundation/mss-label.component'
import 'shared/foundation/mss-button.component'


class TagsEdit extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    .container-box {
      box-shadow: var(--section-box-shadow, 0 1px 4px 0 rgba(0, 0, 0, .5));
      border-radius: var(--section-border-radius, 8px);
      border-style: var(--section-border-style, none);
      padding-top: 22px;
      padding-left: 12px;
      margin-bottom: 50px;
    }
    
    .items-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    
    .item {
      position: relative;
      height: 36px;
      padding-left: 20px;
      padding-top: 8px;
      min-width: 220px;
    }
    mss-label {
      cursor: pointer;
    }
    
    .label-text {
      display: inline-block;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      top: -3px;
    }
    mss-checkbox {
      margin-right: 5px;
    }
    
    .grayed-out {
      opacity: 50%;
    }
    .grayed-out * {
      cursor: default;
    }
    
    .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;     
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 10px;
    }
    mss-button {
      margin-bottom: 15px;
    }
    
    .empty {
      width: 100%;
      height: 44px;
      padding-top: 18px;
      text-align: center;
    }
    
    .empty.error {
      color: var(--danger-color)
    }
  `;

  static properties = {
    type: {type: String},
    record_id: {type: String},
    allTags: {state: true},
    assignedIds: {state: true},
    inheritedIds: {state: true},
  };


  constructor() {
    super()
    this.type = ''
    this.record_id = ''
    this.allTags = null
    this.assignedIds = []
    this.inheritedIds = []
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {

    // Lower case first letter
    let queryName = this.type.charAt(0).toLowerCase() + this.type.slice(1);
    let inheritedQuery = ''
    if (queryName === 'player' || queryName === 'playerFolder') {
      inheritedQuery = `
          inheritedTags {
            id
          }
      `;
    }

    let query = `
      query {
        accountTags: tags(accountId: ${graphQL.accountId()}) {
          id
          name
        }
        ${queryName}(id: ${this.record_id}) {
          tags {
            id
          }
          ${inheritedQuery}
        }
      }
    `;

      graphQL.query({
      query: query,
      success: (response) => {
        this.allTags = response.data.accountTags
        this.assignedIds = []
        for (const tag of response.data[queryName].tags) {
          this.assignedIds.push(tag.id)
        }
        this.inheritedIds = []
        if (response.data[queryName].inheritedTags) {
          for (const tag of response.data[queryName].inheritedTags) {
            this.inheritedIds.push(tag.id)
          }
        }
      },
      error: (message) => {
        this.allTags = []
        this.dataError = message
        console.log(`Error getting tags: ${this.dataError}`)
      }
    });
  }


  render() {
    let items = []
    let buttons = html`
      <mss-button @click=${this.save} class="save primary">Save</mss-button>
      <mss-button @click=${this.cancel} class="cancel">Cancel</mss-button>
    `;
    if (!this.allTags) {
      items.push(html`
        <div class="empty"> 
          <em>Retrieving Data</em>
        </div>
      `);
      buttons = html`
        <mss-button @click=${this.cancel} class="cancel">Back</mss-button>
      `;
    } else if (this.dataError) {
      items.push(html`
        <div class="empty error">
          <em>Error Retrieving Data </em>
        </div>
      `);
      buttons = html`
        <mss-button @click=${this.cancel} class="cancel">Back</mss-button>
      `;
    } else if (this.allTags.length > 0) {
      let tags = [...this.allTags]   // make a copy of allTags
      // sort tags by name
      tags.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      for (const tag of tags) {
        let disabled = false
        let checked = this.assignedIds.includes(tag.id)
        let grayedOut = ''
        if (this.inheritedIds.includes(tag.id)) {
          disabled = true
          checked = true
          grayedOut = 'grayed-out'
        }
        items.push(html`
          <div class="item ${grayedOut}">
            <mss-label>
              <mss-checkbox name="${tag.name}" value="${tag.id}" 
                            ?checked=${checked}
                            ?disabled=${disabled}>
              </mss-checkbox>
              <div class="label-text">${tag.name}</div>
            </mss-label>
          </div>
        `)
      }
    } else {
      items.push(html`
        <div class="empty">
          <em>No Tags Defined</em>
        </div>
      `);
      buttons = html`
        <mss-button @click=${this.cancel} class="cancel">Back</mss-button>
      `;
    }

    return html`
      <div class="container-box">
        <div class="items-section">
          ${items}
        </div>
        <div class="buttons">
          ${buttons}
        </div>
      </div>
    `;
  }


  save(event) {
    let typeName = this.type.charAt(0).toLowerCase() + this.type.slice(1);
    let val = this.value()

    let mutation = `
      mutation TagUpdate($params: TaggableTagUpdateInput!) {
        taggableTagUpdate(input: $params) {
          clientMutationId
          message
        }
      }
    `;

    let variables = {
      params: {
        id: this.record_id,
        taggableType: this._taggableTypeMap(this.type),
        tagIds: val.assignedIds
      }
    }

    graphQL.query({
      query: mutation,
      variables: variables,
      success: (response) => {
        history.back()
      },
      error: (message) => {
        console.log(`Error updating tags: ${message}`)
        gaudi.flash(`Error updating tags.`, 'error', 0)
      }
    });

    event.stopPropagation()
    event.preventDefault()
  }


  _taggableTypeMap(str) {
    if (typeof str !== 'string') return ''
    let map = {
      content: 'CONTENT',
      datafeed: 'DATA_FEED',
      messagetemplate: 'MESSAGE_TEMPLATE',
      player: 'PLAYER',
      playerfolder: 'PLAYER_FOLDER',
      playlist: 'PLAYLIST',
      contenttemplate: 'CONTENT_TEMPLATE',
    }
    let ret = map[str.toLowerCase()]
    if (!ret) {
      ret = str.toUpperCase()
    }
    return ret
  }


  value() {
    let val = {assignedIds: [], unassignedIds: []}
    let list = this.shadowRoot.querySelectorAll('mss-checkbox')
    for (const box of list) {
      let id = box.value
      let assigned = box.checked
      if (box.disabled && !this.assignedIds.includes(id)) {
        assigned = false
      }
      if (assigned) {
        val.assignedIds.push(id)
      } else {
        val.unassignedIds.push(id)
      }
    }
    return val
  }


  cancel(event) {
    history.back()
    event.stopPropagation()
    event.preventDefault()
  }
}
customElements.define('tags-edit', TagsEdit);

