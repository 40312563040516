
import {LitElement, html, css} from 'lit'
import {classMap} from 'lit/directives/class-map.js'
import {Assets} from 'shared/assets';
import 'shared/foundation/mss-checkbox.component'
import 'shared/foundation/mss-label.component'


export class TableFilterSectionBase extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .filter-section {
      position: relative;
      border-bottom: 1px solid #e0e0e0;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    header {
      position: relative;
      cursor: pointer;
      height: 36px;
      font-size: 16px;
      padding: 7px 10px;
    }

    header .mark {
      position: absolute;
      height: 7px;
      width: 14px;
      top: 5px;
      right: 7px;
    }
    header .mark svg {
      height: 7px;
      width: 14px;
      transform: rotate(0deg);
      transition: transform 0.15s
    }
    header .mark svg g {
      fill: #5B5B5B;
    }

    .item {
      position: relative;
      height: 36px;
      opacity: 1.0;
      padding-left: 20px;
      padding-top: 1px;
      transition: height 0.15s ease, opacity 0.09s linear;
    }
    .item.subItem {
      padding-left: 54px;
    }
    mss-label {
      cursor: pointer;
    }

    .label-text {
      display: inline-block;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 218px;
      font-size: 14px;
      top: 1px;
    }
    .item.subItem .label-text {
      max-width: 184px;
    }
    mss-checkbox {
      margin-right: 5px;
    }

    .collapsed header .mark svg {
      transform: rotate(90deg);
    }
    .collapsed .item {
      height: 0;
      opacity: 0;
    }
    .collapsed:not(.changing) .item {
      display: none;
    }
  `;


  constructor() {
    super()
  }


  get value() {
    let list = this.shadowRoot.querySelectorAll('mss-checkbox')
    let empty = true
    let val = {}
    if (this.valueType === 'array') {
      val = []
      for (const box of list)  {
        if (box.checked) {
          val.push(box.value)
          empty = false
        }
      }
    } else {
      for (const box of list)  {
        if (box.checked) {
          val[box.getAttribute('name')] = box.getAttribute('value')
          empty = false
        }
      }
    }
    if (empty) {
      return null
    }
    return val
  }


  sectionHelper(title, items, collapsed=true) {
    let classes = {'filter-section': true, collapsed: collapsed}
    return html`
      <div class=${classMap(classes)}>
        <header @click=${this.headerClick}>
          ${title}
          <span class="mark">${Assets.inlineSvg('caret-solid.svg')}</span>
        </header>
        ${items}
      </div>
    `;
  }


  itemHelper(name, value, label, itemClass = '', isChecked = false) {
    if(isChecked) {
      return html`
        <div class="item ${itemClass}">
          <mss-label>
            <mss-checkbox name="${name}" value="${value}" checked="checked"></mss-checkbox>
            <div class="label-text" title="${name}">${label}</div>
          </mss-label>
        </div>
      `;
    }

    return html`
      <div class="item ${itemClass}">
        <mss-label>
          <mss-checkbox name="${name}" value="${value}"></mss-checkbox>
          <div class="label-text" title="${name}">${label}</div>
        </mss-label>
      </div>
    `;
  }


  headerClick(event) {
    let section = this.shadowRoot.querySelector('.filter-section')
    if (section) {
      if (section.classList.contains('collapsed')) {
        section.classList.add('changing')
        setTimeout(() => section.classList.remove('collapsed'), 3)
      } else {
        section.classList.add('collapsed', 'changing')
      }
      setTimeout(() => section.classList.remove('changing'), 130)
    }
  }


  clear() {
    let list = this.shadowRoot.querySelectorAll('mss-checkbox')
    for (const box of list)  {
      box.checked = false
    }
  }


  setValues(values) {
    let checkboxes = this.shadowRoot.querySelectorAll('mss-checkbox')
    for (const box of checkboxes) {
      let key
      if (this.valueType === 'array') {
        key = box.getAttribute('value')
      } else {
        key = box.getAttribute('name')
      }
      if (key) {
        let val = values[key]
        if (val !== undefined) {
          box.checked = val
        }
      }
    }
    if (this.parentNode) {
      this.parentNode.apply()
    }
  }
}
