import { svg } from "lit";

export const profileAndName = svg`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="19px" height="23px" viewBox="0 0 19 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group 15 Copy</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="1131" height="636"></rect>
        <filter x="-1.1%" y="-1.9%" width="102.1%" height="103.8%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-3" x="0" y="0" width="284" height="250" rx="5"></rect>
        <filter x="-2.5%" y="-2.0%" width="104.9%" height="105.6%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CT---placeholders---add1" transform="translate(-299.000000, -177.000000)">
            <g id="Rectangle" transform="translate(284.000000, 113.000000)">
                <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
            </g>
            <g id="Group-15" transform="translate(284.000000, 91.000000)">
                <g id="Rectangle-Copy">
                    <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                    <use fill="#F6F6F6" fill-rule="evenodd" xlink:href="#path-3"></use>
                </g>
                <rect id="Rectangle-Copy-11" fill="#D3D8DD" opacity="0" x="0" y="80" width="284" height="38" rx="3"></rect>
                <g id="Group-15-Copy" transform="translate(15.000000, 87.000000)">
                    <rect id="Rectangle" fill="#D8D8D8" x="3" y="2" width="14" height="14"></rect>
                    <g id="Group-17" transform="translate(6.000000, 3.000000)" fill="#7D7D7D">
                        <circle id="Oval" cx="4" cy="3" r="3"></circle>
                        <path d="M2,7 L6,7 C7.1045695,7 8,7.8954305 8,9 L8,13 L8,13 L0,13 L0,9 C-1.3527075e-16,7.8954305 0.8954305,7 2,7 Z" id="Rectangle"></path>
                    </g>
                    <path d="M0.605,0 L18.605,0 L18.605,21 L0.605,21 L0.605,0 Z" id="Rectangle" stroke="#7D7D7D" stroke-width="0.5" stroke-dasharray="0.9799999833106994"></path>
                    <text id="Full-Name" font-family="Helvetica" font-size="2.205" font-weight="normal" fill="#7D7D7D">
                        <tspan x="4.97630371" y="20">Full Name</tspan>
                    </text>
                </g>
            </g>
        </g>
    </g>
</svg>
`;

export const positionAndName = svg`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="19px" height="26px" viewBox="0 0 19 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group 15 Copy 2</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="1131" height="636"></rect>
        <filter x="-1.1%" y="-1.9%" width="102.1%" height="103.8%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-3" x="0" y="0" width="284" height="250" rx="5"></rect>
        <filter x="-2.5%" y="-2.0%" width="104.9%" height="105.6%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CT---placeholders---add1" transform="translate(-299.000000, -215.000000)">
            <g id="Rectangle" transform="translate(284.000000, 113.000000)">
                <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
            </g>
            <g id="Group-15" transform="translate(284.000000, 91.000000)">
                <g id="Rectangle-Copy">
                    <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                    <use fill="#F6F6F6" fill-rule="evenodd" xlink:href="#path-3"></use>
                </g>
                <rect id="Rectangle-Copy-12" fill="#D3D8DD" opacity="0" x="0" y="118" width="284" height="38" rx="3"></rect>
                <g id="Group-15-Copy-2" transform="translate(15.000000, 125.000000)">
                    <rect id="Rectangle" fill="#D8D8D8" x="3" y="2" width="14" height="14"></rect>
                    <g id="Group-17" transform="translate(6.000000, 3.000000)" fill="#7D7D7D">
                        <circle id="Oval" cx="4" cy="3" r="3"></circle>
                        <path d="M2,7 L6,7 C7.1045695,7 8,7.8954305 8,9 L8,13 L8,13 L0,13 L0,9 C-1.3527075e-16,7.8954305 0.8954305,7 2,7 Z" id="Rectangle"></path>
                    </g>
                    <path d="M0.605,0 L18.605,0 L18.605,24 L0.605,24 L0.605,0 Z" id="Rectangle" stroke="#7D7D7D" stroke-width="0.5" stroke-dasharray="0.9799999833106994"></path>
                    <text id="Full-Name" font-family="Helvetica" font-size="2.205" font-weight="normal" fill="#7D7D7D">
                        <tspan x="4.97630371" y="20">Full Name</tspan>
                    </text>
                    <text id="Position" font-family="Helvetica" font-size="2.205" font-weight="normal" fill="#7D7D7D">
                        <tspan x="6.07772705" y="23">Position</tspan>
                    </text>
                </g>
            </g>
        </g>
    </g>
</svg>
`;

export const workAndName = svg`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="19px" height="26px" viewBox="0 0 19 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group 15 Copy 4</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="1131" height="636"></rect>
        <filter x="-1.1%" y="-1.9%" width="102.1%" height="103.8%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-3" x="0" y="0" width="284" height="250" rx="5"></rect>
        <filter x="-2.5%" y="-2.0%" width="104.9%" height="105.6%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CT---placeholders---add1" transform="translate(-299.000000, -258.000000)">
            <g id="Rectangle" transform="translate(284.000000, 113.000000)">
                <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
            </g>
            <g id="Group-15" transform="translate(284.000000, 91.000000)">
                <g id="Rectangle-Copy">
                    <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                    <use fill="#F6F6F6" fill-rule="evenodd" xlink:href="#path-3"></use>
                </g>
                <rect id="Rectangle-Copy-8" fill="#D3D8DD" opacity="0" x="0" y="156" width="284" height="38" rx="3"></rect>
                <g id="Group-15-Copy-4" transform="translate(15.000000, 168.000000)">
                    <rect id="Rectangle" fill="#D8D8D8" x="3" y="2" width="14" height="14"></rect>
                    <g id="Group-17" transform="translate(6.000000, 3.000000)" fill="#7D7D7D">
                        <circle id="Oval" cx="4" cy="3" r="3"></circle>
                        <path d="M2,7 L6,7 C7.1045695,7 8,7.8954305 8,9 L8,13 L8,13 L0,13 L0,9 C-1.3527075e-16,7.8954305 0.8954305,7 2,7 Z" id="Rectangle"></path>
                    </g>
                    <path d="M0.605,0 L18.605,0 L18.605,24 L0.605,24 L0.605,0 Z" id="Rectangle" stroke="#7D7D7D" stroke-width="0.5" stroke-dasharray="0.9799999833106994"></path>
                    <text id="Full-Name" font-family="Helvetica" font-size="2.205" font-weight="normal" fill="#7D7D7D">
                        <tspan x="4.97630371" y="20">Full Name</tspan>
                    </text>
                    <text id="Start-Date" font-family="Helvetica" font-size="2.205" font-weight="normal" fill="#888888">
                        <tspan x="5.03659668" y="23">Start Date</tspan>
                    </text>
                </g>
            </g>
        </g>
    </g>
</svg>
`;