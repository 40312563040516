import { html, css } from 'lit'
import { TableBase } from 'shared/tables/table-base.component'
import { graphQLIngestor } from "shared/graphQLIngestor"
import { CRONExpressionToString as cronToString } from '../shared/cron-parser'


class integrationsTable extends TableBase {

    static styles = [
        TableBase.styles,
        css`

    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0
    }
    .action:hover svg circle {
      stroke: #d6d9e0
    }

   .danger {
      color: #D75762;
    }

    .c-column span{
      display: inline-flex;
    }

   .fa{
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      cursor: pointer;
   }


    .fa-exclamation-triangle{
      margin-left: 5px;
      margin-top: 3px;
    }
    .fa-exclamation-triangle:before {
        content: "\\f071";
    }

    `,
    ];

    static properties = {
        allowed: {}
    };


    constructor() {
        super()

        this.route = '/integrations/:id'
        this.dataName = 'integrations'
        this.columns = [
            { key: 'name', title: 'Name' },
            { key: 'updatedAt', title: 'Last update' },
            { key: 'fileUrl', title: 'File URL' },
            { key: 'status', title: 'Status' },
            { key: 'schedule', title: 'Schedule' },
            { key: 'actions', title: '', display: 'always-right' },
        ]
        this.paginationEnabled = true;
        this.paginationOptions.dataName = this.dataName;
        this.searchEnabled = true
    }

    getData() {
        let query = `
          query Integrations($filters:IntegrationFilter!, $total_count_filters: IntegrationFilter!){
            integrationCount(filters: $total_count_filters)
            integrations(filters:$filters){
              name
              updatedAt
              id
              backgroundJobs{
                completedAt
                status
                hasErrors
                }
              fields{
                name
                id
                type
                value
              }
            }
          }
        `;

        let variables = {
            filters: {
                pager: {
                    pageSize: this.pageSize,
                    page: this.page - 1,
                },
                "forAccount": window.GraphQL.accountId
            },
            total_count_filters: {
                "forAccount": window.GraphQL.accountId
            }
        };

        if (this.searchValue) {
            variables.filters["nameLike"] = this.searchValue
        }

        graphQLIngestor.query({
            query: query,
            variables: variables,
            success: (response) => {
                if (response.data) {
                    this.totalCount = response.data.integrationCount;
                    this.data = response.data.integrations
                }
            },
            error: (message) => {
                this.data = []
                console.log('GraphQLIngestor Errors: ', message)
            },
        });
    }


    cellValue(colKey, item) {
        let value = html``;

        if (colKey === 'name') {
            value = item.name
        } else if (colKey === 'updatedAt') {
            let dates = $.map(item.backgroundJobs, function (i) {
                if (i['completedAt']) {
                    return Date.parse(i['completedAt'])
                }
            })
            if (dates.length > 0) {
                let paredDate = new Date(Math.max(...dates));
                value = Util.formatDateTime(paredDate);
            } else {
                value = ''
            }
        } else if (colKey === 'status') {
            value = []
            if (item.backgroundJobs.length > 0) {
                let backgroundJob = item.backgroundJobs[0]
                let jobStatus = backgroundJob.status
                if (jobStatus == 'COMPLETED' && backgroundJob.hasErrors) {
                    jobStatus = 'WARNING'
                }
                value.push(html`${jobStatus}`);
                if (backgroundJob.hasErrors) {
                    value.push(html`<span class="danger fa fa-exclamation-triangle"></span>`)
                }
            }
            value
        } else if (colKey === 'fileUrl') {
            value = item.fields.find(x => x.name === 'SFTP Address')?.value || item.fields.find(x => x.name === 'FTP Address')?.value
        } else if (colKey === 'schedule') {
            value = cronToString(item.fields.find(x => x.name === 'Schedule String')?.value)
        } else if (colKey === 'mapped') {
            let data = item.fields.find(x => x.name === 'Field Map')?.value
            if (data) {
                value = '<div>'
                let parsedJson = JSON.parse(data)
                Object.entries(parsedJson).forEach(([k, v]) => {
                    value += k + ':' + v + '<br>';
                });
                value += '</div>'
            }
            value = $(value);
        } else if (colKey === 'actions') {
            value = [
                {
                    title: 'View',
                    url: Routes.integration_path(item.id),
                    httpMethod: 'get',
                    remote: false,
                    protect: 'show'
                },
                {
                    title: 'Edit',
                    url: Routes.edit_integration_path(item.id),
                    httpMethod: 'get',
                    remote: false,
                    protect: 'update'
                }, {
                    title: 'Run',
                    url: Routes.integration_run_path(item.id),
                    httpMethod: 'post',
                    remote: false,
                    protect: 'edit'
                },
                {
                    title: 'Delete',
                    url: Routes.integration_path(item.id),
                    httpMethod: 'delete',
                    remote: false,
                    confirm: `Are you sure you want to delete ${item.name || 'this record'}?`,
                    protect: 'destroy'
                },
            ]
        }
        return value
    }
}

customElements.define('integrations-table', integrationsTable)

