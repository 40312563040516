import {LitElement, html, css} from 'lit'
import {Assets} from 'shared/assets'
import 'shared/tables/table-filter.component'
import 'shared/tables/table-search.component'
import 'shared/tables/table-settings.component'
import 'shared/tables/table-pagination.component'
import 'shared/tables/table-actions.component'
import 'shared/tables/table-bulk-actions.component'
import {graphQL} from "../graphQL";


export class TableBaseCellClick extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    .table-base {
      box-shadow: var(--section-box-shadow, 0 1px 4px 0 rgba(0, 0, 0, .5));
      border-radius: var(--section-border-radius, 8px);
      border-style: var(--section-border-style, none);
      padding: 0 17px;
      font-size: 15px;
    }

    .table-toolbar {
      display: flex;
      justify-content: space-between;
      padding-top: 26px;
      padding-bottom: 20px;
      padding-left: 8px;
      padding-right: 8px;
      min-height: 60px;
      border-bottom: 1px solid #e0e0e0;
    }

    .toolbar-right {
      display: flex;
    }

    .c-column {
      overflow: hidden;
      flex: 1 0;
      align-items: center;
      padding-bottom: 0px;
      padding-left: 10px;
      padding-right: 2px;
      padding-top: 0px;
      height: 100%;
      display: flex;
    }
    .c-column > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .c-row {
      display: flex;
      height: 60px;
      padding: 0;
      transition: background-color 0.2s ease-in-out;
    }
    .c-row, header, .c-row:last-child, header:last-child {
      border-bottom: 1px solid #e0e0e0;
    }
    .c-row:hover {
      background-color: #f2f2f2;
    }
    .c-row mss-checkbox, .c-row mss-favorite-toggle {
      visibility: hidden;
    }
    .c-row mss-checkbox[checked], .c-row mss-favorite-toggle[checked] {
      visibility: visible;
    }
    .c-row:hover mss-checkbox, .c-row:hover mss-favorite-toggle {
      visibility: visible;
    }

    header {
      display: flex;
      height: 47px;
    }
    header .c-column {
      display: flex;
      padding-right: 16px;
      font-size: 15px;
      font-weight: bold;
    }
    header .sorted {
      color: #438BDF;
    }
    header [data-sortable] {
      position: relative;
      cursor: pointer;
    }
    header [data-sortable] > span {
      position: relative;
      padding-right: 24px;
    }

    .caret-icon svg {
      height: 7px;
      width: 14px;
      transition: transform 0.3s
    }
    .caret-icon svg g {
      fill: #438bdf;
    }

    header [data-sortable] .mark {
      display: none;
      position: absolute;
      top: -2px;
      right: 0px;
      height: 16px;
      width: 16px;
    }
    header [data-sortable].descending.sorted .mark {
      display: inline-block;
    }
    header [data-sortable].descending.sorted .mark svg {
      transform: rotate(0deg);
    }
    header [data-sortable].ascending.sorted .mark {
      display: inline-block;
    }
    header [data-sortable].ascending.sorted .mark svg {
      transform: rotate(180deg);
    }

    .empty-table {
      width: 100%;
      height: 57px;
      background-color: #f3f3f3;
      padding-top: 18px;
      text-align: center;
      border-bottom: 1px solid #979797;
    }

    .c-row:hover table-actions {
      border-color: #979797;
    }

    .c-column[data-type=icon] {
      max-width: 50px;
    }
    .c-column[data-type=bulk_action] {
      max-width: 32px;
    }
    .c-column[data-type=actions] {
      display: flex;
      justify-content: space-around;
      overflow: visible;
      min-width: 46px;
      max-width: 48px;
    }
    .c-column[data-type=favorite] {
      display: flex;
      justify-content: space-around;
      overflow: visible;
      min-width: 48px;
      max-width: 48px;
    }
    .c-column[data-type=favorite] > span {
      padding: 2px;
    }
  `;

  static properties = {
    data: {state: true},
    totalCount: {state: true},
  };


  constructor() {
    super()
    this.day = 0
    this.data = null
    this.name = this.constructor.name
    this.columns = []
    this.orderBy = ''
    this.orderType = 'ascending'   // ascending or descending

    this.paginationEnabled = true
    this.page = 1
    this.totalCount = 0

    this.filterSections = null
    this.bulkActionsMenu = null
    this.bulkActionValues = []
    this.paginationOptions = {
      dataName: '',
      pageSizeOptions: [10, 25, 50, 100, 200],
      pageSizeDefault: 25,
      navMax: 5,
    }
  }


  connectedCallback() {
    super.connectedCallback()
    if (this.getData) {
      graphQL.ready(() => this.getData())
    }
  }


  render() {
    this.actionList = {}
    // this.actionsFlag = false
    // for (const col of this.columns) {
    //   if (col.key === 'actions') this.actionsFlag = true;
    // }

    // let tableClasses = this.actionsFlag ? 'actions' : ''
    // if (this.tableClasses) {
    //   tableClasses = `${tableClasses} ${this.tableClasses}`
    // }

    return html`
      <div class="table-base" style="width: 100%">
        <div class="table-toolbar">
          ${this.toolbar()}
        </div>
        <header>
          ${this.tableHeader()}
        </header>
        <div class="table-base-body">
          ${this.tableBody()}
        </div>
        <footer>
          ${this.footer()}
        </footer>
      </div>
    `;
  }


  updated() {
    let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
    for (const box of list) {
      box.addEventListener('change', (event) => {
        this.bulkActionCheckboxChange(event)
      })
    }
  }


  toolbar() {
    let left = []
    if (this.filterSections) {
      left.push(html`
        <table-filter @change=${this.filterChange}>${this.filterSections}</table-filter>
      `)
    }

    if (this.bulkActionsMenu) {
      left.push(html`
        <table-bulk-actions id="bulk_actions"
              @selectAll=${this.bulkActionSelectAll}
              @deselectAll=${this.bulkActionDeselectAll}>
          ${this.bulkActionsMenu}
        </table-bulk-actions>
      `)
    }

    let right = []
    if (this.searchEnabled) {
      right.push(html`<table-search @change=${this.searchChange}></table-search>`);
    }
    if (this.settingsEnabled) {
      right.push(html`
        <table-settings
            @save=${this.settingsSave}
            @close=${this.settingsClose}
            .settings=${this.settings()}
            .defaultSettings=${this.defaultSettings()}
        >
        </table-settings>
      `);
    }

    return html`
      <div class="toolbar-left">${left}</div>
      <div class="toolbar-right">${right}</div>
    `;
  }


  filterChange(event) {
    this.filterValues = event.currentTarget.value
    this.updateTable()
  }


  setFilterValues(values) {
    let filter = this.shadowRoot.querySelector('table-filter')
    if (filter) {
      filter.setValues(values)
    }
  }


  bulkActionSelectAll(event) {
    let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
    for (const box of list) {
      box.checked = true
    }
    this.updateBulkActionValues()
  }


  bulkActionDeselectAll(event) {
    let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
    for (const box of list) {
      box.checked = false
    }
    this.updateBulkActionValues()
  }


  bulkActionCheckboxChange(event) {
    let bulkActions = this.shadowRoot.querySelector('table-bulk-actions')
    if (event.currentTarget.checked) {
      bulkActions.open = true
    } else {
      let found = false
      let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
      for (const box of list) {
        if (box.checked) {
          found = true
        }
      }
      // If any of the boxes are checked
      // then the bulk action menu should be open.
      bulkActions.open = found
    }
    this.updateBulkActionValues()
  }


  updateBulkActionValues() {
    let values = []
    let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
    for (const box of list) {
      if (box.checked) {
        values.push(box.value)
      }
    }
    this.bulkActionValues = values
  }


  searchChange(event) {
    this.searchValue = event.currentTarget.value
    this.updateTable()
  }


  tableHeader() {
    let header = []
    for (const setting of this.settings()) {
      let col = this.getColumnByKey(setting.key)
      if (!col || !setting.visible) continue
      let classVal = 'c-column'
      classVal += this.columnClasses(col)
      if (col.sortable) {
        if (col.sortable === this.orderBy) {
          classVal += ' sorted ' + this.orderType
        }
        header.push(html`
          <div data-type="${col.key}" class="${classVal}"
               data-sortable="${col.sortable}" @click=${this.columnHeaderClick}>
            <span>${col.title}<span class="mark caret-icon">${Assets.inlineSvg('caret-solid.svg')}</span></span>
          </div>
        `);
      } else {
        header.push(html`
          <div data-type="${col.key}" class="${classVal}">
            ${col.title}
          </div>
        `);
      }
    }
    return header
  }


  columnHeaderClick(event) {
    let heading = event.currentTarget
    let asc = heading.classList.contains('ascending')
    // clear out current sort
    let list = this.shadowRoot.querySelectorAll(`header [data-sortable="${this.orderBy}"]`)
    for (const head of list) {
      head.classList.remove('sorted', 'ascending', 'descending')
    }
    // set new sort
    heading.classList.add('sorted')
    this.orderBy = heading.getAttribute('data-sortable')
    if (asc) {
      heading.classList.remove('ascending')
      this.orderType = 'descending'
      heading.classList.add(this.orderType)
    } else {
      heading.classList.remove('descending')
      this.orderType = 'ascending'
      heading.classList.add(this.orderType)
    }
    this.updateTable()
  }


  tableBody() {
    let body = []
    if (!this.data) {
      // let dummy = []
      // if (this.actionsFlag) {
      //   dummy.push(html`<div class="c-column"></div>`)
      // }
      body.push(html`
        <div class="c-row empty">
          <div class="c-column"><span><em>Retrieving Data</em></span></div>
        </div>
      `);
    } else if (this.data.length > 0) {
      for (let index=0; index < this.data.length; index++) {
        let item = this.data[index]
        let row = []
        for (const setting of this.settings()) {
          let col = this.getColumnByKey(setting.key)
          if (!col || !setting.visible) continue
          let classes = this.columnClasses(col)
          let value = this.cellValue(col.key, item)
          let cellUrl = this.cellUrl(col.key, item)
          if (cellUrl) {
            let link = []
            if (Array.isArray(value)) {
              if ((value.length === 2) && (col.key !== 'attributes')) {
                link.push(html`<span class="user_name">${value[0] || html`--`}</span>`)
                link.push(html`<span class="user_position">${value[1] || html``}</span>`)
              } else if (col.key === 'attributes') {
                for (const att of value) {
                  link.push(html`<div class="attribute_pill">${att}</div>`)
                }
              }
            } else {
              if (col.circular_image) {
                link.push(html`<div class="circular-img">${value || html`--`}</div>`)
                if (item.contents && item.contents.length > 0) {
                  link.push(html`<div class="user-in-content_badge"><i class="far fa-eye"></i></div>`)
                }
              } else {
                link.push(html`${value || html`--`}`)
              }
            }
            row.push(html`<a class="c-column ${classes}" data-type="${col.key}" href="${cellUrl}">${link}</a>`)
          } else if (col.key === 'actions' && Array.isArray(value)) {
            let actionComponent = html``
            if (!this.rowIsDisabled(item)) {
              actionComponent = html`
                <table-actions .actions=${value}
                               @open=${this.actionsOpen}
                               @close=${this.actionsClose}
                >
                </table-actions>
              `;
            }
            row.push(html`
              <div class="c-column ${classes}" data-type="${col.key}" data-index="${index}">
                ${actionComponent}
              </div>
            `)
            // if (value.length > 0) {
            //   row.push(html`
            //     ${Assets.inlineSvg('bullets.svg')}
            //   `);
            //   let list = []
            //   for (const action of value) {
            //     this.actionList[action.handler] = true
            //     let params = ''
            //     for (const key in action) {
            //       params += ` data-${key}="${action[key]}"`
            //     }
            //     list.push(html`
            //       <li>
            //         <a href="${action.url}"${params}>${action.title}</a>
            //       </li>
            //     `);
            //   }
            //   row.push(html`<div class="c-column actionMenu"><ul>${list}</ul></div>`)
            // }
          } else {
            let key = (col.key === 'favorite') && this.rowIsDisabled(item) ? 'disabled' : col.key
            row.push(html`
              <div class="c-column ${classes}" data-type="${key}" data-index="${index}" @click=${this.cellClickFrontend}>
                <span>${value}</span>
              </div>
            `);
          }
        }
        body.push(html`<div data-id="${item.id}" class="c-row ${this.rowClass(item)}">${row}</div>`)
      }
    } else {
      if (this.page !== 1) {
        this.page = 1
      }
      body.push(html`
        <div class="empty-table">
          <em>No ${this.dataName} found!</em>
        </div>
      `);
    }
    return body
  }


  actionsOpen(event) {
    let actions = this.shadowRoot.querySelectorAll('table-actions')
    for (const action of actions) {
      action.open = false
    }
  }


  actionsClose(event) {
    let actions = this.shadowRoot.querySelectorAll('table-actions')
    for (const action of actions) {
      action.open = false
    }
  }


  cellClickFrontend(event) {
    let key = event.currentTarget.getAttribute('data-type')
    let index = event.currentTarget.getAttribute('data-index')
    this.cellClick(key, this.data[index], event)
  }


  footer() {
    if (!this.paginationEnabled) return ''

    return html`
      <table-pagination
          .page=${this.page}
          .pageSize=${this.pageSize}
          .totalCount=${this.totalCount}
          .options=${this.paginationOptions}
          @change=${this.paginationChange}
      ></table-pagination>
    `;
  }


  paginationChange(event) {
    this.page = event.currentTarget.page
    this.pageSize = event.currentTarget.pageSize
    this.updateTable()
  }


  columnClasses(column) {
    return column.class ? ' ' + column.class : ''
  }


  updateTable() {
    let pagination = this.querySelector('table-pagination')
    if (pagination) {
      pagination.page = 1
      this.page = 1
    }
    if (this.getData) {
      this.getData()
    } else {
      this.requestUpdate()
    }
  }


  get pageSize() {
    let val = localStorage.getItem(this.name + '_pageSize')
    if (val === 'null' || val === undefined || val === null || val ==='') {
      this.pageSize = this.paginationOptions.pageSizeDefault
    } else {
      val = parseInt(val)
      if (isNaN(val) || val <= 0) {
        val = this.paginationOptions.pageSizeDefault;
        this.pageSize = val
      }
    }
    return val
  }


  set pageSize(val) {
    localStorage.setItem(`${this.name}_pageSize`, val)
  }


  settings() {
    let settings = this.getStoredSettings()
    if (settings) {
      return settings
    }
    // No settings stored for this table.
    // Use the default settings.
    return this.defaultSettings()
  }


  defaultSettings() {
    // Build default table setting.
    let settings = []
    // Make a rough determination of the device type based on screen size.
    let screenType = 'desktop'
    const width = screen.width
    if (width < 480) {
      screenType = 'mobile'
    } else if (width < 768) {
      screenType = 'tablet'
    }

    let defaults = this.defaultTableSettings() || {}
    let platform = defaults[screenType] || {}
    let visibleColumns = platform.visibleColumns

    for (const col of this.columns) {
      let visible = true
      if (visibleColumns) {
        visible = visibleColumns.includes(col.key) ||
            (col.display && col.display.indexOf('always') === 0)
      }
      settings.push({
        key:      col.key,
        title:    col.title || (col.key === 'bulk_action' ? 'select' : col.key),
        visible:  visible,
        display:  col.display,
      })
    }
    return settings
  }


  saveSettings(settings) {
    let key = `${this.name}_tableSettings`
    if (!Array.isArray(settings)) settings = null
    let str = undefined
    if (settings) {
      try {
        str = JSON.stringify(settings)
        if (str) {
          localStorage.setItem(key, str)
          return true
        }
      } catch (e) {
        str = undefined
      }
    }
    // Something when wrong, remove stored value.
    localStorage.removeItem(key)
    return false
  }


  getStoredSettings() {
    let key = `${this.name}_tableSettings`
    let raw = localStorage.getItem(key)
    if (raw === 'null' || raw === undefined || raw === null || raw === '') return null
    let val = []
    try {
      val = JSON.parse(raw)
    } catch (e) {
      return null
    }
    // Do a simple check on the retrieved settings.
    if (!Array.isArray(val)) return null
    for (const item of val) {
      if (!item.key) return null
    }
    return val
  }


  settingsSave(event) {
    this.saveSettings(event.currentTarget.settings)
    this.requestUpdate()
  }


  settingsClose(event) {
    event.stopPropagation()
  }


  getColumnByKey(key) {
    for (const col of this.columns) {
      if (col.key === key) {
        return col
      }
    }
    return null
  }



  //-------------------------------------------------------------------------
  //-------------------------------------------------------------------------
  cellValue(colKey, item) {
    return item[colKey]
  }

  //-------------------------------------------------------------------------
  cellUrl(colKey, item) {
    return null
  }

  //-------------------------------------------------------------------------
  rowIsDisabled(item) {
    return false
  }

  //-------------------------------------------------------------------------
  rowClass(item) {
    return ''
  }

  //-------------------------------------------------------------------------
  cellClick(key, item, event) {
    return ''
  }

  //-------------------------------------------------------------------------
  defaultTableSettings() {
    return {}
  }
}
