
import {css, html, LitElement} from "lit"
import {Assets} from 'shared/assets'


export const PlayerIssueFlagsInfo = [
  {
    tag: "frequent_reboots",
    tagAlias: "frequentReboots",
    name: "Multiple Reboots",
    description: "The player has recently been rebooting frequently (Approximately more than 5 times in the past hour).",
  },
  {
    tag: "with_tv_issues",
    tagAlias: "withTvIssues",
    name: "TV Issue",
    description: "The player's display is off or disconnected.",
  },
  {
    tag: "html_invalid",
    tagAlias: "htmlInvalid",
    name: 'HTML Invalid',
    description: "The player received invalid HTML that it couldn't play.",
  },
  {
    tag: "manifest_mismatch",
    tagAlias: "manifestMismatch",
    name: "Not Updating",
    description: "The player is not updating to the latest playlist/content from the server.",
  },
  {
    tag: "smart_scan_issues",
    tagAlias: "smartScanIssues",
    name: "Smart Scan Issues",
    description: "The last screenshot indicates a possible problem with the player.",
  },
  {
    tag: "usb_storage_not_installed",
    tagAlias: "usbStorageNotInstalled",
    name: "USB Unplugged",
    description: "USB storage is not installed or recognized in the player.",
  },
]


export function GetPlayerIssueFlagInfo(issue) {
  if (!issue)   return null
  let tag = issue.toLowerCase()
  if(tag == 'display_off' || tag == 'display_connected'){
    tag = 'with_tv_issues'
  }
  for (const item of PlayerIssueFlagsInfo) {
    if (tag === item.tag || tag === item.tagAlias.toLowerCase()) {
      return item
    }
  }
  return null
}


class PlayerIssueFlag extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    :host {
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    :host([issue=smart_scan_issues]) svg {
      position: absolute;
      top: -27.5%;
      left: -25.6%;
      width: 150%;
      height: 150%;
    }

 `;

  static properties = {
    issue: {type: String},
  };


  constructor() {
    super()
    this.issue = ''
  }


  render() {
    let flag = Assets.inlineSvg('flag.svg')
    let issue = this.issue.toLowerCase()

    if (issue === 'manifest_mismatch') {
      flag = Assets.inlineSvg('flag_not_updating.svg')
    }
    else if (issue === 'frequent_reboots') {
      flag = Assets.inlineSvg('flag_reboots.svg')
    }
    else if (issue === 'usb_storage_not_installed') {
      flag = Assets.inlineSvg('flag_usb_unplugged.svg')
    }
    else if (issue === 'html_invalid') {
      flag = Assets.inlineSvg('flag.svg')
    }
    else if (issue === 'hdmi_unplugged') {
      // This is no longer supported by the players
      flag = Assets.inlineSvg('flag_tv_issue.svg')
    }
    else if (issue === 'with_tv_issues') {
      flag = Assets.inlineSvg('flag_tv_issue.svg')
    }
    else if (issue === 'smart_scan_issues') {
      flag = Assets.inlineSvg('screenshot/icon-broken.svg')
    }
    else if (issue === 'generic') {
      flag = Assets.inlineSvg('flag.svg')
    }

    return html`
      ${flag}
    `;
  }
}
customElements.define('player-issue-flag', PlayerIssueFlag);

