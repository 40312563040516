
import {LitElement, html, css} from 'lit'
import {Assets} from 'shared/assets';


export class Dialog extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    
    :host {
      display: flex;
      position: fixed;
      justify-content: center;
      align-items: flex-start;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 5500;
      background: rgba(0, 0, 0, 0.5);
      overflow: auto;
      font-size: 14px;
    }
    
    .box {
      display: block;
      position: relative;
      margin-top: 10vh;
      background-color: #EBEBEB;
      width: 460px;
      padding: 25px;
      border: 1px solid #DCDCDC;
      border-radius: 8px;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5);
    }
    
    slot[name=message] {
      display: block;
    }
    
    .buttons {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 35px;
    }
    
    .close-button {
      position: absolute;
      top: 0px;
      right: 3px;
      padding: 7px;
    }
    .close-button svg {
      height: 14px;
      width: 14px;
    }
  `;


  static properties = {
    content: {attribute: false},
  };


  constructor() {
    super()
    this.content = html``
    this.value = 'close'
    this.closeCallback = null
  }


  render() {
    return html`
      <div class="box">
        <div class="close-button" value="close" @click=${this._buttonClick}>
          ${Assets.inlineSvg('close.svg')}
        </div>
        <slot name="message">-Message Missing-</slot>
        <div class="buttons">
          <slot name="button" @click=${this._buttonClick}>
            <mss-button slot="button" class="primary" value="OK">OK</mss-button>
          </slot>
        </div>
      </div>
    `;
  }

  _buttonClick(event) {
    let val = event.target.getAttribute('value')
    if (val) {
      this.value = val
    }
    this.close()
  }


  static open(html, closeCallback) {
    let element = document.createElement("dialog-box")
    element.content = html
    let listener = (event) => {
      element.removeEventListener('close', listener)
      document.body.removeChild(element)
    }
    element.closeCallback = closeCallback;
    element.addEventListener('close', listener)
    element.innerHTML = html
    document.body.appendChild(element)
    return element
  }

  close() {
    this.dispatchEvent(new Event('close', {bubbles: true, composed: true}))
    if (this.closeCallback) {
      this.closeCallback(this.value)
    }
  }
}
customElements.define('dialog-box', Dialog);

