

//---------------------------------------------------------------------------
// Format date and time.
//   mon day, year - HH:MM:SS am/pm
// Parameter can be a date-time string or Date object.
export function formatDateTime(date) {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (Object.prototype.toString.call(date) !== "[object Date]" || isNaN(date.getTime())) {
    return ''
  }
  return `${formatDate(date)} - ${formatTime(date)}`
}

//---------------------------------------------------------------------------
// Format date
//   mon day, year
// Parameter can be a date string or Date object.
export function formatDate(date) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (Object.prototype.toString.call(date) !== "[object Date]" || isNaN(date.getTime())) {
    return ''
  }
  return `${monthNames[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`
}

//---------------------------------------------------------------------------
// Format time.
//   HH:MM:SS am/pm
// Parameter can be a time string or Date object.
export function formatTime(date) {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date('1970-01-01T' + date)
  }
  if (Object.prototype.toString.call(date) !== "[object Date]" || isNaN(date.getTime())) {
    return ''
  }
  let hours = date.getUTCHours()
  let ampm = 'am'
  if (hours > 12) {
    ampm = 'pm'
    hours -= 12
  }
  if (hours === 0) hours = 12
  return `${hours}:${pad(date.getUTCMinutes(), 2)}:${pad(date.getUTCSeconds(), 2)} ${ampm}`
}

//---------------------------------------------------------------------------
// Format duration string
// Parameters can be date strings or Date objects
export function formatDuration(beginning, ending) {
  if (!beginning || !ending) {
    return '-'
  }
  let bTime = (new Date(beginning)).getTime()
  let eTime = (new Date(ending)).getTime()
  if (isNaN(bTime) || isNaN(eTime)) {
    return '-'
  }
  let num = Math.floor((eTime - bTime) / 1000)
  let seconds = num % 60
  num = (num - seconds) / 60
  let minutes = num % 60
  let hours  = (num - minutes) / 60
  if (hours === 0) {
    return pad(minutes) + ":" + pad(seconds)
  }
  return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds)
}

//---------------------------------------------------------------------------
// Pad beginning of string.
// If parameter is a number it will be converted to a string.
function pad(x, width=2, padString=null) {
  if (typeof x === 'number') {
    x = x.toString()
    if (padString === null) padString = '0'
  } else {
    if (padString === null) padString = ' '
  }
  if (padString.length <= 0) return x
  while (x.length < width) {
    x = padString + x
  }
  return x
}


//---------------------------------------------------------------------------
export function timeAgo(date, excludeEnding=false, includeSeconds=false) {
  let timeTable = [
    [60, 'seconds', 1],
    [60*2, 'minute', 60],
    [60*60, 'minutes', 60],
    [60*60*2, 'hour', 3600],
    [60*60*24, 'hours', 60*60],
    [60*60*24*2, 'day', 60*60*24],
    [60*60*24*7, 'days', 60*60*24],
    [60*60*24*7*2, 'week', 60*60*24*7],
    [60*60*24*7*4, 'weeks', 60*60*24*7],
    [60*60*24*7*4*2, 'month', 60*60*24*7*4],
    [60*60*24*7*4*12, 'months', 60*60*24*7*4],
    [60*60*24*7*4*12*2, 'year', 60*60*24*7*4*12],
    [60*60*24*7*4*12*1000, 'years', 60*60*24*7*4*12],
  ]

  if (!date) {
    return '-'
  }

  let msec
  switch (typeof date) {
    case 'number':
      msec = date
      break
    case 'string':
      msec = +new Date(date)
      break
    case 'object':
      if (date.constructor === Date) msec = date.getTime()
      break
    default:
      msec = +new Date()
  }

  let seconds = (+new Date() - msec) / 1000
  let token = ' ago'

  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = ' from now'
  }
  if (seconds < 60 && !includeSeconds) {
    seconds = 60
  }
  if (seconds === 0) {
    return 'just now'
  }
  let i = 0
  let format
  while (format = timeTable[i++]) {
    if (seconds < format[0]) {
      if (excludeEnding) token = ''
      return `${Math.floor(seconds / format[2])} ${format[1]}${token}`
    }
  }
  return '--'
}



