import {html, css, LitElement} from "lit";
import {graphQL} from "../../shared/graphQL";
import {templateStyles} from "./template-styles";

export class LocationSelector extends LitElement {
  static properties = {
    selectedLocation: {state: true, type: Object},
    locations: {state: true},
    searchValue: {state: true},
  };

  constructor() {
    super();
    this.pageSize = 10;
    this.currentPage = 0;
    this.searchBy = "nameLike";
    this.orderBy = "orderByName";
    this.orderType = "ASC";
    this.loading = false
    this.initial = true
  }

  _getQuerySort() {
    let sort = {};
    sort[this.orderBy] = this.orderType;
    return sort;
  }

  _getQueryPage() {
    return {
      pageSize: this.pageSize,
      page: this.currentPage,
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('open_modal', function(){
      this._openModal()
    }.bind(this));
    window.addEventListener('close_preview', function(){
      this._closePreview()
    }.bind(this));
    graphQL.ready(() => this.getData());
  }

  disconnectedCallback() {
    window.removeEventListener('open_modal', this._openModal);
    window.removeEventListener('close_preview', this._closePreview);
  }

  static styles = [
    templateStyles,
    css`

      .actions {
        margin-top: 30px;
        margin-bottom: 10px;
        justify-content: center;
        font-size: 14px;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, "sans-serif";
      }

      .actions button {
        padding: 7px;
        border-radius: 7px;
        width: 140px;
      }

      #cancel-template {
        background-color: #f5f5f5;
      }

      .wrapper {
        width: 40%;
        margin: auto;
        height: 560px;
        flex-direction: column;
      }

      .wrapper header {
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, "sans-serif";
        border-bottom: solid 1px black;
        margin-bottom: 10px;
        width: 90%;
        color: #666666;
        border-color: #979797;
        font-size: 14px;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
        padding: 20px 20px 0px 20px;
        background-color: #ffffff;
        border: 1px solid #979797;
        border-radius: 3px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        max-height: 30vh;
      }

      .search-input {
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        padding: 0;
        background-color: #ffffff;
      }

      .search-input i {
        position: absolute;
        left: 10px;
        top: 12px;
      }

      .search-input .total-count {
        position: absolute;
        right: 20px;
        top: 10px;
      }

      input[type="search"] {
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
        width: 100%;
        background: transparent no-repeat 13px center;
        margin: 0;
        padding: 10px 12px;
        padding-left: 30px;
        font-size: 16px;
        line-height: 16px;
        border: 1px solid #979797;
        border-radius: 3px;
        height: 37px;
        outline: none !important;
        color: #333333;
        transition: border-color 0.2s ease-in-out;
      }

      .total-count,
      input[type="search"]::placeholder {
        color: #c6c6c6;
      }

      input[type="search"]:focus {
        box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.5);
        border-color: #438bdf;
        outline: none;
      }

      input[type="search"] {
        &::-webkit-search-cancel-button {
          display: none;
        }
      }

      .list {
        margin-top: 10px;
        border: 1px solid #979797;
        border-bottom: 0px;
        background-color: #FAFAFA;
        padding: 15px 10px 20px 10px;
        overflow-y: scroll;
        min-height: 40px;
      }

      .list-item-container input[type="radio"] {
        display: none;
      }

      .selections {
        color: #5B5B5B;
        font-size: 16px;
        font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, "sans-serif";
        padding-top: 5px;
        margin-bottom: 10px;
      }

      .list-item-check {
        visibility: hidden;
        position: absolute;
        display: flex;
        right: 10px;
        bottom: 10px;
      }

      .list-item-container input[type="radio"]:checked + .list-item {
        color: #3771c4;
        border-color: #3771c4;

        .item-circle-icon {
          display: block
        }
      }

      .list-item-container {
        margin-bottom: 8px;
        cursor: pointer;
        text-align: center;
        border-radius: 15px;
        display: block;
        border: 2px solid;
        position: relative;
      }

      .list-item-container:has(> input[type="radio"]:checked) {
        background-color: #e0eaf9;
      }


      .list-item-container
      input[type="radio"]:checked
      + .list-item
      .list-item-check {
        visibility: visible;
      }

      .footer {
        justify-content: space-between;
        background-color: #f5f5f5;
        border: 1px solid #979797;
        border-top: 0px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 0px 20px 10px 20px;
      }

      .submit-link {
        padding: 10px 0px;
        cursor: pointer;
        font-size: 18px;
        color: #1b6bc8;
      }

      .hidden {
        display: none;
      }

      .fa {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
      }

      .item-circle-icon {
        display: none;
        position: absolute;
        right: 5px;
        bottom: 4px;
        font-size: 14px;
      }


      .fa-check-circle:before {
        content: "\\f058"
      }

      .fa-search:before {
        content: "\\f002";
      }

      .fa-search {
        position: absolute;
        left: 10px;
        top: 12px;
        color: #C6C6C6;
      }

      .list-item-container input[type="radio"]:checked {
        .item-circle-icon {
          display: block
        }
      }

    `,
  ];

  #mostRecentRequest = undefined;

  async getData(continueFetchData) {
    let query = `
            query Locations($filters: LocationFilter, $total_count_filters: LocationFilter) {
                locationsCount(filters: $total_count_filters)
                locations(filters: $filters) {
                    id
                    name
                }
            }`;

    let variables = {
      filters: {},
      total_count_filters: {},
    };

    variables.filters.sort = this._getQuerySort();
    variables.filters.pager = this._getQueryPage();
    // add account filter
    const accountId = graphQL.accountId();
    variables.filters.forAccountId = accountId;
    variables.total_count_filters.forAccountId = accountId;

    if (this.searchValue) {
      variables.filters[this.searchBy] = this.searchValue;
    }

    // Stop listening to any prvious request
    if (this.#mostRecentRequest)  {
      this.#mostRecentRequest.abort();
    }

    // Clear out any existing errors
    this.dataError = undefined;

    const request = await graphQL.asyncQuery({
      query: query,
      variables: variables,
      success: (response) => {
        if(continueFetchData){
          this.loading = false
          if(response.data){
            this.locations = [...this.locations, ...response.data.locations]
          }
        }else {
          if (response.data) {
            this.totalCount = response.data.locationsCount;
            this.locations = response.data.locations;
          }
        }
      },
      error: (message) => {
        this.locations = [];
        this.dataError = message;
        if(continueFetchData){
          this.loading = false
        }
      }
    });

    // Update the most recent request
    this.#mostRecentRequest = request.xhr;
  }

  _searchTemplate() {
    return html`
        <div style="display: flex;">
            <div class="search-input">
                <span class="fa fa-search search-icon"></span>
                <input
                        type="search"
                        placeholder="Search"
                        @change="${this._searchChange}"
                        @keyup="${this._searchChange}"
                />
                <span class="total-count">${this.totalCount} total</span>
            </div>
        </div>`;
  }

  _searchChange(event) {
    this.searchValue = event.currentTarget.value;
    this.currentPage = 0
    this.getData();
  }

  _locationListTemplate() {
    if (!this.locations) {
      return html`
          <div class="empty-table">Retrieving Data</div>`;
    } else if (this.dataError) {
      return html`
          <div class="empty-table error">Error Retrieving Data</div>`;
    }

    return html`
        <div class="list">
            ${this.locations.map((location) => this._listItemTemplate(location))}
        </div>`;
  }

  _listItemTemplate(location) {
    return html`
        <label
                class="list-item-container"
                for="${location.id}"
                @click="${() => (this.selectedLocation = location)}"
        >
            <input
                    type="radio"
                    name="location-select"
                    id="${location.id}"
            />
            <span class="list-item">
          ${location.name}
          <span class="fa fa-check-circle item-circle-icon"></span>
        </span>
        </label>
    `;
  }

  _footerTemplate() {
    return html`
        <div class="footer selected">
            <div class="selections">
                <span class="${this.selectedLocation ? "hidden" : ""}">No</span>
                <span class="${this.selectedLocation ? "" : "hidden"}">1 </span>
                <span>Location Selected</span>
            </div>
        </div>
        <div class="actions">
            <button
                    class="primary btn-sm nav-item"
                    id="save-template"
                    type="submit"
                    @click=${this._updateLocation}
                    ?disabled=${!this.selectedLocation}
            >
                Preview
            </button>
            <button
                    class="btn-sm nav-item"
                    id="cancel-template"
                    @click=${this._closeModal}
            >
                Cancel
            </button>
        </div>
    `;
  }

  updated() {
    if (this.selectedLocation) {
      let el = $(this.shadowRoot).find('input#' + this.selectedLocation.id)
      if (el) {
        el.attr('checked', true)
      }
    }
    let scroll = $(this.shadowRoot).find('.list')
    if(this.initial == true && scroll[0]){
      this.initial = false
      let thiz = this
      scroll.on('scroll', function () {
        if($(this).scrollTop() > 0){
          if ($(this).scrollTop() + 2 + $(this).innerHeight() >= $(this)[0].scrollHeight) {
            if (!thiz.loading) {
              thiz.loading = true;
              thiz.currentPage += 1
              thiz.getData(true);
            }
          }
        }
      })
    }
  }

  render() {
    return html`
        <div class="wrapper">
            <header>Locations</header>
            <div class="container">
                ${this._searchTemplate()}
                ${this._locationListTemplate()}
            </div>
            ${this._footerTemplate()}
        </div>`
  }

  _updateLocation() {
    this.dispatchEvent(
      new CustomEvent("update-location", {
        detail: {location: this.selectedLocation},
        bubbles: true,
        composed: true,
      })
    );
  }
  _openModal(){
    this.searchValue = undefined
    $(this.shadowRoot).find('.search-input input').val('')
    this.getData()
  }

  _closePreview() {
    this.currentPage = 0
    this.locations = []
    this.totalCount = []
    this.selectedLocation = undefined
    $(this.shadowRoot).find('input[type="radio"]').attr('checked', false)
  }

  _closeModal(){
    this.dispatchEvent(
      new CustomEvent("close-modal", {
        bubbles: true,
      })
    );
  }
}

customElements.define("location-selector", LocationSelector);
