
import {LitElement, html, css} from 'lit'
import {map} from 'lit/directives/map.js'
import {when} from 'lit/directives/when.js'
import {Assets} from 'shared/assets';


export class TablePagination extends LitElement {
  static styles = css`
    :host {
      display: flex;
      justify-content: space-between;
      padding: 26px 15px;
      color: #616161
    }
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .select-wapper {
      position: relative;
    }

    .select-wapper svg {
      position: absolute;
      right: 17px;
      top: 7px;
      width: 12px;
      height: 6px;
      pointer-events: none;
    }

    select {
      border: 1px solid #666666;
      border-radius: 5px;
      padding: 0 23px 0 10px;
      height: 27px;
      margin-top: -2px;
      margin-bottom: -2px;
      margin-left: 0px;
      margin-right: 4px;
      font-size: 16px;
      color: #333333;
      appearance: none;
    }

    .page-info {
      display: inline-block;
      font-size: 14px;
    }

    nav {
      display: flex;
      flex: none;
      color: var(--inactive-color);
    }
    nav span {
      height: 24px;
      width: 18px;
      padding: 0;
      margin-left: 7px;
      border: 1px solid var(--inactive-color);
      text-align: center;
      line-height: 20px;
      border-radius: 4px;
      position: relative;
    }
    nav span.active {
      border: 1px solid #666666;
      color: #303030;
    }
    nav span.current {
      color: white;
      background-color: var(--brand-primary);
      border-color: var(--brand-primary);
    }
    nav span.page {
      font-size: 14px;
      line-height: 22px;
      width: unset;
      min-width: 24px;
      height: 24px;
      padding: 0 2px;
    }
    nav span.page a {
      line-height: 22px;
    }
    nav span.active:hover {
      cursor: pointer;
      color: var(--brand-primary);
      border-color: var(--brand-primary);
    }
  `;

  static properties = {
    page: {type: Number},
    pageSize: {type: Number},
    totalCount: {type: Number},
    options: {type: Object},
  };


  constructor() {
    super()
    this.page = 1
    this.pageSize = 25
    this.totalCount = 0
    this.options = {}
    this.changeEvent = new Event('change', {bubbles: true, composed: true});
    this.totalPages = 1
  }

  render() {
    this.dataName = this.options.dataName || ''
    this.pageSizeOptions = this.options.pageSizeOptions || [10, 25, 50, 100, 200]
    this.navMax = this.options.navMax || 5
    if (this.navMax <= 0) this.navMax = 5
    if (this.totalCount == 0) return;
    let startItem = ((this.page - 1) * this.pageSize) + 1
    let endItem = startItem + this.pageSize - 1
    if (endItem > this.totalCount) endItem = this.totalCount

    this.totalPages = Math.floor((this.totalCount + (this.pageSize - 1)) / this.pageSize)

    let nav = []

    let active = this.page > 1 ? 'active' : ''
    if (this.page > 1) {
      nav.push(html`
        <span class="first active" @click=${this._first}>«</span>
        <span class="prev active" @click=${this._prev}>‹</span>
      `)
    } else {
      nav.push(html`
        <span class="first">«</span>
        <span class="prev">‹</span>
      `)
    }
    let first = this.page - Math.floor(this.navMax / 2)
    if (first + this.navMax - 1 >= this.totalPages) {
      first = this.totalPages - this.navMax + 1
    }
    if (first < 1) {
      first = 1
    }
    if (first > 1) {
      nav.push(html`<span class="page gap">…</span>`)
    }
    for (let x=0; x < this.navMax; x++) {
      if ((first + x) > this.totalPages) {
        break
      }
      if ((first + x) === this.page) {
        nav.push(html`<span class="page current" data-page="${first + x}">${first + x}</span>`)
      } else {
        nav.push(html`<span class="page active" @click=${this._page} data-page="${first + x}">${first + x}</span>`)
      }
    }
    if (first + this.navMax <= this.totalPages) {
      nav.push(html`<span class="page gap">…</span>`)
    }
    let nav4
    active = this.page < this.totalPages ? 'active' : ''
    if (this.page < this.totalPages) {
      nav.push(html`
        <span class="next active" @click=${this._next}>›</span>
        <span class="last active" @click=${this._last}>»</span>
      `)
    } else {
      nav.push(html`
        <span class="next">›</span>
        <span class="last">»</span>
      `)
    }

    return html`
      <div>
        <span>Rows:</span>
        <span class="select-wapper">
          <select name="per" @change=${this._select}>
            ${map(this.pageSizeOptions, (val) => html`
                <option ?selected=${val === this.pageSize} ${val}
                          .value="${val}">${val}</option>`)}
          </select>
          ${Assets.inlineSvg('caret-solid.svg')}
        </span>
        <div class="page-info">
          ${when(this.totalCount <= this.pageSize,
              () => html`All ${this.totalCount} ${this.dataName}`,
              () => html`${this.dataName} ${startItem}&nbsp;-&nbsp;${endItem} of ${this.totalCount}`
          )}
        </div>
      </div>
      <nav>${nav}</nav>
    `;
  }

  get value() {
    return {
      pageSize: this.pageSize,
      page: this.page,
    }
  }

  _select(event) {
    let val = event.currentTarget.value
    this.pageSize = val
    this.page = 1
    this.dispatchEvent(this.changeEvent)
  }

  _first(event) {
    this.page = 1
    this.dispatchEvent(this.changeEvent)
  }

  _prev(event) {
    if (this.page > 1) {
      this.page -= 1;
      this.dispatchEvent(this.changeEvent)
    }
  }

  _page(event) {
    let val = event.currentTarget.getAttribute('data-page')
    if (val) {
      val = parseInt(val)
      if (!isNaN(val)) {
        this.page = val
        this.dispatchEvent(this.changeEvent)
      }
    }
  }

  _next(event) {
    if (this.page < this.totalPages) {
      this.page += 1
    }
    this.dispatchEvent(this.changeEvent)
  }

  _last(event) {
    this.page = this.totalPages
    this.dispatchEvent(this.changeEvent)
  }

}
customElements.define('table-pagination', TablePagination);
