import { svg } from "lit";

export const image = svg`
<svg width="22px" height="22px" viewBox="0 0 33 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Content-Table-" transform="translate(-13.000000, -314.000000)" fill="#5B5B5B">
            <path d="M23.7142985,321.928552 C23.7142985,323.703108 22.2745646,325.142842 20.5000089,325.142842 C18.7254533,325.142842 17.2857194,323.703108 17.2857194,321.928552 C17.2857194,320.153997 18.7254533,318.714263 20.5000089,318.714263 C22.2745646,318.714263 23.7142985,320.153997 23.7142985,321.928552 Z M40.8571761,328.357131 L40.8571761,335.85714 L17.2857194,335.85714 L17.2857194,332.642851 L22.6428686,327.285702 L25.3214433,329.964276 L33.892882,321.392837 L40.8571761,328.357131 Z M42.4643208,316.571403 L15.6785746,316.571403 C15.3939761,316.571403 15.1428597,316.822519 15.1428597,317.107118 L15.1428597,337.464285 C15.1428597,337.748884 15.3939761,338 15.6785746,338 L42.4643208,338 C42.7489194,338 43.0000358,337.748884 43.0000358,337.464285 L43.0000358,317.107118 C43.0000358,316.822519 42.7489194,316.571403 42.4643208,316.571403 Z M45.1428955,317.107118 L45.1428955,337.464285 C45.1428955,338.937501 43.9375369,340.14286 42.4643208,340.14286 L15.6785746,340.14286 C14.2053586,340.14286 13,338.937501 13,337.464285 L13,317.107118 C13,315.633902 14.2053586,314.428543 15.6785746,314.428543 L42.4643208,314.428543 C43.9375369,314.428543 45.1428955,315.633902 45.1428955,317.107118 Z" id="t1-folder1-copy-3"></path>
        </g>
    </g>
</svg>
`;