
import {css, html, LitElement} from "lit";
import {Assets} from 'shared/assets';


class TableFilter extends LitElement {
  static styles = css`
    :host {
      position: relative;
    }
    
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .filter-icon svg {
      height: 14px;
      width: 14px;
    }
    
    .main-button {
      position: relative;
      height: 32px;
      width: auto;
      margin-left: 4px;
      padding-top: 7px;
      padding-bottom: 0px;
    }
    .main-button span.label {
      padding-left: 4px;
      padding-right: 4px;
    }
    .active-check {
      display: none;
      position: relative;
      top: 3px;
    }
    .active-check svg {
      height: 17px;
      width: 16px;
    }
    .main-button.active {
      padding-top: 4px;
      color: var(--brand-primary);
      border-color: var(--brand-primary);
    }
    .active .filter-icon svg g {
      fill: #438BDF
    }
    .active .active-check {
      display: inline-block;
    }

    .filter-body {
      position: absolute;
      z-index: 1000;
      left: 2px;
      top: -14px;
      background-color: #FFFFFF;
      width: 200px;
      padding: 0 13px;
      border-radius: 8px;
      border-style: none;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      transition: width .2s;
    }
    .filter-body.active .filter-heading .label {
      color: var(--brand-primary);
      border-color: var(--brand-primary);
    }

    .filter-body.expanded {
      width: 300px;
    }

    .filter-heading {
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid #e0e0e0;
      height: 48px;
      padding-top: 12px;
      font-size: 16px;
    }
    .filter-heading span.label {
      padding: 0px 4px;
    }
    
    .filter-heading span.close {
      position: absolute;
      right: 0;
    }
    
    .clear-all {
      cursor: pointer;
      height: 42px;
      font-size: 14px;
      padding: 10px 10px;
      color: #438BDF;
    }
    .clear-all:hover {
      color: #2d7edb;
    }

    .filter-buttons {
      display: flex;
      justify-content: center;
      padding: 15px 0;
      height: 65px;
      border-top: 1px solid #e0e0e0;
    }
    .filter-buttons div {
      width: 90px;
      text-align: center;
      margin: 5px;
      padding: 7px 0;
    }
    
    .filter-sections {
      max-height: 500px;
      overflow-y: auto;
    }

    .filter-section {
      border-bottom: 1px solid #e0e0e0;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .heading {
      position: relative;
      cursor: pointer;
      height: 36px;
      font-size: 16px;
      padding: 5px 10px;
    }
    .heading .mark {
      position: absolute;
      font-size: 20px;
      right: 10px;
      transform: rotate(-90deg);
    }
      
    .collapsed .heading .mark {
      transform: rotate(0deg);
    }
    .collapsed .item {
    }

    .item {
      position: relative;
      height: 36px;
      padding-left: 20px
    }
    
    .hide {
      display: none;
    }
 `;

  static properties = {
  };


  constructor() {
    super()
    this.sections = null
  }

  render() {
    return html`
      <mss-button class="main-button outline" @click=${this.showSelections}>
        <span class="filter-icon">${Assets.inlineSvg('filters/filter.svg')}</span>
        <span class="label">Filter</span>
        <span class="active-check">${Assets.inlineSvg('filters/small_blue_check.svg')}</span>
      </mss-button>
      <div class="filter-body hide">
        <div class="filter-heading" @click=${this.hideSelections}>
          <span class="filter-icon">${Assets.inlineSvg('filters/filter.svg')}</span>
          <span class="label">Filter</span>
          <span class="active-check">${Assets.inlineSvg('filters/small_blue_check.svg')}</span>
          <span class="close">${Assets.inlineSvg('filters/close.svg')}</span>
        </div>
        <div class="filter-sections" @change=${this.sectionChange}>
          <slot></slot>
        </div>
        <div class="clear-all" @click=${this.clearAll}>
          <span>Clear All</span>
        </div>
        <div class="filter-buttons">
          <mss-button @click=${this.applyClick} class="primary">
            Apply
          </mss-button>
          <mss-button @click=${this.hideSelections} class="outline">
            Cancel
          </mss-button>
        </div>
      </div>
    `;
  }

  showSelections() {
    let body = this.shadowRoot.querySelector('.filter-body')
    if (body) {
      body.classList.remove('hide')
      setTimeout(() => {
        body.classList.add('expanded')
      }, 2)
    }
  }

  hideSelections() {
    let body = this.shadowRoot.querySelector('.filter-body')
    if (body) {
      body.classList.remove('expanded')
      setTimeout(() => {
        body.classList.add('hide')
      }, 100)
    }
  }


  sectionChange(event) {
    event.stopPropagation()
    event.preventDefault()
  }


  applyClick(event) {
    this.apply()
  }


  apply() {
    let active = false
    let value = {}
    let slot = this.shadowRoot.querySelector('.filter-sections slot')
    const sections = slot.assignedNodes()
    for (const sec of sections) {
      // Make sure it is not the text node
      if (sec.tagName) {
        value[sec.tagName.toLowerCase()] = sec.value
        if (sec.value) {
          active = true
        }
      }
    }
    this.value = value

    if (active) {
      this.shadowRoot.querySelector('.main-button').classList.add('active')
      this.shadowRoot.querySelector('.filter-body').classList.add('active')
    } else {
      this.shadowRoot.querySelector('.main-button').classList.remove('active')
      this.shadowRoot.querySelector('.filter-body').classList.remove('active')
    }
    this.dispatchEvent(new Event('change', {bubbles: true, composed: true}))
    this.hideSelections()
  }

  clearAll() {
    let slot = this.shadowRoot.querySelector('.filter-sections slot')
    const sections = slot.assignedNodes()
    for (const sec of sections) {
      // Make sure it is not the text node
      if (sec.tagName) {
        sec.clear()
      }
    }
  }

  setValues(values) {
    for (const section in values) {
      let element = this.querySelector(section)
      if (element) {
        element.setValues(values[section])
      }
    }
  }
}
customElements.define('table-filter', TableFilter);

