
import {css, html, LitElement} from "lit"
import {classMap} from 'lit/directives/class-map.js'
import {Assets} from 'shared/assets';


class TableSettings extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    :host {
      display: inline-block;
      font-size: 16px;
      line-height: 16px;
      margin-left: 10px;
    }

    .open-button {
      display: inline-block;
      width: 32px;
      height: 32px;
      padding: 6px 6px;
      border-color: #5b5b5b;
    }
    .open-button svg {
      width: 18px;
      height: 18px;
    }

    .container {
      display: none;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 5500;
      background: rgba(0, 0, 0, 0.5);
      overflow: auto;
      font-size: 14px;
    }

    .settings {
      margin-top: 10vh;
      margin-left: 4vw;
      margin-right: 4vw;
      background-color: #EBEBEB;
      padding: 25px;
      border: 1px solid #DCDCDC;
      border-radius: 8px;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5);
    }
    .settings-heading {
      border-bottom: 1px solid #979797;
      margin-bottom: 20px;
    }
    .settings-body {
      background-color: #F5F5F5;
      padding: 15px;
    }
    .example-label {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 4px;
    }

    .preview {
      display: flex;
      justify-content: space-between;
      border: 1px solid #979797;
      background-color: white;
      padding: 0px 5px;
    }
    .preview .title {
      flex-grow: 1;
      text-align: center;
      padding: 11px 0px;
      border-left-width: 12px;
      border-left-color: white;
      border-left-style: solid;
      border-right-width: 12px;
      border-right-color: white;
      border-right-style: solid;
      cursor: grab;
    }
    .preview .title.always {
      color: #a7a7a7;
    }
    .title.moving {
      cursor: grabbing;
      background-color: #e8e8e8;
      color: #e8e8e8;
    }
    .preview .title:active {
      cursor: grabbing;
    }
    .preview .drag-over-right {
      border-right-color: #438BE0;
    }
    .preview .drag-over-left {
      border-left-color: #438BE0;
    }

    .heading-selection {
      font-size: 12px;
      padding: 20px 10%;
    }
    .check-boxes {
      display: flex;
      flex-wrap: wrap;
    }
    .check-boxes mss-label {
      display: inline-block;
      min-width: 200px;
      margin-right: 12px;
      height: 32px;
      cursor: pointer;
    }
    .check-boxes mss-checkbox {
      position: relative;
      display: inline-block;
      top: 4px;
      cursor: pointer;
    }

    .buttons {
      display: flex;
      justify-content: center;
      padding-top: 25px;
    }
  `;


  static properties = {
    settings: {attribute: false},
    defaultSettings: {attribute: false},
  }


  constructor() {
    super()
    this.settings = []
    this.defaultSettings = []
  }


  connectedCallback() {
    super.connectedCallback()
  }


  render() {
    if (!this._settings) {
      // Make a deep copy of the settings
      if (this.accountid && this.orgid) {
        this._settings = this.settings.filter(elem => elem.key !== 'organization' && elem.key !== 'account');
      } else if (this.accountid) {
        this._settings = this.settings.filter(elem => elem.key !== 'account');
      } else if (this.orgid) {
        this._settings = this.settings.filter(elem => elem.key !== 'organization');
      }else{
        this._settings = this.settings;
      }
    }
    let preview = []
    for (const col of this._settings) {
      if (col.key && col.title && col.visible) {
        const classes = {
          title: true,
          always: col.display && (col.display.indexOf('always') === 0)
        }
        let display = col.display || ''
        if (display.indexOf('left') < 0 && display.indexOf('right') < 0) {
          preview.push(html`
            <div
                @dragstart=${this.dragstart}
                @dragenter=${this.dragenter}
                @dragover=${this.dragover}
                @dragleave=${this.dragleave}
                @drop=${this.drop}
                @dragend=${this.dragend}
                class=${classMap(classes)}
                data-key="${col.key}"
                draggable="true">
              ${col.title}
            </div>
          `)
        }
      }
    }
    let count = 0
    let checkBoxes = []

    for (const item of this.defaultSettings) {
      let col = this.settingByKey(item.key)
      if (col && col.key && col.title &&
        !(col.display && col.display.indexOf('always') === 0))
      {
        if (this.settingsIsVisible(col.key)) {
          count += 1
        }
        checkBoxes.push(html`
          <mss-label>
            <mss-checkbox @change="${this.itemChange}" ?checked=${this.settingsIsVisible(col.key)} title="${col.title}" value="${col.key}"></mss-checkbox>
            <span class="item" data-key="${col.key}">${col.title}</span>
          </mss-label>
        `)
      }
    }
    return html`
      <mss-button @click=${this.openSettings} class="open-button">${Assets.inlineSvg('filters/settings.svg')}</mss-button>
      <div class="container">
        <div class="settings">
          <div class="settings-heading">Table Settings
          </div>
          <div class="settings-body">
            <div class="example-label">
              <div>Table Header Example</div>
              <div>Total Columns: ${count}</div>
            </div>
            <div class="preview">${preview}</div>
            <div class="heading-selection">
              <div>Select Table Columns</div>
              <div class="check-boxes">${checkBoxes}</div>
            </div>
          </div>
          <div class="buttons">
            <mss-button class="primary" @click=${this._save}>Save</mss-button>
            <mss-button class"outline" @click=${this._cancel}>Cancel</mss-button>
            <mss-button class"outline" @click=${this._default}>Default</mss-button>
          </div>
        </div>
      </div>
    `;
  }


  openSettings() {
    this.shadowRoot.querySelector('.container').style.display = 'block'
    // Make a deep copy of the settings
    this._settings = JSON.parse(JSON.stringify(this.settings))
    this.requestUpdate()
  }


  _save(event) {
    this.settings = this._settings
    this.dispatchEvent(new Event('save', {bubbles: true, composed: true}))
    this.dispatchEvent(new Event('close', {bubbles: true, composed: true}))
    this.shadowRoot.querySelector('.container').style.display = 'none'
    event.preventDefault()
  }


  _cancel(event) {
    this.dispatchEvent(new Event('close', {bubbles: true, composed: true}))
    this.shadowRoot.querySelector('.container').style.display = 'none'
    event.preventDefault()
  }


  _default(event) {
    // Make a deep copy of the default settings
    this._settings = JSON.parse(JSON.stringify(this.defaultSettings))
    this.requestUpdate()
    event.preventDefault()
  }


  dragstart(event) {
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text', event.currentTarget.getAttribute('data-key'))
    event.currentTarget.classList.add('moving')
    this.dragKey = event.currentTarget.getAttribute('data-key')

    this.dragImage = event.currentTarget.cloneNode(true)
    this.dragImage.style.position = 'fixed'
    this.dragImage.style.top = '-1000px'
    this.dragImage.style.border = '1px solid #979797'
    this.dragImage.style.backgroundColor = 'rgba(255, 255, 255, 0.6)'
    this.dragImage.style.padding = '8px 10px'
    this.dragImage.style.fontSize = '14px'
    document.body.appendChild(this.dragImage);

    let rect = this.dragImage.getBoundingClientRect();
    event.dataTransfer.setDragImage(this.dragImage, rect.width, rect.height);
  }


  dragenter(event) {
    if (!event.currentTarget.classList.contains('moving')) {
      if (this.settingsIndexOf(this.dragKey) > this.settingsIndexOf(event.currentTarget.getAttribute('data-key'))) {
        event.currentTarget.classList.add('drag-over-left')
      } else {
        event.currentTarget.classList.add('drag-over-right')
      }
    }
    event.preventDefault();
  }


  dragover(event) {
    event.preventDefault();
  }


  dragleave(event) {
    event.currentTarget.classList.remove('drag-over-left', 'drag-over-right')
    event.preventDefault();
  }


  drop(event) {
    event.currentTarget.classList.remove('drag-over-left', 'drag-over-right')
    this.moveColumn(this.dragKey, event.currentTarget.getAttribute('data-key'))
    event.preventDefault();
  }


  dragend(event) {
    event.currentTarget.classList.remove('moving')
    document.body.removeChild(this.dragImage)
    event.preventDefault();
  }


  itemChange(event) {
    let item = event.currentTarget
    if (item.checked) {
      // Make this item visible.
      const col = this.settingByKey(item.value)
      if (col) {
        col.visible = true
      }
    } else {
      // Make this item not visible.
      const col = this.settingByKey(item.value)
      if (col) {
        col.visible = false
      }
    }
    this.requestUpdate()
  }


  moveColumn(from, to) {
    let fromIndex = this.settingsIndexOf(from)
    let toIndex = this.settingsIndexOf(to)
    if (fromIndex < 0 || toIndex < 0 || fromIndex === toIndex) return
    if (fromIndex > toIndex) {
      let fromValue = this._settings[fromIndex]
      for (let i = fromIndex; i > toIndex; i--) {
        this._settings[i] = this._settings[i-1]
      }
      this._settings[toIndex] = fromValue
    } else {
      let fromValue = this._settings[fromIndex]
      for (let i = fromIndex; i < toIndex; i++) {
        this._settings[i] = this._settings[i+1]
      }
      this._settings[toIndex] = fromValue
    }
    this.requestUpdate()
  }

  settingsIncludes(key) {
    return this.settingsIndexOf(key) >= 0
  }


  settingsIsVisible(key) {
    const index = this.settingsIndexOf(key)
    if (index < 0) return false
    return this._settings[index].visible
  }


  settingByKey(key) {
    return this._settings[this.settingsIndexOf(key)]
  }

  settingsIndexOf(key) {
    if (!Array.isArray(this._settings)) return -1
    for (let index = 0; index < this._settings.length; index++) {
      let col = this._settings[index]
      if (col && (col.key === key)) {
        return index
      }
    }
    return -1
  }
}
customElements.define('table-settings', TableSettings);

