import {LitElement, html, css} from 'lit'
import {Assets} from 'shared/assets'
import {graphQL} from '../shared/graphQL'
import './players-table.component'
import './player-issue-flag.component'
import { PlayersIndex } from './players-index.component'
import {PlayerIssueFlagsInfo} from "./player-issue-flag.component";
import { TableBase } from '../shared/tables/table-base.component'

export class PlayerFolderlessIndex extends PlayersIndex {

    static styles = [
        TableBase.styles,
        PlayersIndex.styles,
    ];

    static properties = {
        player_group_id: {type: String},
        orgId: {type: String},
        data: {state: true},
        allowed: {},
        system: {}
      };

    constructor() {
        super()
        this.route = { folder: '/players?player_group_id=:id', item: '/players/:id' }
        this.player_group_id = null
        this.orgId = null
        this.data = {}
        this.table = 'players-folderless-table'
        this.system = "false"
    }

    getData() {
        let query = `
        query PlayerStats($orgId: ID) {
            playerStats(organizationId: $orgId) {
                displayConnected
                frequentReboots
                htmlInvalid
                manifestMismatch
                playersOffline
                playersOnline
                smartScanIssues
                totalPlayers
                usbStorageNotInstalled
            }
        }
        `;

        let variables = {}

        if(this.orgId) {
            variables.orgId = this.orgId
        }

        graphQL.query({
          query: query,
          variables: variables,
          success: (response) => {
              if(response.errors) {
                  console.log('Stats API Errors: ', response.errors)
                  return false
              }
            this.data = response.data.playerStats
          },
          error: (message) => {
            console.log(`Error getting player folder stats: ${message}`)
          },
        });
      }
}
customElements.define('player-folderless-index', PlayerFolderlessIndex);