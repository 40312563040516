import {LitElement, html, css} from 'lit'

export class ButtonSpinner extends LitElement {
    static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    .fa{
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
    }
    .fa-pulse{
        animation: fa-spin 1s infinite steps(8);
    }
    .fa-2x {
        font-size: 2em;
    }
    .fa-spinner:before {
        content: "\\f110";
    }
    @keyframes fa-spin {
      0% {
        transform: rotate(0deg);
      }
    
      100% {
        transform: rotate(360deg);
      }
    }
  `;

    static properties = {};

    constructor() {
        super()
    }


    render() {
        return html`
            <span class="fa fa-spinner fa-pulse fa-2x"></span>
        `;
    }
}

customElements.define('button-spinner', ButtonSpinner)