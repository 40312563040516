

export const graphQLIngestor = {

  ready(callback, params) {
    let timeoutID = null
    let listener = null
    // If GraphQL has not been configured with API token and other things
    // then wait until that has happened.
    if (!window.GraphQLIngestor) {
      listener = () => {
        if (timeoutID) {
          clearTimeout(timeoutID)
        }
        callback()
      }
      document.addEventListener('GraphQLIngestorReady', listener)

      timeoutID = setTimeout(() => {
        removeEventListener('GraphQLIngestorReady', listener)
        console.log('GraphQLIngestor is not configured')
        if (params && params.error) {
          let msg = 'GraphQLIngestor is not configured.'
          if (params.context) {
            params.error.call(params.context, msg)
          } else {
            params.error(msg)
          }
        }
      }, 10000)
    } else {
      callback()
    }
  },

  query: function(params) {
    graphQLIngestor.ready(() => window.GraphQLIngestor.query(params), params)
  },

  organizationId: function() {
    return window.GraphQLIngestor.organizationId || 0
  },

  accountId: function() {
    return window.GraphQLIngestor.accountId || 0
  },

}

