import { svg } from "lit";

export const backArrow = svg`
<svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Content-Templates---menu---back" transform="translate(-191.000000, -103.000000)" fill="#438BDF">
            <path d="M208.142878,111.857134 L208.142878,113.285707 C208.142878,114.044637 207.640645,114.714281 206.837072,114.714281 L198.97992,114.714281 L202.250013,117.995535 C202.517871,118.252231 202.674121,118.620535 202.674121,119 C202.674121,119.379465 202.517871,119.747769 202.250013,120.004465 L201.412959,120.852681 C201.156262,121.109378 200.787958,121.265628 200.408493,121.265628 C200.029029,121.265628 199.660725,121.109378 199.392867,120.852681 L192.127233,113.575886 C191.870537,113.31919 191.714287,112.950886 191.714287,112.571421 C191.714287,112.191956 191.870537,111.823652 192.127233,111.555795 L199.392867,104.301322 C199.660725,104.033464 200.029029,103.877214 200.408493,103.877214 C200.787958,103.877214 201.145101,104.033464 201.412959,104.301322 L202.250013,105.127216 C202.517871,105.395073 202.674121,105.763377 202.674121,106.142842 C202.674121,106.522307 202.517871,106.890611 202.250013,107.158468 L198.97992,110.428561 L206.837072,110.428561 C207.640645,110.428561 208.142878,111.098205 208.142878,111.857134 Z" id=""></path>
        </g>
    </g>
</svg>
`;