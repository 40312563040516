import { html, css, LitElement } from 'lit';
import { templateStyles } from './template-styles';

export class BaseForm extends LitElement {
    static properties = {
        formFields: Array,
        errors: Array,
        submitText: String,
        showCancelButton: Boolean,
        cancelText: String
    };

    constructor() {
        super();
        this.errors = [];
        this.submitText = 'Update';
        this.cancelText = 'Cancel';
    }

    static styles = [
        templateStyles, 
        css`
        .form-group {
            display: flex;
            justify-content: center;
            margin-bottom: 2em;
        }

        .form-field {
            display: flex;
            flex-direction: column;
            width: 90%;
        }

        .form-field.required {
            padding-bottom: 0;
        }
        
        .form-field.required:after {
            content: "Required";
            text-align: right;
            display: block;
            font-size: 12px;
            line-height: 19px;
            margin-bottom: -3px;
        }

        .form-field label {
            display: block;
            margin-left: 10px;
            font-size: 12px;
            font-weight: bold;
        }

        .form-field.error {
            color: red;
        }

        .form-field input {
            display: inline-block;
            border-radius: 3px;
            border: 1px solid #979797;
            color: #333333;
            background-color: #FFFFFF;
            line-height: 16px;
            padding: 10px 12px;
            cursor: text;
            font-size: 16px;
        }

        .form-field.error input {
            color: red;
            border-color: red;
        }

        .action {
            width: 50%;
        }
    `];

    actionButtons() {
        return html`
            <div class="actions">
                <div class="action">
                    <button
                    type="submit"
                    class="primary btn-lg"
                    ?disabled=${this.errors.length > 0}
                    >
                    ${this.submitText}
                    </button>
                </div>
                ${this.showCancelButton ?
                html`
                    <div class="action">
                        <button
                        type="reset"
                        @click="${this._cancel}"
                        class="btn-lg"
                        >
                        ${this.cancelText}
                        </button>
                    </div>
                    `
                : ''}
            </div>
        `;
    }

    createFormField(field) {
        return html`
            <div class="form-field ${field.required ? "required" : ""} ${this._hasError(field.id)}">
                <label for="${field.id}">${field.label}</label>
                <input 
                    type="text" 
                    name="${field.id}" 
                    id="${field.id}"
                    value="${field.value}"/>
            </div>
            `;
    }

    render() {
        return html`
            <form id="form" 
                @submit="${e => this._submit(e)}"
                @change="${e => this._formValueUpdated(e)}">
                <div class="form-group">
                    ${this.formFields.map(field => this.createFormField(field))}
                </div>
                ${this.actionButtons()}
            </form>`;
    }

    _hasError = (fieldId) =>
        (this.errors.indexOf(fieldId) >= 0 ? 'error' : '');

    _formValueUpdated(event) {
        let errorList = [...this.errors];
        if (!event.target.value) {
            errorList.push(event.target.id);
        } else {
            let indexOfError = errorList.indexOf(event.target.id);
            if (indexOfError >= 0) {
                errorList.splice(indexOfError, 1);
            }
        }
        this.errors = [...errorList];
    }

    _validateForm(form) {
        let errors = [];
        let fields = form.elements;
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            if (!field.value && field.id) { // TODO this will be a validate method
                errors.push(field.id);
            }
        }
        return errors;
    }

    getFormResponse(form) {
        let formResponse = {};

        let fields = form.elements;
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            if (field.id) {
                formResponse[field.id] = field.value;
            }
        }
        return formResponse;
    }

    _submit(event) {
        event.preventDefault();
        let form = event.target;
        this.errors = this._validateForm(form);
        if (!this.errors.length) {
            this._emitFormEvent(this.getFormResponse(form));
            form.reset();
        }
    }

    _emitFormEvent(form) {
        if (form) {
            this.dispatchEvent(new CustomEvent('submit', {
                detail: { ...form },
                bubbles: true,
                composed: true,
                cancelable: true
            }));
        }
    }

    _cancel() {
        this.errors = [];
        this.dispatchEvent(new CustomEvent('cancel', {
            bubbles: true,
            composed: true,
            cancelable: true,
        }));
    }
}

customElements.define('base-form', BaseForm);
