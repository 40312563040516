
import {LitElement, html, css} from 'lit'


class MssLabelValueBox extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    
    :host {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      margin-left: 15px;
      margin-right: 15px;
      box-shadow: var(--section-box-shadow, 0 1px 4px 0 rgba(0, 0, 0, 0.5));
      border-radius: var(--section-border-radius, 8px);
      border-style: var(--section-border-style, none);
      background-color: white;
      transition: background-color 0.2s;
      height: 40px;
      white-space: nowrap;
      overflow: hidden;
    }
    
    :host(.clickable:hover) {
      background-color: #fcfcfc;
      color: #5b5b5b;
      box-shadow: 0 1px 2px 1px rgba(0,0,0,0.50);
    }
    :host(.clickable:active) {
      transform: translateY(1px);
      box-shadow: inset 0px 0px  rgba(0,0,0,0.50);
      border-width: 1px;
      border-style: solid;
      border-color: #979797;
    }

    ::slotted(*) {
		  display: flex;
      align-items: center;
      padding-top: 0px;
      padding-left: 17px;
      padding-right: 17px;
      height: 100%;
    }
    ::slotted([slot=label]) {
      flex-grow: 1;
    }
    ::slotted([slot=value]) {
      flex-grow: 0;
      font-weight: normal;
      border-left: 2px solid #e0e0e0;
    }
  `;

  static properties = {
  };


  constructor() {
    super()
  }


  render() {
    return html`
      <slot name="label">-Label Missing-</slot>
      <slot name="value">-Value Missing-</slot>
    `;
  }
}
customElements.define('mss-label-value-box', MssLabelValueBox);

