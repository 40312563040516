
import {css, html, LitElement} from 'lit'
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component'
import {Assets} from 'shared/assets'
import {graphQL} from '../graphQL'


class TableFilterAttributes extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
      header svg {
        position: relative;
        top: 2px;
      }
    `];

  static properties = {
    data: {state: true},
    attrid: {}
  };


  constructor() {
    super()
    this.valueType = 'array'
    this.data = []
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let query = `
    query CustomAttributes($account_id:ID!){
      customAttributes(accountId:$account_id){
        name
        id
      }
    }`;

    let variables = `
    {
      "account_id": ${graphQL.accountId()}
    }`;

    // TODO: Add query for attributes
    graphQL.query({
      query: query,
      variables,
      success: (response) => {
        this.data = response.data.customAttributes
      },
      error: (message) => {
        console.log(`Error in filter while getting Groups: ${message}`)
      },
    });
  }


  render() {
    let items = []
    for (const type of this.data) {
      let isChecked = type.id === this.attrid;
      items.push(this.itemHelper(type.name, type.id, html`${type.name}`, '', isChecked));
    }

    return html`
      ${this.sectionHelper(html`
        ${Assets.inlineSvg('subnavigation/attributes.svg')}&nbsp;Attributes`, items)}
    `;
  }
}
customElements.define('table-filter-attributes', TableFilterAttributes);

