
import {LitElement, html, css} from 'lit';

// MSS Button component
// Button styles
// Primary: Used to indicate the more common choice.
//     example: <mss-button class="primary">Save</mss-button>
// Outline: Indicates a less common choice. (default)
//     example: <mss-button class="outline">Cancel</mss-button>


class MssButton extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    
    :host {
      display: inline-block;
      width: 150px;
      color: #5B5B5B;
      background-color: #FFFFFF;
      border-width: 1px;
      border-style: solid;
      border-color: #979797;
      border-radius: 8px;
      outline: none;
      padding: 8px 10px;
      margin: 0 6px;
      cursor: pointer;
      user-select: none;
      transition: background-color 0.2s, color 0.2s;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }
    
    :host(:hover) {
      background-color: #fcfcfc;
      color: #5b5b5b;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50);
    }
    
    :host(:active) {
      transform: translateY(1px);
      box-shadow: inset 0 0px 2px 0 rgba(0,0,0,0.50);
    }

    :host(.primary) {
      color: #FFFFFF;
      background-color: #438bdf;
      border-color: #438bdf;
    }
    
    :host(.primary:hover) {
      background-color: #2d7edb;
      border-color: #2d7edb;
    }

    :host([disabled]) {
      color: #fff;
      opacity: .6;
      cursor: not-allowed !important;
      pointer-events: none;
      background-color: #438BDF;
    }

    :host(.raised) {
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
    }
  `;


render() {
    return html`
        <slot></slot>
    `;
  }
}
customElements.define('mss-button', MssButton);


