
import {css, html, LitElement} from "lit"

class BaseColorPill extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    :host {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgb(140, 176, 234);
      border-radius: 10px;
      background-color: rgb(212, 228, 250);
      text-align: center;
      white-space: nowrap;
      padding: 2px 8px 2px 8px;
      margin: 3px;
    }
  `;

  render() {
    return html`
      <slot></slot>
    `;
  }
}
customElements.define('base-color-pill', BaseColorPill);