
import {css, html, LitElement} from "lit";
import {Assets} from 'shared/assets';


class TableSearch extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    :host {
      display: inline-block;
      margin-left: 10px;
    }

    .search {
      height: 32px;
      width: 32px;
      color: #5B5B5B;
      border: 1px solid #5B5B5B;
      border-radius: 8px;
      background-color: #FFFFFF;
      transition: background-color 0.2s, color 0.2s;
      padding-top: 1px;
      padding-bottom: 1px;
      font-size: 10px;
      line-height: 10px;
      overflow: hidden;
    }
    .search:hover {
      cursor: pointer;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.50);
    }
    .search:active {
      transform: translateY(1px);
      box-shadow: inset 0 0px 2px 0 rgba(0, 0, 0, 0.50);
    }

    .button {
      display: inline-block;
      height: 100%;
      width: 30px;
      overflow-x: hidden;
      padding-left: 7px;
      padding-top: 5px;
      border-width: 0px;
      border-radius: 8px;
    }
    .button:hover {
      background-color: #FCFCFC;
      color: #5b5b5b;
    }

    .search-icon svg {
      height: 18px;
      width: 17px;
    }

    .clear-icon svg {
      position: relative;
      top: -1px;
      height: 16px;
      width: 16px;
    }

    .clear {
      display: inline-block;
      float: right;
      height: 100%;
      width: 0px;
      overflow-x: hidden;
      padding-left: 0px;
      padding-top: 7px;
      border-width: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      transition: width .2s;
    }
    .clear:hover {
      background-color: #F5F5F5;
      color: #5b5b5b;
    }

    .text {
      display: inline-block;
      height: 100%;
      width: 0px;
      overflow-x: hidden;
      transition: width .2s;
    }

    input {
      display: inline-block;
      font-size: 16px;
      color: #333333;
      line-height: 16px;
      appearance: none;
      height: 100%;
      width: 100%;
      padding: 0px;
      outline: none;
      border: none;
    }

    .search.expanded button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
     }

    .search.expanded .clear {
      width: 30px;
      padding-left: 7px;
      padding-top: 7px;
    }

    .search.expanded .text {
      width: 210px;
    }
 `;


  constructor() {
    super()
    this.value = ''
  }


  render() {
    // Note: No whitespace between the <elements>. The whitespace makes subtle format changes.
    return html`
      <div class="search">
        <div class="button" @click="${this._buttonClick}">
          <span class="search-icon">${Assets.inlineSvg('filters/search.svg')}</span>
        </div>
        <div class="text">
          <input type="text" name="search_text" @change="${this._inputChange}">
        </div>
        <div class="clear" @mousedown="${this._clearClick}">
          <span class="clear-icon">${Assets.inlineSvg('filters/clear.svg')}</span>
        </div>
      </div>
    `;
  }


  _buttonClick(event) {
    let search = this.shadowRoot.querySelector('.search')
    let input = this.shadowRoot.querySelector('input')
    if (search && input) {
      if (search.classList.contains('expanded')) {
        search.classList.remove('expanded');
        search.style.width = '32px';
        this.value = ''
      } else {
        search.classList.add('expanded');
        search.style.width = '300px';
        this.value = input.value
        input.focus()
      }
    }
    event.stopPropagation()
  }


  _clearClick(event) {
    let oldValue = this.value
    let input = this.shadowRoot.querySelector('input')
    if (input) {
      this.value = ''
      input.value = this.value
      input.focus()
    }
    if (this.value !== oldValue) {
      this.dispatchEvent(new Event('change', {bubbles: true, composed: true}))
    }
    event.stopPropagation()
  }


  _inputChange(event) {
    this.value = event.currentTarget.value
    let newEvent = new Event('change', {bubbles: true, composed: true});
    this.dispatchEvent(newEvent)
    event.stopPropagation()
  }
}
customElements.define('table-search', TableSearch);
