import { LitElement, html, css } from 'lit';
import { map } from 'lit/directives/map';

class MultiSelectorChangeEvent extends CustomEvent {
    constructor(newSet, options) {
        const safeOptions = {
            detail: {
                newSet
            },
            bubbles: true,
            composed: true,
            ...options
        };
        super('change', safeOptions);
    }
}

export class MultiSelector extends LitElement {
    static properties = {
        options: {type: Array},
        value: {type: Array}
    }

    static styles = css`
        ul {
            list-style-type: none;
            user-select: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-around;
        }

        ul > li {
            transition: background-color 250ms, color 250ms;
            flex-grow: 1;
            flex-basis: 0;
            
            text-align: center;
        }

        ul > li {
            border-left: 1px solid #979797;
        }

        ul:first-child {
            border-left: 0;
        }

        ul > li.selected {
            background-color: #438BDF;
            color: white;
        }

        ul {
            background-color: #eee;
            border: 1px solid #979797;
            line-height: 40px;
        }
    `;

    constructor() {
        super();
        this.options = [];
    }

    /**
     * Checks if an option is in the current value.
     * Complex values use a string comparison.
     * Updates the value property.
     * @param {*} option    The option to search for in the value
     * @returns true if the object is selected otherwise false
     */
    #optionIsInValue(option) {
        // Handle the case where there is not yet a value
        if (!this.value) {
            // if there is no value it seems obvious the option isn't in it
            return false; 
        }

        if (typeof option === 'object') {
            // A strict deep comparision is done here by stringifying both
            // objects to be compared. In larger use cases with will be
            // prohibitive and will need to be optimized. However, this is
            // well trodden ground and if reasonable libary imports can be
            // established should not prove difficult.
            for (const e of this.value) {
                if (JSON.stringify(option) === JSON.stringify(e)) {
                    return true;
                }
            }
            return false;
        }

        return this.value.indexOf(option) !== -1;
    }

    /**
     * Adds an option to the value
     * Updates the value property
     * @param {*} option 
     */
    #addOptionToValue(option) {
        // Create a safe replacement since we have to replace the reference
        const replacementValue = this.value ? [...this.value] : [];
        
        replacementValue.push(option); // add the option
        // sort to keep consistency. This might become cumbersome if enough
        // options are added and need to be removed, but at time of writing
        // the largest option is 7 and this is probably not a huge performance
        // hit for the convience it offers.
        replacementValue.sort();

        // replace the reference
        this.value = replacementValue;
    }

    /**
     * Removes an option from the value if it is in it.
     * Updates the value property
     * @param {*} option 
     */
    #removeOptionFromValue(option) {
        const replacementValue = this.value ? [...this.value] : [];
        const optionIndex = this.value.indexOf(option);

        // If option wasn't in the value just be done
        if (optionIndex === -1) {
            return;
        }

        // remove the option
        replacementValue.splice(optionIndex, 1);

        this.value = replacementValue;
    }

    /**
     * Toggles a selection in the set and emits a change event to inform anyone
     * who might care
     * @param {*} e event that the index property of the interacted element can
     *              be derived from
     */
    #toggleInValue(e) {
        // toggle value in set
        const optionIndex = this.#getElementDatasetFromEvent(e)?.index;
        const option = this.options[optionIndex];
        if (this.#optionIsInValue(option)) {
            this.#removeOptionFromValue(option);
        } else {
            this.#addOptionToValue(option);
        }

        // dispatch the change event
        this.dispatchEvent(new MultiSelectorChangeEvent([
            ...this.value
        ]));

        // Request update so change is rendered
        this.requestUpdate();
    }

    /**
     * Helper to grab the element from an event in a cross-browser
     * compatable way
     * @param {*} e event that we want to get the dataset from
     * @returns 
     */
    #getElementDatasetFromEvent(e) {
        const element = e?.target;
        return element?.dataset;
    }

    /**
     * Helper that renders the correct li based on its type
     * @param {*} option 
     * @returns 
     */
    #renderOption(option, index) {
        // Render complex object
        if (typeof option === 'object') {
            const {value, label} = option;
            const asString = typeof option.toString === 'function' ?
                option.toString() :
                '';
            return html`
                <li
                    title="${label ?? asString}"
                    data-index=${index}
                    data-option="${value ?? asString}"
                    class="${
                        this.#optionIsInValue(option) ?
                            'selected' :
                            ''
                    }"
                    @click="${this.#toggleInValue}">
                    ${label ?? asString}
                </li>`
        }

        // Render simple object
        return html`<li
            title="${option}"
            data-index="${index}"
            data-option="${option}"
            class="${this.#optionIsInValue(option) ? 'selected' : ''}"
            @click="${this.#toggleInValue}">
                ${option}
            </li>`;
    }

    // Lifecycle Hooks
    render() {
        return html`<ul>
            ${map(
                this.options,
                (option, index) => this.#renderOption(option, index)
            )}
        </ul>`;
    }
}

customElements.define('multi-selector', MultiSelector);
