
import {css, html, LitElement} from 'lit'
import {TableFilterSectionBase} from 'shared/tables/table-filter-section-base.component'


class TableFilterPlayerOnOffLine extends TableFilterSectionBase {
  static styles = [
    TableFilterSectionBase.styles,
    css`
      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
      }
      .dot.offline {
        background-color: var(--danger-color);
      }
      .dot.online {
        background-color: var(--success-color);
      }
    `];

  static properties = {
    data: {state: true},
  };


  constructor() {
    super()
    this.data = []
  }


  render() {
    let items = []
    items.push(this.itemHelper('offline', 'true', html`
      <span class="dot offline"></span>
      <span>Offline</span>
    `))
    items.push(this.itemHelper('online', 'true', html`
      <span class="dot online"></span>
      <span>Online</span>
    `))

    return html`
      ${this.sectionHelper(html`Status`, items)}
    `;
  }
}
customElements.define('table-filter-player-on-off-line', TableFilterPlayerOnOffLine);

