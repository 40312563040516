import { LitElement, html, css } from 'lit';
import { convertConfigToEnglish } from '../cron-parser';

export class RecurringScheduleSentence extends LitElement {
    static styles = css`
    :host {
        display: inline;
    }
    `;
    static get properties() {
        return {
          // Hides the allow until clause if repeat is enabled
          repeat: { type: Object },
          // adds
          time: { type: String },
          // Hides the allow until clause if repeat is enabled
          timezone: { type: String }
        };
    };

    createRenderRoot() {
        return this;
    }

    render() {
        const repeat = this.repeat ?? {type: 'never'};
        let timestring = convertConfigToEnglish(repeat);

        if (repeat.type === 'never') {
            return html`<span>${timestring}</span>`;
        }

        if (this.time) {
            timestring += ` at ${this.time}`;
        }

        if (this.timezone) {
            timestring += ` ${this.timezone}`;
        }


        return html`<span>${timestring}</span>`;
    }
}

customElements.define('recurring-schedule-sentence', RecurringScheduleSentence);
