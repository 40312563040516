
import {LitElement, html, css} from 'lit'


class MssCheckbox extends LitElement {
  static styles = css`
		*, *:before, *:after {
		  box-sizing: border-box;
		}
    :host {
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      color: #5B5B5B;
      background-color: rgba(0,0,0,0.0);
      border-width: 2px;
      border-style: solid;
      border-color: #438BDF;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      user-select: none;
      transition: background-color 0.2s, color 0.2s;
      text-align: center;
    }
    
    :host(:hover) {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    }
    
    :host(:active) {
      transform: translateY(1px);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    }
    
    :host([disabled]) {
      transform: translateY(0px);
      box-shadow: unset;
      cursor: default;
    }
    
    :host([checked]) {
      background-color: #438BDF;
      border-color: #033F86;
    }
        
    .check {
      font-size: 13px;
      line-height: 14px;
      position: absolute;
      top: 1px;
      left: 3px;
      color: rgb(255, 255, 255);
    }
  `;


  static properties = {
    checked: {type: Boolean, reflect: true},
    disabled: {type: Boolean},
    name: {type: String},
    value: {type: String},
  };


  constructor() {
    super()
    this.checked = false
    this.disabled = false
    this.name = ''
    this.value = ''
  }


  render() {
    if (this.checked) {
      return html`<span class="check">✔</span>`;
    } else {
      return html``;
    }
  }


  connectedCallback() {
    super.connectedCallback()
    this._clickListener = (event) => {
      this._click(event)
    }
    this.addEventListener('click', this._clickListener)
  }


  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener('click', this._clickListener)
    this._resizeListener = null
  }


  _click(event) {
    if (!this.disabled) {
      this.checked = !this.checked
      this.dispatchEvent(new Event('change', {bubbles: true, composed: true}))
    }
    event.stopPropagation()
    event.preventDefault()
  }
}
customElements.define('mss-checkbox', MssCheckbox);
