

export const graphQL = {

  ready(callback, params) {
    let timeoutID = null
    let listener = null
    // If GraphQL has not been configured with API token and other things
    // then wait until that has happened.
    if (!window.GraphQL) {
      listener = () => {
        if (timeoutID) {
          clearTimeout(timeoutID)
        }
        callback()
      }
      document.addEventListener('GraphQLReady', listener)

      timeoutID = setTimeout(() => {
        removeEventListener('GraphQLReady', listener)
        console.log('GraphQL is not configured')
        if (params && params.error) {
          let msg = 'GraphQL is not configured.'
          if (params.context) {
            params.error.call(params.context, msg)
          } else {
            params.error(msg)
          }
        }
      }, 10000)
    } else {
      callback()
    }
  },

  query: function(params) {
    graphQL.ready(() => window.GraphQL.query(params), params)
  },

  /**
   * Provides an asynchrnous version of the function with a handle to the actual
   * request
   * @param {*} params 
   * @returns an object with a handle to the actual request
   */
  asyncQuery: async function(params) {
    await new Promise((res, _rej) => graphQL.ready(() => res(), params));
    return {xhr: window.GraphQL.query(params)};
  },

  organizationId: function() {
    return window.GraphQL.organizationId || 0
  },

  accountId: function() {
    return window.GraphQL.accountId || 0
  },
}

