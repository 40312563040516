import { LitElement, html, css } from 'lit';
import { trash } from '../icons/delete';
import { map } from 'lit/directives/map';
import { choose } from 'lit/directives/choose';
import { capitalize } from './capitalize';
import './standard-repeating-options.component';
import './multi-selector.component';
import './pikaday.component';
import { convertConfigToEnglish, ordinalSuffix } from '../cron-parser';

/**
 * Custom change event. Not 100% necessary but makes debugging a lot easier
 * when the event class is named.
 */
class RecurringScheduleChange extends CustomEvent {
  constructor(type, detailsExt) {
    super('change', {
      detail: {
        type,
        isValid: false,
        ...detailsExt
      },
      bubbles: true,
      composed: true
    });
  }
}

export class RecurringSchedule extends LitElement {
  /**
   * styles specific to this component
   */
  static styles = css`
    .recurring-schedule-container {
      border: 1px solid rgb(151, 151, 151);
      border-color: var(--recurring-schedule-container-border-color, rgb(151, 151, 151));
      background: white;
      border-radius: 3px;
      line-height: 22px;
      font-size: 12px;
      width: 100%;
    }

    .invalid{
      color: #E4B079;
      text-align: center
    }

    ul.repeat-days,
    ul.repetition-types {
      list-style-type: none;
      user-select: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
    }

    ul.repeat-days > li,
    ul.repetition-types > li {
      transition: background-color 250ms, color 250ms;
      flex-grow: 1;
      padding: 3px;
      text-align: center;
    }

    ul.repetition-types > li.selected {
      background-color: #438BDF;
      color: white;
    }

    ul.repeat-days {
      background-color: #eee;
      border: 1px solid #979797;
      line-height: 40px;
    }

    ul.repeat-days > li {
      border-left: 1px solid #979797;
    }

    ul.repeat-days:first-child {
      border-left: 0;
    }

    .type-editor,
    .type-repeat-status-check {
      border-top: 1px solid rgb(151, 151, 151);
    }

    .type-editor {
      padding: 20px 30px 10px 30px;
    }

    .type-editor.never {
      border: 0;
      padding: 0;
    }

    .type-editor.weekly {
      padding-top: 30px;
    }

    .type-repeat-status-check {
      line-height: 32px;
      text-align: center;
      max-width: min-content;
      min-width: 100%;
    }

    .monthly-repeat-by {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    ul.day-of-month-selector,
    ul.day-of-week-of-month-selector {
      grid-gap: 1px;
      background-color: #979797;
      border: 1px solid #979797;
      line-height: 30px;
      padding: 0;
      justify-items: stretch;
      justify-content: center;
      align-items: stretch;
    }

    ul.day-of-month-selector > li,
    ul.day-of-week-of-month-selector > li {
      padding: 0;
      background-color: #eee;
      list-style-type: none;
      text-align: center;
    }

    ul.day-of-month-selector > li:last-child {
      grid-column: span 4;
    }

    ul.day-of-month-selector {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    ul.day-of-week-of-month-selector {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    ul.day-of-week-of-month-selector.hide-last {
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    }

    ul.day-of-month-selector  > li.selected,
    ul.day-of-week-of-month-selector > li.selected {
      background-color: #438BDF;
      color: white;
    }

    .adding-custom-date {
      display: flex;
      justify-content: space-between;
    }

    .adding-custom-date > pikaday-date {
      flex-grow: 1;
    }

    .adding-custom-date > button {
      margin-left: 10px;
    }

    ul.custom-dates {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 10px;
    }

    ul.custom-dates > li {
      display: flex;
      justify-content: space-between;
      border: 1px solid #979797;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 5px 10px;
      background-color: #f5f5f5;
    }

    .individual-custom-date {
      font-size: 16px;
    }

    .add-custom-date-button {
      color: #fff;
      border: 0;
      background-color: #438BDF;
      border-radius: 8px;
      padding: 10px 15px;
    }

    .add-custom-date-button:hover,
    .add-custom-date-button:focus {
      background-color: #438BDF;
    }

    .weekday-selection {
      margin-bottom: 20px;
    }

    .individual-custom-date {
      align-items: center;
    }

    .trash {
      display: flex;
    }

    .monthly-types {
      margin-bottom: 20px;
    }

    .invalid-feedback{
      position: relative;
      display: inline-block;
      font-size: 12px;
      color: #D75762;
      margin-bottom: -20px;
    }
      
    .recurring-schedule-container.has-error {
      border-color: #D75762;
    }

    `;

  /**
   * Getter for the lit specific properties.
   */
  static get properties() {
    return {
      value: { type: Object },

      /**
       * Switches to hide functionality
       */
      // Hides the allow until clause if repeat is enabled
      allowUntil: { type: Boolean },
      // Hides repeat options for weekly
      hideWeeklyRepeat: { type: Boolean },
      // Hides repeat options for monthly
      hideMonthlyRepeat: { type: Boolean },
      // Hide last options
      hideLast: { type: Boolean },
      // Show custom option
      showCustom: { type: Boolean },
      isValid: { type: Boolean },
    }
  };

  /**
   * Types of repeating options for use in various template places
   */
  types = new Set(['never', 'daily', 'weekly', 'monthly', 'yearly', 'custom']);

  /**
   * internal member to manage the view if view value is not set.
   * should only be interacted with via getter and setter even internally
   */
  #view;

  /**
   * Setter for view
   */
  set view(newView) {
    // Make sure view can be one of the types
    if (!this.types.has(newView)) {
      throw new Error(`Tried to set recurr type to unknown type ${newView}`);
    }

    /**
     * If the component has a value update it
     */
    if (this.value?.type) {
      this.value = newView;
    }

    // Update the internal value no matter what
    this.#view = newView;

    // If reset the repeat type
    this.repeatType = 'forever';

    // Emit a change
    this.#updateAndEmit();
  }

  /**
   * getter for internal view
   */
  get view() {
    return this.value?.type ?? this.#view ?? 'never';
  }

  get #repeatOptions() {
    return this.renderRoot.querySelector('standard-repeating-options');
  }

  // WEEKDAY specific properties
  /**
   * mapping for the weekdays specifically
   */
  #weekdays = [
    {
      label: 'S',
      en: 'Sunday',
      value: '0'
    }, {
      label: 'M',
      en: 'Monday',
      value: '1'
    }, {
      label: 'T',
      en: 'Tuesday',
      value: '2',
    }, {
      label: 'W',
      en: 'Wednesday',
      value: '3'
    }, {
      label: 'T',
      en: 'Thursday',
      value: '4'
    }, {
      label: 'F',
      en: 'Friday',
      value: '5'
    },
    {
      label: 'S',
      en: 'Saturday',
      value: '6'
    }
  ];

  // Month specific properties
  #monthRepeatBy = 'daysOfMonth';
  #daysOfMonth = [...Array(31).keys()].map(x => x + 1);
  /**
   * a helpful array of arrays that has all the weekdays in moth pre-prepairs
   */
  #weekdaysForMonth = [...Array(6)].map((_, i) => this.#weekdays.map(day => ({
    ...day,
    /**
     * The week value as per cron it is not zero indexed
     * but days are ¯\_(ツ)_/¯
     */
    week: i + 1,
    /**
     * Getter for the pair. Ideally this is the only place this happens so that
     * the annoying logic of doing it doesn't propigate.
     */
    get pair() {
      return `${this.value}#${this.week}`;
    }
  })))
  #selectedMonthDays = new Set();
  #selectedMonthWeekdays = new Set();

  // Custom specific properties
  #dateToAdd = undefined;
  #customDates = [];

  constructor() {
    super();
    this.isValid = true;
    this.errorMessage = ''
    this.view = 'never';
    this.#daysOfMonth.push('Last Day')
  }

  arrayIsEmpty(arr) {
    return !arr || arr.length === 0;
  }

  validateRecur() {
    const value = this.value;
    const container = $(this.shadowRoot).find('.recurring-schedule-container')[0];

    // General validation function for different types and conditions
    const validateSelection = (type, repeatBy, days, errorMessage) => {
      if (value.type === type && (!repeatBy || value.repeatBy === repeatBy)) {
        if (this.arrayIsEmpty(days)) {
          this.errorMessage = errorMessage;
          this.isValid = false;
          $(container).addClass('has-error');
          return false;
        }
      }
      return true;
    };

    // Validate different types of recurrence
    if (!validateSelection('weekly', null, value.days, 'One or more days must be selected')) return;
    if (!validateSelection('monthly', 'daysOfMonth', value.daysOfMonth, 'One or more days of the month must be selected')) return;
    if (!validateSelection('monthly', 'daysOfWeek', value.daysOfWeek, 'One or more days of the week must be selected')) return;

    // If all validations pass
    $(container).removeClass('has-error');
    this.isValid = true;
  }

  /**
   * Helper to safely get the element from an event, cross-browser.
   * @param {*} e event that we want to get the dataset from
   * @returns The dataset from the triggering event.
   */
  #getElementFromEvent(e) {
    return e?.target;
  }

  /**
   * Helper to safely get the dataset from an event, cross-browser.
   * @param {*} e event that we want to get the dataset from
   * @returns The dataset from the triggering event.
   */
  #getElementDatasetFromEvent(e) {
    return this.#getElementFromEvent(e)?.dataset;
  }

  #defaultValueForType(viewType) {
    const updatedValue = {
      type: viewType
    };

    // Set every where applicable
    if (
      updatedValue.type === 'daily' ||
      (updatedValue.type === 'weekly' && !this.hideWeeklyRepeat) ||
      (updatedValue.type === 'monthly' && !this.hideMonthlylyRepeat) ||
      updatedValue.type === 'yearly'
    ) {
      updatedValue.every = 1;
    }

    // Set days for weekly
    if (updatedValue.type === 'weekly') {
      updatedValue.days = [];
    }

    // Set days for weekly
    if (updatedValue.type === 'monthly') {
      updatedValue.repeatBy = 'daysOfMonth';
      updatedValue.daysOfMonth = [];
    }

    if (updatedValue.type === 'custom') {
      updatedValue.dates = [];
    }

    return updatedValue;
  }

  /**
   * Handler for a click to change the view
   * @param {*} e the click event
   */
  #switchTypeClick(e) {
    const element = e?.target;
    const viewType = element?.dataset?.viewType;

    // if we are already on the right type do nothing
    if (viewType === this.view) {
      return;
    }

    if (viewType) {
      this.value = this.#defaultValueForType(viewType);
      // determine initial validility based on type
      const isValid = viewType === 'never' || viewType === 'daily' || viewType === 'yearly';
      this.isValid = isValid
      this.dispatchEvent(new RecurringScheduleChange(this.view, {
        isValid,
        type: this.value.type,
        value: this.value,
        reason: 'Type switch'
      }));
      this.requestUpdate();
      return;
    }
    throw new Error('Could not determine viewType to switch to.');
  }

  /**
   * Switches the element the monthly repeat by is set to on user interaction
   * @param {*} e
   */
  #setMonthlyRepeatBy(e) {
    const el = this.#getElementFromEvent(e);
    this.#monthRepeatBy = el.value;

    // Clear sets
    this.#selectedMonthDays.clear();
    this.#selectedMonthWeekdays.clear();

    // Update value
    const updatedValue = {
      type: 'monthly',
      repeatBy: this.#monthRepeatBy,
      every: 1
    };

    // Create a new approcriate to the type
    updatedValue[this.#monthRepeatBy] = [];
    this.value = updatedValue;
    this.isValid = false

    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      isValid: false,
      type: this.value.type,
      value: this.value,
      reason: 'Subtype switch'
    }));

    this.requestUpdate();
  }

  /**
   * Extracts the repeat options from the DOM
   * @returns an object with the following properties:
   *            - type: either forever, ntimes, or enddate
   *            - repeatTimes?: included if type is ntimes, the number of times
   *                            it is expected to repeat.
   *            - repeatDate?:  included if type is enddate, the date to stop
   *                            repeating
   *
   */
  #extractRepeatOptions() {
    // intentially return nothing if we have hidden the element
    if (
      (this.view === 'weekly' && this.hideWeeklyRepeat) ||
      (this.view === 'monthly' && this.hideMonthlyRepeat)
    ) {
      return;
    }


    const el = this.#repeatOptions;

    if (!el) {
      throw new Error(
        'Tried to extract repeat options, element could not be found'
      );
    }

    const options = {
      type: el.type,
      isValid: el.isValid
    };

    if (this.allowUntil && el.type === 'ntimes') {
      options.repeatTimes = el.repeatTimes;
    }

    if (this.allowUntil && el.type === 'enddate') {
      options.repeatDate = el.repeatDate;
    }

    return options;
  }

  /**
   * A generalized helper for sets that add an item if it isn't in a set and
   * removes it if it is, by side effect.
   * @param {*} set   the set to mutate
   * @param {*} item  the item to add or remove
   * @returns undefined
   */
  #toggleItemInSet(set, item) {
    if (set.has(item)) {
      set.delete(item);
      return;
    }

    set.add(item);
  }

  /** MONTH SPECIFIC **/
  /**
   * handler for adding a day of the month to the associated set
   * @param {*} e
   */
  #addDayOfMonth(e) {
    const data = this.#getElementDatasetFromEvent(e);
    const day = data?.day === 'Last Day' ? 'Last Day' : parseInt(data?.day, 10);
    this.#toggleItemInSet(this.#selectedMonthDays, day);
    const repeat = this.#extractRepeatOptions();

    const daysOfMonth = [...this.#selectedMonthDays].sort(
      // Sort in ascending order with "Last Day" always being last
      (a, b) =>
        typeof a === 'number' && typeof b === 'number' ? a - b :
          typeof b !== 'number' ? -1 :
            typeof a !== 'number' ? 1 :
              0
    );

    this.value = {
      ...this.value,
      daysOfMonth
    };

    this.isValid = daysOfMonth.length > 0

    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      isValid: this.#selectedMonthDays.size > 0 && repeat?.isValid,
      daysOfMonth,
      repeat
    }));
  }

  /**
   * handler for adding a day of the week in a month to the associated set
   * @param {*} e
   */
  #addDayOfWeek(e) {
    const { pair } = this.#getElementDatasetFromEvent(e);
    const currentIndex = this.value.daysOfWeek.indexOf(pair);

    if (currentIndex === -1) {
      this.value = {
        ...this.value,
        daysOfWeek: [...this.value.daysOfWeek, pair].sort()
      };
    } else {
      const daysOfWeek = [...this.value.daysOfWeek];
      daysOfWeek.splice(currentIndex, 1);
      this.value = {
        ...this.value,
        daysOfWeek
      };
    }

    this.#selectedMonthWeekdays = new Set(this.value.daysOfWeek);
    this.isValid = this.#selectedMonthWeekdays.size > 0

    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      isValid: this.#selectedMonthWeekdays.size > 0,
      daysOfWeek: this.value.daysOfWeek,
    }));
  }

  /** CUSTOM SPECIFIC **/
  #addCustomDate() {
    if (!this.#dateToAdd) {
      return;
    }

    this.#customDates.push(this.#dateToAdd);
    this.#dateToAdd = undefined;
    const datePicker = this.renderRoot.querySelector('pikaday-date');
    datePicker.clear();

    this.value = {
      ...this.value,
      dates: [...this.#customDates]
    };
    this.isValid = this.#customDates?.length > 0

    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      isValid: this.#customDates?.length > 0,
      dates: [...this.#customDates]
    }));
  }

  #updateStagedDate(e) {
    e.stopPropagation();
    this.#dateToAdd = e?.detail?.formattedDatestring;
  }

  #removeDateAtIndex(e) {
    const index = parseInt(this.#getElementDatasetFromEvent(e)?.index, 10);
    const newDates = [...this.#customDates];
    newDates.splice(index, 1);
    this.#customDates = newDates;

    // reflect changes to value
    if (this.value) {
      this.value.dates = newDates;
    }
    this.isValid = this.#customDates?.length > 0

    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      isValid: this.#customDates?.length > 0,
      dates: [...this.#customDates]
    }));

    this.requestUpdate();
  }

  /**
   * A helper that creates details from repeat options.
   * This method should be sufficent to create a details object for event where
   * there isn't further checking need for validity or need for extra
   * properties.
   */
  #createDetails() {
    const repeat = this.#extractRepeatOptions();
    const details = {
      isValid: (repeat ? repeat?.isValid : true)
    };

    const every = this.#repeatOptions?.every;
    if (every) {
      details.every = every;
    }

    if (this.allowUntil && repeat) {
      details.repeat = repeat;
    }

    return details;
  }

  /**
   * gathers the data from component state to make a change object
   * @returns void
   */
  async #updateAndEmit() {
    await this.updateComplete; // wait for the current cycle to complete

    if (this.view === 'monthly' || this.view === 'custom') {
      // monthly & custom intentionally skipped since they uses a more correct
      // event based flow
      return;
    }

    // Redirect to the correct subhandler
    if (this.view === 'never') {
      this.isValid = true
      this.errorMessage = ''
      this.dispatchEvent(new RecurringScheduleChange(
        this.view,
        // Since never has no additional properties it is alway valid.
        { isValid: true }
      ));
      return;
    }

    // daily and yearly emit just repeating
    if (this.view === 'daily' || this.view === 'yearly') {
      const details = this.#createDetails() ?? {};
      this.value = {
        ...this.value,
        every: details.every
      };

      this.dispatchEvent(new RecurringScheduleChange(
        this.view,
        {
          ...details
        }
      ));
      return;
    }

    if (this.view === 'weekly') {
      const details = this.#createDetails() ?? {};
      details.weekdays = this.renderRoot.querySelector('multi-selector')?.value;
      this.dispatchEvent(new RecurringScheduleChange(this.view, details));
      return;
    }

    // emit an error if we tried to switch to an unknown type
    throw new Error(`Tried to emit change for unknown type ${this.view}`);
  }

  #handleWeekdaysChange(e) {
    e.stopPropagation();

    // update value
    this.value = {
      ...this.value,
      days: [...e.detail.newSet.map(x => x.value)]
    };

    this.isValid = this.value.days.length > 0

    // dispatch change
    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      ...this.value
    }));
  }

  /**
   * function to handle any repeat change.
   * TODO: this function (and down stream functions) could be improved by
   *       getting the data needed from the trigger event rather than querying
   *       the DOM for data.
   * @param {*} e
   */
  #handleRepeatChange(e) {
    e.stopPropagation();

    // shallow copy value;
    const replacementValue = {
      ...this.value
    };

    if (this.view === 'weekly' && !this.hideWeeklyRepeat) {
      replacementValue.every = e?.detail?.every;
    } else if (this.view === 'monthly' && !this.hideMonthlyRepeat) {
      replacementValue.every = e?.detail?.every;
    } else if (this.view === 'daily' || this.view === 'yearly') {
      replacementValue.every = e?.detail?.every;
    }

    this.value = replacementValue;

    this.dispatchEvent(new RecurringScheduleChange(this.view, {
      ...this.value
    }));
  }

  /**
   * A helper to render the highly redundant repeat options
   * @param {*} label The label to bind to the singular context
   */
  #renderRepeatOptions(label) {
    return html`
      <standard-repeating-options
        singularContext="${label}"
        .every="${this.value?.every}"
        .type="${this.repeatType}"
        .allowUntil="${this.allowUntil}"
        @change="${this.#handleRepeatChange}">
      </standard-repeating-options>
    `
  }

  /**
   * A helper to render monthly since the nesting gets a bit intense
   */
  #renderMonthlySubPicker() {
    return html`
      <div class="monthly-types">
        <div class="monthly-repeat-by">
            Repeat by...
            <label>
              <input
                name="until"
                type="radio"
                value="daysOfMonth"
                @change="${this.#setMonthlyRepeatBy}"
                .checked="${this.#monthRepeatBy === 'daysOfMonth'}"
              />
              Day of the Month
            </label>
            <label>
              <input
                name="until"
                type="radio"
                value="daysOfWeek"
                @change="${this.#setMonthlyRepeatBy}"
                .checked="${this.#monthRepeatBy === 'daysOfWeek'}"
              />
              Day of the Week
            </label>
        </div>
      </div>
      <div>
      ${choose(this.#monthRepeatBy, [
      ['daysOfMonth', () => html`
            <ul class="day-of-month-selector">${map(this.#daysOfMonth, (e, i) => {
        const isLast = i === this.#daysOfMonth?.length - 1;

        // handle disabled last day
        if (this.hideLast && isLast) {
          return html`<li></li>`
        }

        return html`
                <li
                  data-day="${e}"
                  @click="${this.#addDayOfMonth}"
                  class="${this.#selectedMonthDays.has(e) ? 'selected' : ''}"
                >
                  ${e}
                </li>`;
      })
        }</ul>`
      ],
      ['daysOfWeek', () => html`
            <ul class="day-of-week-of-month-selector${this.hideLast ? ' hide-last' : ''}">${map(this.#weekdaysForMonth, (weekdays, i) => {
        const isLast = i === this.#weekdaysForMonth.length - 1;
        if (isLast && this.hideLast) {
          return html``;
        }

        // If it is the last week give it a different label
        const weekLabel = isLast ?
          'Last' :
          `${i + 1}${ordinalSuffix(i + 1)}`;
        return html`
                  <li class="week-of-month-label">${weekLabel}</li>${
          // iterate over weekdays
          map(weekdays, (x) => html`<li
                        class="weekday-option ${this.#selectedMonthWeekdays.has(x.pair) ?
              'selected' :
              ''
            }"
                        data-pair="${x.pair}"
                        @click="${this.#addDayOfWeek}">
                          ${x.label}`)}
                  </li>`;
      })
        }</ul>`
      ],
    ]) // end of choose
      }`;
  }

  // Lifecycle Hooks
  requestUpdate(name, oldValue) {
    if (name === 'value') {
      // update the days of month set if applicable
      if (
        this.value?.type === 'monthly' &&
        this.value?.repeatBy === 'daysOfMonth'
      ) {
        this.#monthRepeatBy = 'daysOfMonth';
        this.#selectedMonthDays = new Set(this.value?.daysOfMonth);
      }

      if (
        this.value?.type === 'monthly' &&
        this.value?.repeatBy === 'daysOfWeek'
      ) {
        this.#monthRepeatBy = 'daysOfWeek';
        this.#selectedMonthWeekdays = new Set(this.value?.daysOfWeek);
      }

      if (this.value?.type === 'custom') {
        this.#customDates = this.value?.dates ? [...this.value?.dates] : [];
      }
    }

    // delete or initalize every if aplicable
    if (this.value?.type === 'monthly' && name === 'hideMonthlyRepeat') {
      if (this.hideMonthlyRepeat) {
        delete this.value.every;
      }
      // If monthly repeat is value and there is a value remove the every value
      else if (this.value) {
        this.value.every = this.value.every ?? 1;
      }
    }

    if (this.value?.type === 'weekly' && name === 'hideWeeklyRepeat') {
      if (this.hideWeeklyRepeat) {
        delete this.value.every;
      }
      // If monthly repeat is value and there is a value remove the every value
      else if (this.value) {
        this.value.every = this.value.every ?? 1;
      }
    }

    return super.requestUpdate(name, oldValue);
  }

  isValidSchedule() {
    return true;
  }

  /**
   * lit function to render a template.
   * @returns lit template
   */
  render() {
    this.validateRecur()
    return html`<div class="recurring-schedule-container">
      <ul class="repetition-types">${
      // render out each repition type
      map(this.types, t => t === 'custom' && !this.showCustom ? html`` : html`
          <li
            data-view-type="${t}"
            @click="${this.#switchTypeClick}"
            class="${t === this.view ? 'selected' : ''}"
          >
            ${capitalize(t)}
          </li>`
      )
      }</ul>

      <div class="type-editor${this.view ? ` ${this.view}` : ''}">${choose(this.view,
        [ // Switch on the view type to detmine which body to show.
          // Never needs no body because there are no associated options
          ['never', () => html``],

          // Daily Body
          ['daily', () => this.#renderRepeatOptions('day')],

          // Weekly Body
          ['weekly', () => html`
            <div class="weekday-selection">
              <multi-selector
                .value="${this.value?.days?.map?.(x => this.#weekdays[x])}"
                .options="${this.#weekdays}"
                @change="${this.#handleWeekdaysChange}">
              </multi-selector>
            </div>

            ${this.hideWeeklyRepeat ? html`` : this.#renderRepeatOptions('week')}
          `],

          // Monthly Body
          ['monthly', () => html`
            ${this.#renderMonthlySubPicker()}
            ${this.hideMonthlyRepeat ? html`` : this.#renderRepeatOptions('month')}
          `],

          // Yearly Body
          ['yearly', () => this.#renderRepeatOptions('year')],

          // Custom Body
          ['custom', () => html`
            <div class="adding-custom-date">
              <pikaday-date
                @change="${this.#updateStagedDate}">
              </pikaday-date>
              <button
                class="add-custom-date-button"
                type="button"
                @click="${this.#addCustomDate}">
                  Add
              </button>
            </div>
            <ul class="custom-dates">
                ${map(this.#customDates, (date, i) => html`<li>
                  <span
                    class="individual-custom-date">
                    ${date}
                  </span>
                  <div
                    class="trash"
                    data-index="${i}"
                    @click="${this.#removeDateAtIndex}">
                    ${trash}
                  </div>
                </li>`)}
            </ul>
          `],
        ],
        // Default view just is case of unknown repeat type
        () => html`unknown view type`
      )}</div>
        <div class="type-repeat-status-check">
          ${this.value?.type ? convertConfigToEnglish(this.value) : '---'
      }
        </div>
    </div>
    ${this.isValid == true ?
        html`<div</div>`
        :
        html`<div class="invalid-feedback">
         ${this.errorMessage}
        </div>`
      }
    `;
  }
}

customElements.define('recurring-schedule', RecurringSchedule);
