import {html, css, LitElement} from 'lit'
import {graphQLIngestor} from "shared/graphQLIngestor"
import {graphQL} from "shared/graphQL"

require("integrations/integration-card.component")
require("integrations/integration-helper")
require("shared/button-spinner.component")

class integrationEdit extends LitElement {
    static properties = {
        accId: 0,
        timeZones: [],
        type: "",
        integration: {state: true},
        integrationType: {state: true},
        ingestableFields: {state: true}
    };
    static styles = [
        css`
        #integration_form * {
            box-sizing: border-box;
        }

        #integration_form .hidden {
            display: none;
        }

        #integration_form form .grid {
            height: auto;
            display: grid;
            margin-left: auto;
            width: 1000px;
            margin-right: auto;
            grid-template-columns: repeat(2, 50%);
            grid-template-rows: auto;
            grid-template-areas: 'details times settings''details times tags''divider divider divider';
            margin-bottom: 40px;
        }

        #integration_form form .grid header {
            background-color: transparent;
            border: none;
            color: #666666;
            border-bottom: 1px solid #979797;
            border-radius: 0;
            margin: 0px 0 20px;
            padding: 0 0 3px 0;
            text-align: left;
            font-size: 14px;
            font-weight: normal;
            line-height: 1em;
            padding-left: 10px;
        }

        #integration_form form .grid .card {
            width: 100%;
            max-width: none;
            margin: 0px;
            max-width: 450px;
            background-color: #F5F5F5;
            border: 1px solid #DCDCDC;
            border-radius: 8px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .5);
            padding: 20px;
            margin: 0;
            font-size: 16px;
            height: 100%;
        }

        #integration_form .inner-card {
            padding: 0 10px;
        }

        #integration_form form .grid .card .inner-card .form-group {
              margin: auto;
              position: relative;
              margin-bottom: 15px;
        }



        #integration_form form .grid .card .inner-card .form-group label {
            font-size: 12px;
            padding: 0;
            font-weight: 400;
            color: #5B5B5B;
            display: inline-block;
        }

        #integration_form form .grid .card .inner-card .form-group input {
            height: 37px;
            border-radius: 3px;
            font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
            display: inline-block;
            width: 100%;
            border: 1px solid #979797;
            padding: 10px 12px;
            font-size: 16px;
            outline: none !important;
            color: #333333;
            background-color: #FFFFFF;
            line-height: 16px;
            transition: border-color 0.2s ease-in-out;
        }

        #integration_form form .form-row {
            text-align: center;
        }

        #integration_form form .form-row .error {
            color: #D75762;
        }

        #integration_form form .form-row .buttons {
            max-width: 500px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            margin-bottom: 0px;
            margin-left: auto;
            margin-right: auto;
        }

        #integration_form form .form-row .buttons .btn-primary {
            margin: 0 6px;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
            padding: 8px 10px;
            width: 100% !important;
            max-width: 200px !important;
            color: #FFFFFF;
            border: 1px solid #438bdf;
            background-color: #438BDF;
            transition: background-color 0.2s, color 0.2s;
            user-select: none;
            border-radius: 8px;
            outline: none !important;
            line-height: 1em;
            display: inline-block;
            cursor: pointer;
            font-size: 16px;
            font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", "sans-serif";
        }

        #integration_form form .form-row .buttons .btn-primary[disabled] {
            opacity: .6;
            cursor: not-allowed !important;
        }

        #integration_form form .form-row .buttons .btn-text {
            border: 1px solid #979797;
            color: #5B5B5B;
            background-color: white;
            margin: 0 6px;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
            padding: 8px 10px;
            width: 100% !important;
            max-width: 200px !important;
            border-radius: 8px;
            outline: none !important;
            line-height: 1em;
            display: inline-block;
            cursor: pointer;
            font-size: 16px;
            transition: color 0.2s;
            text-decoration: none;
            text-align: center;
        }

         #integration_form form .grid .card .inner-card .form-group.error input {
            border: 1px solid #D75762;
        }
        
                
        #integration_form .inner-card .error span {
            font-size: 12px;
            color: #D75762;
        }

        #integration_form form .grid .card .inner-card .form-group span {
            display: none;
        }
        
        #integration_form form .grid .card .inner-card .form-group.error span {
            display: block;
        }
                
       .form-group.required:after{
          content: "Required";
          text-align: right;
          display: block;
          font-size: 12px;
          line-height: 19px;
          margin-bottom: -3px;
          position: absolute;
          top: 63px;
          right: 0px;
       }
       
      .alert.alert-danger {
            border: 4px solid #D75762;
            border-radius: 8px;
            background-color: #0B2543;
            overflow: hidden;
            color: white;
            margin-bottom: 20px;
            padding: 10px 20px;
       }
        .hidden{
         display: none;
       }
       
       .center{
         text-align: center;
       }
    `,
    ];

    constructor() {
        super()
        this.integration = {fields: []}
        this.fieldValues = {}
        this.timeZones = []
        graphQLIngestor.ready(() => this.getData())
    }

    render() {
        let fieldCategories
        let cards = []
        let thiz = this
        let counter = 1
        let firstCard = []
        if (this.integrationType && this.ingestableFields) {
            fieldCategories = this.integrationType.fieldCategories
            let orderedFieldCategories = sortByOrder(fieldCategories)
            $.each(orderedFieldCategories, function (i, data) {
                let name = data[0]
                let readable = data[1].interstitial
                if (counter == 1) {
                    firstCard.push(html`
                        <integration-card .integrationType=${thiz.integrationType}
                                          .ingestableFields=${thiz.ingestableFields}
                                          .integration="${thiz.integration}"
                                          .readable="${readable}" ,
                                          .name=${name}></integration-card>`)
                } else {
                    let nextFieldCategory = orderedFieldCategories[counter]
                    if (counter % 2 === 0) {
                        if (nextFieldCategory) {
                            cards.push(html`
                                <div class="grid">
                                    <integration-card .integrationType=${thiz.integrationType}
                                                      .integration="${thiz.integration}"
                                                      .ingestableFields=${thiz.ingestableFields}
                                                      .timeZones=${thiz.timeZones}
                                                      .readable="${readable}" ,
                                                      .name=${name}></integration-card>
                                    <integration-card .integrationType=${thiz.integrationType}
                                                      .ingestableFields=${thiz.ingestableFields}
                                                      .integration="${thiz.integration}"
                                                      .timeZones=${thiz.timeZones}
                                                      .readable="${readable}" ,
                                                      .name=${nextFieldCategory[0]}></integration-card>
                                </div>`)
                        } else {
                            cards.push(html`
                                <div class="grid">
                                    <integration-card .integrationType=${thiz.integrationType}
                                                      .ingestableFields=${thiz.ingestableFields}
                                                      .integration="${thiz.integration}"
                                                      .readable="${readable}" ,
                                                      .name=${name}></integration-card>
                                </div>`)
                        }
                    }
                }
                counter += 1
            })
        }
        return html`
            <div id="integration_form" class="hidden">
                <form>
                    <div class="grid">
                        <div class="general card">
                            <header>General Information</header>
                            <div class="inner-card">
                                <div class="alert alert-danger hidden">Please review the problems below:</div>
                                <div class="required form-group">
                                    <label for="integration_name">Name</label>
                                    <input type="text" id="integration_name"
                                           value=${this.integration.name}>
                                    <span>Name can't be blank</span>
                                </div>
                                <div class="form-group integration_description">
                                    <label for="integration_description">Description</label>
                                    <input type="text" id="integration_description" ,
                                           value=${this.integration.description}>
                                    <span>Description can't be blank</span>
                                </div>
                            </div>
                        </div>
                        ${firstCard}
                    </div>
                    ${cards}
                    <div class="form-row">
                        <div class="error hidden">
                            <span>Error</span>
                        </div>
                        <div class="spinner hidden">
                            <button-spinner></button-spinner>
                        </div>
                        <div class="buttons">
                            <input type="submit" name="commit" value="Save" class="btn-primary"
                                   @click=${this.formSubmitted}>
                            <a class="btn-text" href="javascript:history.back()">Cancel</a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="formSpinner" class="center">
                <button-spinner></button-spinner>
            </div>
        `;
    }


    hideOrShowSpinner = function (hide) {
        let spinner = $(this.shadowRoot).find('#integration_form .spinner')[0]
        if (hide) {
            $(spinner).addClass('hidden')
        } else {
            $(spinner).removeClass('hidden')
        }
    }


    checkIfErrorExistsAndSaveFieldValues = function (integrationCard, thiz) {
        let error = false
        $(integrationCard.shadowRoot).find('.inner-card').children().not('div').each((j, formGroup) => {
            if (formGroup.tagName === 'CRON-FIELD') {
                let name = formGroup.dataset.fieldName
                let tz = $(formGroup.parentElement).find('span select')[0].value
                if (formGroup.value != '') {
                    thiz.fieldValues[name] = formGroup.value + ' ' + tz
                } else {
                    thiz.fieldValues[name] = formGroup.value
                }

                error = error || !formGroup.isValid;
                formGroup.addEventListener('change', () => {
                    let tz = $(formGroup.parentElement).find('span select')[0].value
                    if (formGroup.value != '') {
                        thiz.fieldValues['Schedule String'] = formGroup.value + ' ' + tz
                    } else {
                        thiz.fieldValues['Schedule String'] = formGroup.value
                    }
                })
            } else {
                let input = $(formGroup.shadowRoot).find('input')[0];
                if (input) {
                    formGroup.validate(input);
                }
                let integrationSelects = $(formGroup.shadowRoot).find('integration-select')
                if (integrationSelects.length > 0) {
                    thiz.fieldValues[formGroup.field.name] ||= {}
                    $.each(integrationSelects, function (i, integrationSelect) {
                        let key = integrationSelect.key
                        thiz.fieldValues[formGroup.field.name][key] = integrationSelect.value
                    })
                    if (formGroup.validateSelects()) {
                        error = true
                    }
                } else {
                    let val = input.value
                    thiz.fieldValues[formGroup.field.name] = val
                    if (formGroup.field.required && val === '') {
                        error = true
                    } else {
                        if ($(formGroup.shadowRoot.children[0]).hasClass('error')) {
                            error = true
                        }
                    }
                }
            }
        })
        return error
    }


    hasError = function (target) {
        if ($(target.parentElement).hasClass('required')) {
            if (target.value == '') {
                $(target.parentElement).addClass('error')
                $(target.parentElement).siblings('.alert').removeClass('hidden')
                return true
            } else {
                $(target.parentElement).removeClass('error')
                $(target.parentElement).siblings('.alert').addClass('hidden')
                return false
            }
        }
        return false
    }

    validateGrids = function () {
        let error = false
        let thiz = this
        $(this.shadowRoot).find('.grid').each(function (i, grid) {
            let integrationCardHasError
            $(grid.children).each(function (g, gridData) {
                let tmp = thiz.checkIfErrorExistsAndSaveFieldValues(gridData, thiz)
                if (tmp) {
                    integrationCardHasError = tmp
                }
            })
            if (integrationCardHasError) {
                error = integrationCardHasError
            }
        })
        if (error === false) {
            $(this.shadowRoot).find('.required input').each(function (i, el) {
                if (el.value == '') {
                    error = true
                }
            })
        }
        return error;
    }

    validateGeneral = function () {
        let thiz = this
        let error = false
        $(this.shadowRoot).find("input[type!='submit']").each(function (i, input) {
            if (thiz.hasError(input)) {
                error = true
            }
        });
        return error
    }

    getData = function () {
        let query = `
              query Integration($id: ID!){
                    integration(id: $id){
                        id
                        name
                        timeZone
                        description
                        jobType
                        fields{
                          name
                          id
                           type
                          value
                          __typename
                        }
                    }
                }
            `;

        let variables = {
            "id": this.id
        }

        let thiz = this;

        graphQLIngestor.query({
            query: query,
            variables: variables,
            success: (response) => {
                this.integration = response.data.integration
                let aElement = $(thiz.parentElement.parentElement).find('.breadcrumbs .name a');
                aElement.attr("href", Routes.integration_path(response.data.integration.id))
                aElement.text(this.integration.name)
                this.getIntegrationType()
            },
            error: (message) => {
                console.log(`Error getting Integration: ${message}`)
            },
        });
    }

    getIntegrationType = function () {
        let query = `
            query IntegrationTypes{
              integrationTypes{
                name
                friendlyName
                fields{
                  sortOrder
                  name
                  __typename
                  fieldType
                  category
                  required
                  jobType
                }
                fieldCategories
              }
            }
          `;

        let thiz = this;
        graphQLIngestor.query({
            query: query,
            success: (response) => {
                thiz.integrationType = response.data['integrationTypes'].find(x => x.name == thiz.integration.jobType)
                this.getIngestableFields()
            },
            error: (message) => {
                console.log(`Error getting IntegrationTypes: ${message}`)
            },
        });
    }

    getIngestableFields = function () {
        let query = `
            query UserIngestorFields{
              userIngestorFields{
                ingestableFields{
                  fieldName
                  description
                  required
                  humanized
                }
              }
            }
          `;

        let thiz = this;
        graphQLIngestor.query({
            query: query,
            success: (response) => {
                thiz.ingestableFields = response.data['userIngestorFields']['ingestableFields']
                $(thiz.shadowRoot).find('#formSpinner').addClass('hidden')
                $(thiz.shadowRoot).find('#integration_form').removeClass('hidden')
            },
            error: (message) => {
                console.log(`Error getting ingestableFields: ${message}`)
            },
        });
    }

    updateIntegration = function () {
        this.hideOrShowSpinner(false)
        let integrationName = $(this.shadowRoot).find('#integration_name').val();
        let integrationDescription = $(this.shadowRoot).find('#integration_description').val();
        let fieldValues = []
        $.each(this.fieldValues, function (e, i) {
            if (typeof (i) == 'object') {
                fieldValues.push({name: e, value: JSON.stringify(i)})
            } else {
                fieldValues.push({name: e, value: i})
            }
        })

        let query = `
            mutation IntegrationUpdate($input: IntegrationUpdateInput!){
                integrationUpdate(input: $input){
                    integration{
                        id
                        name
                        jobType
                        fields{
                            name
                            type
                            value
                        }
                    }
                }
            }
        `;


        let variables = {
            input: {
                updateParams: {
                    id: this.id,
                    name: integrationName,
                    description: integrationDescription,
                    fieldValues: fieldValues
                }
            }
        }

        let thiz = this;
        $(thiz.shadowRoot).find('.form-row .error').addClass('hidden')

        graphQLIngestor.query({
            query: query,
            variables: variables,
            success: (response) => {
                window.location.href = Routes.integration_path(response.data.integrationUpdate.integration.id)
                thiz.hideOrShowSpinner(true)
            },
            error: (response, message, data) => {
                $(thiz.shadowRoot).find('.form-row .error').removeClass('hidden')
                if (data.responseJSON.errors) {
                    $(thiz.shadowRoot).find('.form-row .error span').text(data.responseJSON.errors[0].message)
                } else if (data.responseJSON.error) {
                    $(thiz.shadowRoot).find('.form-row .error span').text(data.responseJSON.error.message)
                }
                thiz.hideOrShowSpinner(true)
            },
        });
    }

    formSubmitted = function (event) {
        $(this.shadowRoot).find('.form-row .error').addClass('hidden');
        event.preventDefault();
        let validGeneral = !this.validateGeneral()
        let validGrids = !this.validateGrids()
        if (validGrids && validGeneral) {
            this.updateIntegration()
        }
    }
}

customElements.define('integration-edit', integrationEdit)