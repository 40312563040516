import { LitElement, html, css } from 'lit';

export class Card extends LitElement {
    static styles = css`
        :host {
            display: block;
            padding: 25px;
            border: 1px solid #DCDCDC;
            border-radius: 8px;
        }

        ::slotted(header) {
            border-bottom: 1px solid rgb(151, 151, 151);
            margin-bottom: 20px;
        }

        ::slotted(.actions) {
            padding-top: 24px;
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 10px;
        }
    `;

    render() {
        return html`<div>
            <slot name="header"></slot>
            <slot></slot>
            <slot name="actions"></slot>
        </div>`;
    }
}

customElements.define('mss-card', Card);
