
import {LitElement, html, css} from 'lit'


class MssLabel extends LitElement {
  static styles = css`
		*, *:before, *:after {
		  box-sizing: border-box;
		}
		:host {
			display: inline-block;
			position: relative;
		}
  `;


  static properties = {
    options: {type: Object, attribute: false},
  };


  constructor() {
    super()
  }


  render() {
    return html`
			<slot></slot>
		`;
  }


  connectedCallback() {
    super.connectedCallback()
    this._clickListener = () => {
      this._click()
    }
    this.addEventListener('click', this._clickListener)
  }


  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener('click', this._clickListener)
    this._resizeListener = null
  }


  _click(event) {
    const slot = this.shadowRoot.querySelector('slot')
    let slots = slot.assignedElements({flatten: true})
    for (const element of slots) {
      let tag = element.tagName
      if (tag === 'MSS-CHECKBOX') {
        let newEvent = new Event('click', {bubbles: true, composed: true});
        element.dispatchEvent(newEvent)
      }
    }
  }


}
customElements.define('mss-label', MssLabel);
