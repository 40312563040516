
import {LitElement, html, css} from 'lit'
import {Assets} from 'shared/assets';

export class SlideshowLargeBase extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }
    :host {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 5500;
      background: rgba(0, 0, 0, 0.85);
      overflow: auto;
    }
   
    .slideshow {
      position: absolute;
      color: white;
      top: 17vh;
      left: 10vw;
      width: 80vw;
    }
    
    .close-button .close-icon svg {
      width: 20px;
      height: 20px;
    }

    .close-button {
      position: absolute;
      font-size: 24px;
      display: inline-block;
      right: -13px;
      top: -112px;
      width: 60px;
      height: 60px;
      padding: 20px;
      padding-top: 30px;
      padding-bottom: 10px;
    }

    .previous-image, .current-image, .next-image {
      position: absolute;
      width: 80vw;
      transition: left 0.4s;
    }
    
    .current-image {
      left: 0vw;
    }
    .next-image {
      display: none;
      left: 90vw;
    }
    .previous-image {
      left: -90vw;
    }
    
    .up-caret-white-icon svg {
      width: 36px;
      height: 21px;
    }
    
    .next-button, .previous-button {
      position: absolute;
      font-size: 38px;
      height: 100%;
      width: 120px;
      padding-left: 40px;
    }
    .next-button .up-caret-white-icon {
      position: absolute;
      top: calc(50% - 23px);
      transform: rotate(90deg);
    }
    .previous-button .up-caret-white-icon {
      position: absolute;
      top: calc(50% - 23px);
      transform: rotate(-90deg);
    }
    .next-button {
      right: -100px;
    }
    .previous-button {
      left: -100px;
    }

    .top-bar {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: -35px;
      width: 100%;
      padding-right: 10px;
      height: 26px;
    }
    .top-left {
      position: relative;
      flex-basis: 33.33%;
      text-align: left;
      display: inline-block;
    }
    .top-middle {
      position: relative;
      flex-basis: 33.33%;
      text-align: center;
      display: inline-block;
    }
    .top-right {
      position: relative;
      flex-basis: 33.33%;
      text-align: right;
      display: inline-block;
    }
    

    .bottom-bar {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      bottom: -35px;
      width: 100%;
      padding: 0px 10px;
      height: 26px;
    }
    .bottom-left {
      position: relative;
      display: inline-block;
      flex-basis: 33.33%;
      text-align: left;
    }
    .bottom-middle {
      position: relative;
      display: inline-block;
      flex-basis: 33.33%;
      text-align: center;
    }
    .bottom-right {
      position: relative;
      display: inline-block;
      flex-basis: 33.33%;
      text-align: right;
    }
  `;


  static properties = {
    data: {attribute: false},
    index: {attribute: false},
  };


  constructor() {
    super()
    this.data = []
    this.index = 0
  }


  connectedCallback() {
    super.connectedCallback()
    this._resizeListener = () => {
      this._resize()
    }
    window.addEventListener('resize', this._resizeListener)
  }


  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('resize', this._resizeListener)
    this._resizeListener = null
  }


  render() {
    return html`
      <div class="slideshow">
        <div class="top-bar">
          <div class="top-left">${this.topLeft(this.index)}</div>
          <div class="top-middle">${this.topMiddle(this.index)}</div>
          <div class="top-right">${this.topRight(this.index)}</div>
        </div>
          
        <img id="image1" class=${this._imageClass(this.index, 'image1')}
             .src=${this._imageSrc(this.index, 'image1')} alt="Image"
             @load=${this._resize}>
        <img id="image2" class=${this._imageClass(this.index, 'image2')}
             .src=${this._imageSrc(this.index, 'image2')} alt="Image"
            @load=${this._resize}>
        <img id="image3" class=${this._imageClass(this.index, 'image3')}
             .src=${this._imageSrc(this.index, 'image3')} alt="Image"
             @load=${this._resize}>
        
        <div class="bottom-bar">
          <div class="bottom-left">${this.bottomLeft(this.index)}</div>
          <div class="bottom-middle">${this.bottomMiddle(this.index)}</div>
          <div class="bottom-right">${this.bottomRight(this.index)}</div>
        </div>
        <div class="close-button" @click=${this.close}>
          <span class="close-icon">${Assets.inlineSvg('filters/close_white.svg')}</span>
        </div>
        <div class="previous-button" @click=${this.previous}>
          <span class="up-caret-white-icon">${Assets.inlineSvg('up_caret_white.svg')}</span>
        </div>
        <div class="next-button" @click=${this.next}>
          <span class="up-caret-white-icon">${Assets.inlineSvg('up_caret_white.svg')}</span>
        </div>
      </div>
    `;
  }


  _imageClass(index, id) {
    const classList = ['previous-image', 'next-image', 'current-image']
    const imgOffset = {image1: 0, image2: 2, image3: 1}
    return classList[(index + imgOffset[id]) % 3]
  }


  _imageSrc(index, id) {
    const imgOffset = {image1: 2, image2: 1, image3: 0}
    const imgBump = {image1: -1, image2: 0, image3: 1}
    let num = (Math.floor((index + imgOffset[id]) / 3) * 3) + imgBump[id]
    if ((num < 0) || (num >= this.data.length)) return ''
    return this.image(num)
  }


  updated() {
    this.showArrows()
  }


  _resize() {
    let image = this.renderRoot.querySelector('.current-image')
    let slideshow = this.renderRoot.querySelector('.slideshow')
    if (!image || !slideshow) return;

    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    let isLoaded = image.complete && image.naturalHeight !== 0
    if (isLoaded) {
      let imageWidth = image.width
      let imageHeight = image.height
      slideshow.style.height = `${image.height}px`
    }
  }


  next() {
    if (this.index >= (this.data.length - 1)) {
      return
    }
    this.renderRoot.querySelector('.next-image').style.display = 'block'

    let previous = this.renderRoot.querySelector('.previous-image')
    previous.style.display = 'none'

    setTimeout(() => {
    this.renderRoot.querySelector('#image1').className =
          this._imageClass(this.index + 1, 'image1')
    this.renderRoot.querySelector('#image2').className =
          this._imageClass(this.index + 1, 'image2')
    this.renderRoot.querySelector('#image3').className =
          this._imageClass(this.index + 1, 'image3')
    }, 0)

    setTimeout(() => {
      previous.style.display = 'block'
    }, 50)

    if ((this.index + 1) < this.data.length) {
      setTimeout(() => {
        if (this.index < (this.data.length - 1)) {
          this.index += 1
        }
        this.renderRoot.querySelector('.next-image').style.display = 'none'
        this.showArrows()
      }, 400)
    }
  }


  previous() {
    if (this.index <= 0) {
      return
    }
    let next = this.renderRoot.querySelector('.next-image')
    next.style.display = 'none'

    this.renderRoot.querySelector('#image1').className =
            this._imageClass(this.index - 1, 'image1')
    this.renderRoot.querySelector('#image2').className =
            this._imageClass(this.index - 1, 'image2')
    this.renderRoot.querySelector('#image3').className =
            this._imageClass(this.index - 1, 'image3')

    setTimeout(() => {
      next.style.display = 'block'
    }, 2)

    if (this.index > 0) {
      setTimeout(() => {
        if (this.index > 0) {
          this.index -= 1
        }
        this.showArrows()
      }, 400)
    }
  }


  showArrows() {
    if (this.index < (this.data.length - 1)) {
      this.renderRoot.querySelector('.next-button').style.display = 'block'
    } else {
      this.renderRoot.querySelector('.next-button').style.display = 'none'
    }
    if (this.index > 0) {
      this.renderRoot.querySelector('.previous-button').style.display = 'block'
    } else {
      this.renderRoot.querySelector('.previous-button').style.display = 'none'
    }
  }


  close() {
    const event = new Event('close')
    this.dispatchEvent(event)
  }



  image(index) {
    return ''
  }


  topLeft(index) {
    return html``
  }


  topMiddle(index) {
    return html``
  }


  topRight(index) {
    return html``
  }


  bottomLeft(index) {
    return html``
  }


  bottomMiddle(index) {
    return html``
  }


  bottomRight(index) {
    return html``
  }
}
